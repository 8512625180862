import { useState } from 'react'
import shared from '../../../../app/infraestructure/shared'
import UpdatePassword from '../../application/useCase/updatePassword'
import { UpdatePasswordParam } from '../../application/useCase/updatePassword/UpdatePasswordParam'
import { IPymeRepository } from '../../domain'

const {
  constants: {
    httpStatusCodes: {
      HTTP_INVALID_CREDENTIALS_STATUS,
      HTTP_UNEXPECTED_ERROR_STATUS,
    },
  },
} = shared

const changePasswordController = ({
  handleSetActiveIndex,
  pymeRepository,
}: {
  handleSetActiveIndex: any
  pymeRepository: IPymeRepository
}) => {
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const updatePasswordUseCase = new UpdatePassword(pymeRepository)

  const handleOnPressSubmit = async (values: UpdatePasswordParam) => {
    try {
      setErrorMsg('')
      setIsLoading(true)
      const response = await updatePasswordUseCase.updatePassword(values)

      if (response.status === HTTP_INVALID_CREDENTIALS_STATUS) {
        return setErrorMsg('No está autorizado')
      }

      if (response.status === HTTP_UNEXPECTED_ERROR_STATUS) {
        return setErrorMsg('Error en cambiar la contraseña, intente otra vez')
      }

      setIsLoading(false)
      return handleSetActiveIndex().next()
    } catch (error) {
      setIsLoading(false)
      return setErrorMsg('Error inesperado')
    }
  }

  return {
    errorMsg,
    isLoading,
    handleOnPressSubmit,
  }
}

export default changePasswordController
