import React from 'react'
import styled from 'styled-components'
import UI from '../../../../UI'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem 3rem 2rem;
  gap: 0.5rem;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const CancelModalContent = ({
  onPressGoBack,
  onPressGoOutWithoutSave,
}: {
  onPressGoBack: () => void
  onPressGoOutWithoutSave: () => void
}) => (
  <ModalContainer>
    <UI.Text color="mariner" weight="mdS" size="lg">
      Atención
    </UI.Text>
    <BodyContainer>
      <UI.Text color="chathamsBlue" weight="smL" size="mdLg">
        ¿Estás seguro que querés abandonar el proceso?
      </UI.Text>
      <UI.Text color="tundora">
        Los datos que no hayan sido guardados hasta el momento se perderán.
      </UI.Text>
    </BodyContainer>
    <ButtonsContainer>
      <UI.Button variant="outlined" onClick={onPressGoBack}>
        Volver
      </UI.Button>
      <UI.Button onClick={onPressGoOutWithoutSave}>Salir sin guardar</UI.Button>
    </ButtonsContainer>
  </ModalContainer>
)

const ConfirmCancelModalContent = ({
  onPressCancel,
  onPressConfirmCancel,
}: {
  onPressCancel: () => void
  onPressConfirmCancel: () => void
}) => (
  <ModalContainer>
    <UI.Text color="mariner" weight="smL">
      Atención
    </UI.Text>
    <BodyContainer>
      <UI.Text color="chathamsBlue">
        Se eliminará el colaborador y todo su grupo familiar.
      </UI.Text>
    </BodyContainer>
    <ButtonsContainer>
      <UI.Button variant="outlined" onClick={onPressCancel}>
        Cancelar
      </UI.Button>
      <UI.Button onClick={onPressConfirmCancel}>Confirmar</UI.Button>
    </ButtonsContainer>
  </ModalContainer>
)

const ContinueWithoutSaving = ({
  onPressContinue,
  onPressConfirm,
}: {
  onPressContinue: () => void
  onPressConfirm: () => void
}) => (
  <ModalContainer>
    <UI.Text color="mariner" weight="mdS" size="lg">
      Atención
    </UI.Text>
    <BodyContainer>
      <UI.Text color="chathamsBlue" weight="smL" size="mdLg">
        No guardaste los cambios, si continuás se perderán
      </UI.Text>
      <UI.Text>¿Deseas continuar de todos modos?</UI.Text>
    </BodyContainer>
    <ButtonsContainer>
      <UI.Button variant="outlined" onClick={onPressContinue}>
        Continuar sin cambios
      </UI.Button>
      <UI.Button onClick={onPressConfirm}>Volver</UI.Button>
    </ButtonsContainer>
  </ModalContainer>
)

export default CancelModalContent
export {
  CancelModalContent, ConfirmCancelModalContent, ContinueWithoutSaving,
}
