import React from 'react'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from './global'
import theme from './theme'

export default ({ children }: any) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
)
