export enum PaymentType {
  none = 0,
  debit = 1,
  transfer = 2,
}

export const PaymentLabel: any = {
  [PaymentType.debit]: 'Débito automático',
  [PaymentType.transfer]: 'Transferencia',
}
