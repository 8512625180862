import { IPymeRepository } from '../../domain'

export default class SavePymeRequote {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async run(params: any): Promise<any> {
    return this.repository.saveRequote(params)
  }
}
