import { DropDownValues } from '../../UI/DropDown'

const [male, female] = [0, 1]
const sexValues = [male, female]
const sexStrings = {
  [male]: 'masculino',
  [female]: 'femenino',
}

const sexOptions: DropDownValues[] = [
  {
    value: male,
    label: sexStrings[male],
  },
  {
    value: female,
    label: sexStrings[female],
  },
]

const asociateTypes = {
  child: 1,
  family: 4,
  spouse: 6,
  aditionalFamily: 7,
  disabledChild: 8,
  holder: 10,
}

export default {
  male,
  female,
  sexStrings,
  sexOptions,
  asociateTypes,
  sexValues,
}
