import { PaymentType } from '../../../../modules/Pyme/application/useCase/savePaymentMethod/PaymentType'

export const paymentTypeToString = (
  paymentType: PaymentType | undefined,
): string => {
  const paymentMap = {
    [PaymentType.none]: 'Ninguno',
    [PaymentType.debit]: 'Débito',
    [PaymentType.transfer]: 'Transferencia',
  }

  if (paymentType) {
    return paymentMap[paymentType] || 'NN'
  }

  return 'None'
}

export default {
  paymentTypeToString,
}
