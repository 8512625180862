import React, { useEffect, useContext } from 'react'
import stores from '../../../stores'
import UI from '../../../UI'
import * as S from '../styles'

const RedirectToLogin = () => {
  const context = useContext(stores.LoginContext)

  useEffect(() => {
    setTimeout(() => {
      context.handleOnPressLogout()
    }, 4000)
  }, [])

  return (
    <S.RedirectContainer>
      <S.SpinnerContainer>
        <UI.Spinner width="4" heigth="4" />
      </S.SpinnerContainer>
      <UI.Text textAlign="center" color="chathamsBlue">
        Te redirigimos para que puedas loguearte con tu nueva contraseña
      </UI.Text>
    </S.RedirectContainer>
  )
}

export default RedirectToLogin
