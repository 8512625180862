import { TServerResponse } from '../../../../app/domain/httpClient'
import { TChild,
  TFamilyGroupFormValues } from '../../../../app/infraestructure/Pages/CompanyApplication/Steps/Step2/constants'
import constants from '../../../../app/infraestructure/shared/constants'
import { DecodeCoveragePlans } from '../../../../app/infraestructure/shared/helpers/encoders'
import { memberTypes } from '../../../commons/member'
import { TSaveFamilyGroupsParams,
  TSaveFamilyGroup,
  TSocios } from '../../../Pyme/domain/entity/saveFamilyGroupDto'
import { THoldersList, THoldersListByMemberType } from '../../domain/DTOs'
import { IFamilyGroupRepository } from '../../domain/entities'

const {
  userRelated: { asociateTypes },
} = constants

const currentDate = new Date()

const mapHolderToAsociate = (formData: TFamilyGroupFormValues): TSocios => ({
  Id: formData.holderId,
  Nombre: formData.name,
  Apellido: formData.lastName,
  Dni: formData.dni.toString(),
  FechaNacimiento: formData.birthDay.toISOString(),
  Cuil: formData.cuil.toString(),
  Mail: formData.email,
  TipoSocio: asociateTypes.holder,
  Sexo: formData.sex,
  TelefonoMovil: `${formData.areaCode}-${formData.phone}`,
  Aporte: formData.contributions,
  Edad: formData.age.toString(),
})

const mapCoupleToAsociate = (formData: TFamilyGroupFormValues): TSocios => ({
  Id: formData.coupleId,
  Nombre: formData.coupleName,
  Apellido: formData.coupleLastName,
  Dni: formData.coupleDni.toString(),
  FechaNacimiento: formData.coupleBirthday.toISOString(),
  Cuil: formData.coupleCuil.toString(),
  Mail: '',
  TipoSocio: asociateTypes.spouse,
  Sexo: formData.coupleSex,
  TelefonoMovil: `${formData.coupleAreaCode}-${formData.couplePhone}`,
  Aporte: 0,
  Edad: formData.coupleAge.toString(),
})

const mapChildsToAsociate = (child: TChild): TSocios => ({
  Id: child.childId,
  Nombre: child.name,
  Apellido: child.lastName,
  Dni: child.childDni.toString(),
  FechaNacimiento: child.birthDay
    ? child.birthDay.toISOString()
    : currentDate.toISOString(),
  Cuil: '',
  Mail: '',
  TipoSocio: asociateTypes.child,
  Sexo: child.sex,
  TelefonoMovil: '',
  Aporte: 0,
  Edad: child.age.toString(),
})

export default class SaveFamilyGroups {
  repository: IFamilyGroupRepository

  constructor(repository: IFamilyGroupRepository) {
    this.repository = repository
  }

  async run(
    holders: THoldersListByMemberType | null,
    pymeId: number | null,
  ): Promise<TServerResponse<{ Message: string }>> {
    const holdersFormValues: TSaveFamilyGroup = {
      grupoFamiliar: [],
    }

    const familyGroup: TSaveFamilyGroupsParams[] = []

    if (holders && pymeId) {
      holders?.Desregulados.forEach((h: THoldersList) => {
        const { formData } = h
        const asociates: TSocios[] = []

        if (formData && formData.dni) {
          asociates.push(mapHolderToAsociate(formData))
          if (formData.includeCouple && formData.coupleDni) {
            asociates.push(mapCoupleToAsociate(formData))
          }
          if (formData.childs) {
            // TODO: type childs
            formData.childs.forEach((c: TChild) => {
              if (c.childDni) asociates.push(mapChildsToAsociate(c))
            })
          }

          familyGroup.push({
            Socios: asociates,
            Direccion: {
              Calle: formData.street,
              Altura: formData.streetNumber,
              Piso: formData.floor,
              Depto: formData.floor,
              IdProvincia: formData.province,
              IdLocalidad: formData.location > 0 ? formData.location : null,
              CodigoPostal: formData.postalCode,
            },
            IdPlan: DecodeCoveragePlans(formData.coveragePlans),
            IdPyme: pymeId,
            tipoAfiliacion: memberTypes.desrregulado,
            Pmo: formData.pmo,
          })
        }
      })
      holders?.Individuales.forEach((h: THoldersList) => {
        const { formData } = h
        const asociates: TSocios[] = []

        if (formData && formData.dni) {
          asociates.push(mapHolderToAsociate(formData))
          if (formData.includeCouple && formData.coupleDni) {
            asociates.push(mapCoupleToAsociate(formData))
          }
          if (formData.childs) {
            // TODO: type childs
            formData.childs.forEach((c: TChild) => {
              if (c.childDni) asociates.push(mapChildsToAsociate(c))
            })
          }

          familyGroup.push({
            Socios: asociates,
            Direccion: {
              Calle: formData.street,
              Altura: formData.streetNumber,
              Piso: formData.floor,
              Depto: formData.floor,
              IdProvincia: formData.province,
              IdLocalidad: formData.location > 0 ? formData.location : null,
              CodigoPostal: formData.postalCode,
            },
            IdPlan: DecodeCoveragePlans(formData.coveragePlans),
            IdPyme: pymeId,
            tipoAfiliacion: memberTypes.individual,
            Pmo: formData.pmo,
          })
        }
      })
      holders?.Monotributistas.forEach((h: THoldersList) => {
        const { formData } = h
        const asociates: TSocios[] = []

        if (formData && formData.dni) {
          asociates.push(mapHolderToAsociate(formData))
          if (formData.includeCouple && formData.coupleDni) {
            asociates.push(mapCoupleToAsociate(formData))
          }
          if (formData.childs) {
            // TODO: type childs
            formData.childs.forEach((c: TChild) => {
              if (c.childDni) asociates.push(mapChildsToAsociate(c))
            })
          }

          familyGroup.push({
            Socios: asociates,
            Direccion: {
              Calle: formData.street,
              Altura: formData.streetNumber,
              Piso: formData.floor,
              Depto: formData.floor,
              IdProvincia: formData.province,
              IdLocalidad: formData.location > 0 ? formData.location : null,
              CodigoPostal: formData.postalCode,
            },
            IdPlan: DecodeCoveragePlans(formData.coveragePlans),
            IdPyme: pymeId,
            tipoAfiliacion: memberTypes.monotributo,
            Pmo: formData.pmo,
          })
        }
      })
    }

    if (!familyGroup.length) {
      return {
        status: constants.httpStatusCodes.HTTP_BAD_REQUEST,
        data: {
          Message: 'No se guardaron titulares, se debe cargar el DNI',
        },
      }
    }

    holdersFormValues.grupoFamiliar = familyGroup

    return this.repository.saveFamilyGroups(holdersFormValues)
  }
}
