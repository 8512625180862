import { useCallback, useEffect, useState } from 'react'
import { TFamilyGroupFormValues,
  HOLDER_STATUS } from '../../../../app/infraestructure/Pages/CompanyApplication/Steps/Step2/constants'
import GetHoldersList from '../../../FamilyGroup/application/useCase/getHoldersList'
import { THoldersList,
  THoldersListByMemberTypeKeys,
  THoldersListByMemberType } from '../../../FamilyGroup/domain/DTOs'
import { IFamilyGroupRepository } from '../../../FamilyGroup/domain/entities'
import GetAllProvincies from '../../../Province/application/useCase/getAllProvincies'
import { Province } from '../../../Province/domain/Province'
import ProvinceRepository from '../../../Province/infraestructure/repositories/provinceRepository'
import GetDataPyme from '../../application/useCase/getDataPyme'
import { IPymeRepository } from '../../domain'
import SaveFamilyGroups from '../../../FamilyGroup/application/useCase/saveFamilyGroups'
import GetFamilysGroupDetail from '../../../FamilyGroup/application/useCase/getFamilysGroupDetail'
import DeleteHolderAndFamilyGroup from '../../application/useCase/deleteHolderAndFamilyGroup'
import constants from '../../../../app/infraestructure/shared/constants'
import holdersLocal from '../../../../app/infraestructure/Pages/CompanyApplication/Steps/Step2/HolderLocalModule'
import GetCompletedHoldersData from '../../../FamilyGroup/application/useCase/getCompletedHoldersData'
import UpdateSteps from '../../application/useCase/updateSteps'
import { memberTypesPlural } from '../../../commons/member'
import httpStatusCodes from '../../../../app/infraestructure/shared/constants/httpStatusCodes'
import { Steps } from '../../../commons'
import GetPymeRequote from '../../application/useCase/getRequoteData'
import SavePymeRequote from '../../application/useCase/saveRequote'
import GetLastQuote from '../../application/useCase/getLastQuote'

// statically, so we can selectHolderId between renders
const poolHoldersId = [
  'b26efc39-0e98-43eb-81b7-f4b36b653e61',
  '849e422c-ba0d-4a15-9efe-13638db9cef9',
  '85bdb6c9-cfae-4576-9a7a-db136a9caf17',
  '667bba34-a48b-41a8-a7b1-09392c970315',
  'a419bb41-e389-440b-9f21-26ee18f873fc',
  '7d7a331b-f18c-4c3d-92bb-f87e62597536',
  '9309f2ab-49c4-4ebc-86b1-8661e51923dd',
  '0beb8393-ea76-494f-860b-28e9881853fb',
  '789d3b55-62e0-449e-870c-a78ea9fd1f6a',
  '9d4332b6-e6df-43c9-b753-fca5b21ef94e',
  '5d82bcd8-6acf-4b56-99f4-fffcbd0e4a31',
  '477a616b-7ef3-4911-994f-3d60ec6cb1ea',
  'b9ca08fc-fe29-4375-afea-dbf625ecdfa2',
  '191bb434-096c-4d46-a460-644d6cf883cb',
  'f57af0c9-827e-40e1-956b-4a8753f08fd7',
  '903d4597-4797-48dd-a858-244b7e9c0cc5',
  'd4547c60-6cf3-4051-a07b-5b5b8cd3f7c3',
  '7d8c7111-5bf2-460d-9238-e48838e7cd26',
  '564d0901-4476-4c53-9860-0009d9afe25f',
  '494dca18-be6e-4709-aac5-f773e1c14bc1',
  '2fcc3a15-1b1d-4880-a984-704674dec0b8',
  'bcf230a8-c589-4ddd-a6e1-b932a3531e99',
  '1501bb41-2f62-4e2e-a120-5a842f8670dd',
  '7d7c474e-1b95-4cb8-ada4-203ef898ff3d',
  'f4b19894-c958-4c1f-8d09-dabd6d78a7c0',
  '85f7bc18-765a-4a79-b71f-68c426e2924d',
  '3c3c3b90-2dba-4404-a2d9-debad160f830',
  '27ee923a-80c8-4cad-b840-ae84e8078df5',
  'c162e251-1df1-40aa-bf65-6a177f97301d',
  '85f528df-9cb3-4d3d-a3f8-f1b099b57a13',
  '3ddd53a0-6be0-498e-aca6-ab81ddbd0992',
  '41cecccd-3ffc-49f8-b602-13bc0983da56',
  'a8fdea49-7292-44cc-90ee-d457d0c31b48',
  '94655345-0048-4f04-bb62-2f543ed1f2f5',
  '91f732cb-2580-4099-9966-cf0f657c0743',
  '2b7f3e7f-d66d-4533-ac5e-333514acc65f',
  '2d2b2406-0f7b-4c68-9bf4-c3f37939334d',
  'e3b8803d-d231-4dee-8ef1-6922561a3a41',
  '92fc5f35-e9e2-4602-95ab-dfe3686d41f2',
  '0ac329cf-6c99-4e76-a26d-ecc62cd380a4',
  '98f27e58-aaef-48b2-bc68-a5f6059dc9fd',
  '8b0a8d90-045c-4afd-9bda-588b4aca1a34',
  'b2750940-30c1-414c-8baf-2cb275e58f22',
  '0b87c15b-e977-4233-a572-cf1eff00b4c9',
  '15743927-83a4-4b6c-b628-c5502d48f01d',
  'c1afc977-5eb5-4c0a-9a21-837d6c95e37c',
  '15d1dbcb-19a5-490c-a442-d818568b0b98',
  'f2876490-fe32-43f3-ac2c-0b5ca9513869',
  '975f786e-95da-4273-b622-ef2341e88278',
  'e67dcf17-11f3-4a02-8a6f-5300015fd53d',
  'f5003cf6-7340-4489-9c49-bb7dadff5b1b',
  '54c3d366-b684-4d34-b50b-6435826fa2a1',
  '529a2f12-13b7-488a-9a43-d47df9a7c4e4',
  '9ee929de-5a91-4092-b75c-16ce47a036e7',
  '6230d28d-d11e-4a40-8e46-362f4005e383',
  'c3f59431-dc13-47ea-bfc2-35bcba60bf93',
  'b9fdb819-ebed-48fb-a590-011d2ba89c40',
  'eda4adba-7832-4c0a-b6fe-0f7033b6a6a7',
  '52d944d6-4792-4f83-b9bf-c9400b08d902',
  '773db381-cf46-41df-970b-36851aa36a43',
]

const memberTypeByDefault = 'Desregulados'

const saveFamilyGroupController = ({
  provinceRepository,
  familyGroupRepository,
  pymeRepository,
}: {
  provinceRepository: ProvinceRepository
  familyGroupRepository: IFamilyGroupRepository
  pymeRepository: IPymeRepository
}) => {
  // data
  const [provincies, setProvincies] = useState<Province[]>([])
  const [holderIdSelected, setHolderIdSelected] = useState('')
  const [memberType, setMemberType] = useState<THoldersListByMemberTypeKeys>(memberTypeByDefault)
  const [pymeId, setPymeId] = useState<number | null>(null)
  const [initValues, setInitValues] = useState<TFamilyGroupFormValues | null>(
    null,
  )
  const [, actionsOverHosterList] = useState({})
  const [msgDisplayer, setMsgDisplayer] = useState({
    error: false,
    msg: '',
  })
  const [completedHoldersData, setCompletedHoldersData] = useState(null)
  const [pymeRequoteData, setPymeRequoteData] = useState(null)

  // errorMsgs
  const [holdersListError, setHoldersListError] = useState('')
  const [pymeIdErrorMsg, setPymeIdErrorMsg] = useState('')
  const [hasPymeRequoteError, setHasPymeRequoteError] = useState(false)

  // isFetchings
  const [holdersListIsFetching, setHoldersListIsFetching] = useState(false)
  const [submitFormIsFetching, setSubmitFormIsFetching] = useState(false)
  const [pymeRequoteIsFetching, setPymeRequoteIsFetching] = useState(false)

  // useCases
  const getAllProvinciesUseCase = new GetAllProvincies(provinceRepository)
  const getHoldersListUseCase = new GetHoldersList(familyGroupRepository)
  const getPymeDataUseCase = new GetDataPyme(pymeRepository)

  const saveFamilyGroupsUseCase = new SaveFamilyGroups(familyGroupRepository)
  const getFamilysGroupDetailUseCase = new GetFamilysGroupDetail(
    familyGroupRepository,
  )
  const deleteHolderAndFamilyGroupUseCase = new DeleteHolderAndFamilyGroup(
    pymeRepository,
  )
  const getCompletedHolderListUseCase = new GetCompletedHoldersData(
    familyGroupRepository,
  )
  const updateStepUseCase = new UpdateSteps(pymeRepository)
  const getPymeRequoteUseCase = new GetPymeRequote(pymeRepository)
  const savePymeRequoteUseCase = new SavePymeRequote(pymeRepository)
  const lastQuote = new GetLastQuote(pymeRepository)

  const forceUpdateAfterHolderListActions = useCallback(
    () => actionsOverHosterList({}),
    [],
  )

  const handleOnPressTabs = async (mType: THoldersListByMemberTypeKeys) => {
    setMemberType(mType)
    setHolderIdSelected('')
    holdersLocal.setHolderSelectedId('')
    holdersLocal.setMemberType(mType)
  }

  const handleOnHolderCreated = () => {
    holdersLocal.createNewHolderByMemberType(memberType)
    forceUpdateAfterHolderListActions()
  }

  const handleOnHolderDeleted = async (id: string) => {
    try {
      const holder = holdersLocal.getHolder()

      const response = await deleteHolderAndFamilyGroupUseCase.run({
        guid: holder?.Guid || '',
        tipoAfiliacion: memberTypesPlural[memberType],
      })

      if (response.status === httpStatusCodes.HTTP_OK_STATUS) {
        return holdersLocal.deleteHolderById(id, memberType)
      }

      return setMsgDisplayer({
        error: true,
        msg: response.data,
      })
    } catch (error) {
      return setMsgDisplayer({
        error: true,
        msg: 'Se produjo un error, vuelva a intentarlo',
      })
    } finally {
      setHolderIdSelected('')
      holdersLocal.setHolderSelectedId('')
      forceUpdateAfterHolderListActions()
    }
  }

  // call on holderForm change
  const handleOnSubmitOnHoldersForm = (fData: TFamilyGroupFormValues) => {
    holdersLocal.updateHolder(
      memberType,
      holderIdSelected,
      fData,
      HOLDER_STATUS.INCOMPLETE,
      constants.commons.HOLDER_STEPS.INCOMPLETE,
    )
    setInitValues(holdersLocal.getHolder()?.formData || null)
  }

  const handleOnSubmitHolderForm = (formValues: TFamilyGroupFormValues) => {
    holdersLocal.updateHolder(
      memberType,
      holderIdSelected,
      formValues,
      HOLDER_STATUS.COMPLETE,
      constants.commons.HOLDER_STEPS.FORM_COMPLETE,
    )
    setInitValues(holdersLocal.getHolder()?.formData || null)
  }

  const handleOnHolderSelected = async (id: string) => {
    try {
      setHoldersListIsFetching(true)
      setHolderIdSelected(id)
      holdersLocal.setHolderSelectedId(id)
      setMsgDisplayer({
        error: false,
        msg: '',
      })
      const holderGuid = holdersLocal.getHolder()?.Guid

      if (holderGuid) {
        const response = await getFamilysGroupDetailUseCase.run(holderGuid)
        return setInitValues(response)
      }
    } catch (error) {
      setMsgDisplayer({
        error: true,
        msg: 'No se pudo obtener los datos del titular',
      })
    } finally {
      setHoldersListIsFetching(false)
    }
    return null
  }

  const handleOnCloseSuccessSave = () => setMsgDisplayer({ error: false, msg: '' })

  const getHolders = async () => {
    try {
      setHoldersListIsFetching(true)
      setHoldersListError('')
      let index = 0
      const { data } = await getHoldersListUseCase.run()
      const addIdToHolders: THoldersListByMemberType = {
        Desregulados: data.Desregulados.map((holder: THoldersList) => {
          index += 1

          return {
            ...holder,
            PasoDesdeFuente: holder.Paso,
            id: poolHoldersId[index],
          }
        }),
        Monotributistas: data.Monotributistas.map((holder: THoldersList) => {
          index += 1

          return {
            ...holder,
            PasoDesdeFuente: holder.Paso,
            id: poolHoldersId[index],
          }
        }),
        Individuales: data.Individuales.map((holder: THoldersList) => {
          index += 1

          return {
            ...holder,
            PasoDesdeFuente: holder.Paso,
            id: poolHoldersId[index],
          }
        }),
      }

      return holdersLocal.setHolders(addIdToHolders)
    } catch (error) {
      return setHoldersListError(
        'Se produjo un error al obtener el listado de titulares.',
      )
    } finally {
      setHoldersListIsFetching(false)
    }
  }

  const handleOnSaveButton = async () => {
    try {
      setMsgDisplayer({ error: false, msg: '' })
      setSubmitFormIsFetching(true)
      const response = await saveFamilyGroupsUseCase.run(
        holdersLocal.getHolders(),
        pymeId,
      )

      if (response.status === constants.httpStatusCodes.HTTP_BAD_REQUEST) {
        return setMsgDisplayer({
          error: true,
          msg:
            response.data.Message
            || 'No se pudo guardar los datos del titular y grupo familiar',
        })
      }

      if (
        response.status
        === constants.httpStatusCodes.HTTP_UNEXPECTED_ERROR_STATUS
      ) {
        return setMsgDisplayer({
          error: true,
          msg:
            response.data.Message
            || 'No se pudo guardar los datos del titular y grupo familiar',
        })
      }

      await getHolders()

      handleOnHolderSelected(holderIdSelected)

      return setMsgDisplayer({ error: false, msg: 'Cambios guardados' })
    } catch (error) {
      return setMsgDisplayer({
        error: true,
        msg: 'Se produjo un error',
      })
    } finally {
      setSubmitFormIsFetching(false)
      setHolderIdSelected('')
      holdersLocal.setHolderSelectedId('')
      forceUpdateAfterHolderListActions()
    }
  }

  const handleRequoteButton = async () => {
    try {
      if (pymeId) {
        setHasPymeRequoteError(false)
        setPymeRequoteIsFetching(true)

        const response = await getPymeRequoteUseCase.run(pymeId)

        if (response?.status === constants.httpStatusCodes.HTTP_BAD_REQUEST) {
          setHasPymeRequoteError(true)
          setPymeRequoteIsFetching(false)
          setPymeRequoteData(null)
          return
        }

        if (
          response?.status
          === constants.httpStatusCodes.HTTP_UNEXPECTED_ERROR_STATUS
        ) {
          setHasPymeRequoteError(true)
          setPymeRequoteIsFetching(false)
          setPymeRequoteData(null)
          return
        }
        setPymeRequoteData(response.data)
      }
    } catch (error) {
      setHasPymeRequoteError(true)
      setPymeRequoteIsFetching(false)
      setPymeRequoteData(null)
      return
    } finally {
      setPymeRequoteIsFetching(false)
    }
  }

  const handlePostRequoteButton = async (
    goToNextStep: any,
    displayModal: any,
  ) => {
    if (pymeRequoteData) {
      setHasPymeRequoteError(false)
      setPymeRequoteIsFetching(true)

      const { PrecioPlan } = pymeRequoteData

      try {
        const response = await savePymeRequoteUseCase.run({ PrecioPlan })

        if (response?.status === constants.httpStatusCodes.HTTP_BAD_REQUEST) {
          setHasPymeRequoteError(true)
          setPymeRequoteIsFetching(false)
          setPymeRequoteData(null)
          return
        }

        if (
          response?.status
          === constants.httpStatusCodes.HTTP_UNEXPECTED_ERROR_STATUS
        ) {
          setHasPymeRequoteError(true)
          setPymeRequoteIsFetching(false)
          setPymeRequoteData(null)
          return
        }

        await lastQuote.getLastQuote(false)

        goToNextStep()
        displayModal(false)
      } catch (error) {
        setHasPymeRequoteError(true)
        setPymeRequoteIsFetching(false)
        setPymeRequoteData(null)
      }
    }
  }

  useEffect(() => {
    const getAllProvincies = async () => {
      try {
        const response = await getAllProvinciesUseCase.getAllProvincies()

        return setProvincies(response.data.provincias)
      } catch (error) {
        return setProvincies([])
      }
    }

    getAllProvincies()
  }, [])

  useEffect(() => {
    getHolders()
    holdersLocal.setHolderSelectedId('')
  }, [])

  useEffect(() => {
    const getPymeData = async () => {
      try {
        setPymeIdErrorMsg('')
        const response = await getPymeDataUseCase.getDataPyme()

        return setPymeId(response.data.Id)
      } catch (error) {
        return setPymeIdErrorMsg('Error en conseguir el Id de la Pyme')
      }
    }

    getPymeData()
  }, [])

  useEffect(() => {
    if (holdersLocal.getHolders()) {
      const h = holdersLocal
        .getHoldersList()
        ?.find((holder: THoldersList) => holder.id === holderIdSelected)

      setInitValues(h?.formData ? h.formData : null)
    }
  }, [holderIdSelected])

  useEffect(() => {
    const getMapQA = (response: any) => {
      const data: any = {}

      data.questions = [
        ...response[0].Respuestas.map((r: any) => r.Pregunta.Pregunta),
        'Altura (en cm)',
        'Peso (en kg)',
      ]
      data.holders = response.map((h: any) => ({
        fullName: `${h.Nombre} ${h.Apellido}`,
        answers: [
          ...h.Respuestas.map((r: any) => (r.Resultado ? 'SI' : 'NO')),
          h.Altura.toString(),
          h.Peso.toString(),
        ],
      }))

      return data
    }

    const getCompletedHolders = async (guid: any) => {
      try {
        const response = await getCompletedHolderListUseCase.run(guid)

        return setCompletedHoldersData(getMapQA(response))
      } catch (error) {
        return setCompletedHoldersData(null)
      }
    }

    const guid = holdersLocal.getHolder()?.Guid

    if (holderIdSelected) {
      getCompletedHolders(guid)
    }
  }, [holderIdSelected])

  useEffect(() => {
    updateStepUseCase.run(Steps.DatosGrupoFamiliar)
  }, [])

  return {
    provincies,
    holdersListIsFetching,
    holdersListError,
    handleOnPressTabs,
    handleOnSubmitOnHoldersForm,
    handleOnHolderCreated,
    handleOnHolderSelected,
    handleOnHolderDeleted,
    handleOnSubmitHolderForm,
    handleOnSaveButton,
    initValues,
    forceUpdateAfterHolderListActions,
    pymeIdErrorMsg,
    msgDisplayer,
    submitFormIsFetching,
    handleOnCloseSuccessSave,
    completedHoldersData,
    getHolders,
    handleRequoteButton,
    handlePostRequoteButton,
    pymeRequoteData,
    pymeRequoteIsFetching,
    hasPymeRequoteError,
  }
}

export default saveFamilyGroupController
