import styled from 'styled-components'
import UI from '../../../../UI'

export const PageContent = styled(UI.Cards.Card)``
export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
`

export const SaveButtonIcon = styled.div`
  margin-top: -1.5px;
  padding-right: 3px;
`

export const PaymentScreenContainer = styled.div`
  display: grid;
  grid-template-areas:
    'header header'
    'buttons buttons'
    'form form'
    'footer footer';
  grid-template-columns: 1fr 1fr;
  width: 100%;
`

export const HeaderContainer = styled.div`
  grid-area: header;
  padding: 1rem 0;
`

export const TextContainer = styled.div`
  margin-top: 1.3rem;
`

export const ButtonsFooter = styled.div`
  grid-area: footer;
  display: flex;
  gap: 1rem;
  padding: 2rem 0;
  width: 34rem;
`

export const PaymentButtons = styled.div<{ isSelected: boolean }>`
  grid-area: buttons;
  display: flex;
  margin-top: 2.8rem;
  height: 4rem;
  width: 34rem;
  gap: 1.2rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 14px;
  border: 1px solid
    ${(props) => (props.isSelected
    ? props.theme.colors.cornflowerBlue
    : props.theme.colors.white)};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  cursor: default;

  &:hover {
    opacity: 0.7;
  }
`

export const FormContainer = styled.div<{ disabled: boolean }>`
  grid-area: form;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  ${(props) => props.disabled && 'pointer-events: none;'}
`

export const BtPaymentContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 30rem;
`

export const IconBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f1f8ff;
  height: 2.2rem;
  width: 2.2rem;
`

export const PaymentContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Row = styled.div`
  display: flex;
  gap: 1rem;
`

export default {
  PageContent,
  PaymentScreenContainer,
  HeaderContainer,
  TextContainer,
  ButtonsFooter,
  IconBackground,
  BtPaymentContainer,
  FormContainer,
  PaymentContent,
  Row,
  SaveButtonIcon,
}
