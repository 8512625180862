import * as yup from 'yup'
import moment from 'moment'

import constants from '../../../../../shared/constants'
import { IProvince } from '../../../../../../../modules/Province/domain/Province'
import { getYearsFromDate } from '../../../../../shared/helpers/date'
import { cuilValidator } from '../../../../../shared/helpers/utils'
import { memberTypesLabel } from '../../../../../../../modules/commons/member'

const {
  formRelated: { validationMsg, validationRegex },
  userRelated: { male, female },
} = constants

const today = moment()
const oneYearAgo = today.subtract(1, 'year')
const familyGroupFormValidationSchema = (
  provincies: IProvince[],
  memberType: string,
) => yup.object().shape({
  name: yup
    .string()
    .min(
      3,
      validationMsg.customAtLeastFieldNameAndCharacterLength('nombre', 3),
    )
    .max(
      30,
      validationMsg.customAtMaxFieldNameAndCharacterLength('nombre', 30),
    )
    .matches(validationRegex.onlyLetters, validationMsg.fieldOnlyLetters)
    .required(validationMsg.fieldRequired),
  lastName: yup
    .string()
    .min(
      3,
      validationMsg.customAtLeastFieldNameAndCharacterLength('apellido', 3),
    )
    .max(
      20,
      validationMsg.customAtMaxFieldNameAndCharacterLength('apellido', 20),
    )
    .matches(validationRegex.onlyLetters, validationMsg.fieldOnlyLetters)
    .required(validationMsg.fieldRequired),
  sex: yup
    .number()
    .oneOf([male, female], validationMsg.sexMustBeChoosed)
    .required(validationMsg.fieldRequired),
  dni: yup
    .number()
    .transform((v) => (Number.isNaN(v) ? undefined : v))
    .test(
      'dnilenght',
      validationMsg.fieldRequired.concat(' y debe tener 8 caracteres'),
      (v) => (v ? v.toString().length === 8 : false),
    ),
  cuil: yup.string().test({
    name: 'cuilValidation',
    exclusive: false,
    params: {},
    message: 'Cuil no válido',
    test(value: any) {
      return value ? cuilValidator(value) : false
    },
  }),
  birthDay: yup.date().test({
    name: 'requiredBirthday',
    exclusive: false,
    params: {},
    message: validationMsg.fieldRequired.concat(' y debe ser mayor de edad'),
    test(value: Date | undefined) {
      return value ? getYearsFromDate(value) > 18 : false
    },
  }),
  email: yup
    .string()
    .email(validationMsg.emailInvalid)
    .required(validationMsg.fieldRequired),
  repeatEmail: yup
    .string()
    .email(validationMsg.emailInvalid)
    .required(validationMsg.fieldRequired),
  age: yup
    .number()
    .min(1, validationMsg.ageMustBeGratherThanCustom(1))
    .max(110, validationMsg.ageMustBeLowerThanCustom(110))
    .required(validationMsg.fieldRequired),
  areaCode: yup
    .string()
    .matches(/^[0-9]+$/g, 'Sólo números')
    .min(2, 'Mínimo 2')
    .max(4, 'Máximo 4')
    .required(validationMsg.fieldRequired),
  phone: yup
    .string()
    .matches(/^[0-9]+$/g, 'Sólo números')
    .min(6, 'Mínimo 6 números')
    .max(12)
    .required(validationMsg.fieldRequired),
  contributions: yup
    .number()
    .test('requerirAporte', validationMsg.fieldRequired, (value: any) => {
      if (
        memberType !== memberTypesLabel.Individuales
          && !value
          && value !== 0
      ) {
        return false
      }
      return true
    })
    .test(
      'min',
      validationMsg.contributionsCouldNotBeLessThanCustom(1),
      (value: any) => {
        if (memberType !== memberTypesLabel.Individuales && value < 1) {
          return false
        }
        return true
      },
    ),
  street: yup.string().required(validationMsg.fieldRequired),
  streetNumber: yup.string().required(validationMsg.fieldRequired),
  floor: yup.string().nullable(),
  province: yup
    .number()
    .min(0, validationMsg.provinceMustBeSelected)
    .required(validationMsg.fieldRequired),
  location: yup.number().test({
    name: 'requerirlocalidad',
    exclusive: false,
    params: {},
    message: 'Debes seleccionar una localidad',
    test(value: any) {
      const pr = provincies?.find(
        (p: IProvince) => p.id === this.parent.province,
      )?.localidades

      if (pr?.length && value === -1) return false

      return pr?.length ? !!value : true
    },
  }),
  postalCode: yup.string().required(validationMsg.fieldRequired),
  includeCouple: yup.bool(),
  coupleName: yup.string().test({
    name: 'requiredCoupleName',
    exclusive: false,
    params: {},
    message: validationMsg.customAtLeastFieldNameAndCharacterLength(
      'nombre',
      3,
    ),
    test(value: string | undefined) {
      let isOk = true

      if (this.parent.includeCouple) {
        isOk = value ? value.length >= 3 : !!value
      }

      return isOk
    },
  }),
  coupleLastName: yup.string().test({
    name: 'requiredCoulpeLastName',
    exclusive: false,
    params: {},
    message: validationMsg.customAtLeastFieldNameAndCharacterLength(
      'apellido',
      3,
    ),
    test(value: string | undefined) {
      let isOk = true

      if (this.parent.includeCouple) {
        isOk = value ? value.length >= 3 : !!value
      }

      return isOk
    },
  }),
  coupleSex: yup.number().test({
    name: 'requiredCoupleSex',
    exclusive: false,
    params: {},
    message: validationMsg.fieldRequired,
    test(value: number | undefined) {
      let isOk = true

      if (this.parent.includeCouple) {
        isOk = [male, female].some((v: number) => v === value)
      }

      return isOk
    },
  }),
  coupleDni: yup
    .number()
    .transform((v) => (Number.isNaN(v) ? undefined : v))
    .test({
      name: 'requiredCoupleDni',
      exclusive: false,
      params: {},
      message: validationMsg.fieldRequired.concat(
        ' y debe tener 8 caracteres',
      ),
      test(value: number | undefined) {
        let isOk = true

        if (this.parent.includeCouple) {
          isOk = value ? value.toString().length === 8 : !!value
        }

        return isOk
      },
    }),
  coupleAreaCode: yup
    .string()
    .matches(/^[0-9]+$/g, 'Sólo números')
    .min(2, 'Mínimo 2')
    .max(4, 'Máximo 4')
    .test(
      'coupleAreaCode',
      validationMsg.fieldRequired,
      (item, testContext) => {
        if (testContext.parent.includeCouple === true && item === undefined) {
          return false
        }
        return true
      },
    ),
  couplePhone: yup
    .string()
    .matches(/^[0-9]+$/g, 'Sólo números')
    .min(6, 'Mínimo 6 números')
    .max(12)
    .test('couplePhone', validationMsg.fieldRequired, (item, testContext) => {
      if (testContext.parent.includeCouple === true && item === undefined) {
        return false
      }
      return true
    }),
  coupleBirthday: yup.date().test({
    name: 'requiredCoupleBirthday',
    exclusive: false,
    params: {},
    message: validationMsg.fieldRequired.concat(
      ' , la pareja no puede ser menor',
    ),
    test(value: Date | undefined) {
      let isOk = true

      if (this.parent.includeCouple) {
        isOk = value ? getYearsFromDate(value) > 18 : false
      }

      return isOk
    },
  }),
  coupleAge: yup.number().test({
    name: 'requiredCoupleAge',
    exclusive: false,
    params: {},
    message: validationMsg.fieldRequired,
    test(value: number | undefined) {
      let isOk = true

      if (this.parent.includeCouple) {
        isOk = value ? value > 0 : false
      }

      return isOk
    },
  }),
  childsAmount: yup.number().max(12, 'La cantidad ingresada es muy alta'),
  childs: yup.array().of(
    yup.object().shape({
      name: yup.string().required(validationMsg.fieldRequired),
      lastName: yup.string().required(validationMsg.fieldRequired),
      birthDay: yup
        .date()
        .max(oneYearAgo, 'Debe ser mayor a 1 año')
        .required(validationMsg.fieldRequired),
      childDni: yup
        .number()
        .test(
          'dnilenght',
          validationMsg.fieldRequired.concat(' y debe tener 8 caracteres'),
          (v) => (v ? v.toString().length === 8 : false),
        ),
      sex: yup
        .number()
        .oneOf([male, female], validationMsg.sexMustBeChoosed)
        .required(validationMsg.fieldRequired),
      age: yup
        .number()
        .min(1, 'no puede ser menor a 1 año')
        .required(validationMsg.fieldRequired),
    }),
  ),
  coveragePlans: yup
    .string()
    .min(0, validationMsg.fieldRequired)
    .matches(/_/g, validationMsg.fieldRequired)
    .required(validationMsg.fieldRequired),
})

export default familyGroupFormValidationSchema
