import { IPymeRepository } from '../../../domain'
import { ICoveragePlansParam } from './CoveragePlansParam'

export default class GetDataCoveragePlans {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async run(params: ICoveragePlansParam): Promise<any> {
    return this.repository.getCoveragePlans(params)
  }
}
