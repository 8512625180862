import { useState } from 'react'
import ValidateAdvisorsSecurityCode from '../../application/useCase/validateAdvisorsSecurityCode'
import { IPymeRepository } from '../../domain'
import shared from '../../../../app/infraestructure/shared'
import { AdvisorSecurityCodeParam } from '../../application/useCase/validateAdvisorsSecurityCode/AdvisorSecurityCodeParam'

const {
  constants: {
    httpStatusCodes: {
      HTTP_INVALID_CREDENTIALS_STATUS,
      HTTP_UNEXPECTED_ERROR_STATUS,
    },
  },
} = shared

const validateAdvisorsSecurityCodeController = ({
  handleSetActiveIndex,
  pymeRepository,
}: {
  handleSetActiveIndex: any
  pymeRepository: IPymeRepository
}) => {
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const validateAdvisorSecurityCodeUseCase = new ValidateAdvisorsSecurityCode(
    pymeRepository,
  )

  const handleOnSubmitForm = async (values: AdvisorSecurityCodeParam) => {
    try {
      setErrorMsg('')
      setIsLoading(true)

      const response = await validateAdvisorSecurityCodeUseCase.validateAdvisorsSecurityCode(
        values,
      )

      if (response.status === HTTP_UNEXPECTED_ERROR_STATUS) {
        return setErrorMsg('El código ingresado es incorrecto')
      }

      if (response.status === HTTP_INVALID_CREDENTIALS_STATUS) {
        return setErrorMsg('No está autorizado')
      }

      return handleSetActiveIndex().next()
    } catch (error) {
      return setErrorMsg('Error inesperado')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    errorMsg,
    isLoading,
    handleOnSubmitForm,
  }
}

export default validateAdvisorsSecurityCodeController
