import { IPymeRepository } from '../../../domain'
import { SaveCompanyDataDTO } from './SaveCompanyDataDTO'

export default class PutDataPyme {
  readonly repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async putDataPyme(params: SaveCompanyDataDTO): Promise<any> {
    return this.repository.saveCompanyData(params)
  }
}
