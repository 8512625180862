import React from 'react'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import Basic from './FieldBasic'

const DropDownContainer = styled.div`
  .react-select__control {
    height: 2rem;
    min-height: 2rem !important;
  }

  .react-select__indicators,
  .react-select__value-container {
    margin-top: -0.18rem;
  }
`

export interface DropDownValues {
  value: any
  label: string
  placeholder?: string
}

export type TDropDown = {
  value?: any
  options: DropDownValues[]
  onChange: (option: any) => void
  onBlur?: (e: any) => void
  label?: string
  name: string
  id?: string
  placeholder?: string
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  isMulti?: boolean
  validationMsg?: string
} & typeof DropDownDefaultProps

export const DropDownDefaultProps = {
  value: '',
  label: '',
  id: 'prov',
  placeholder: '',
  isDisabled: false,
  isLoading: false,
  isClearable: false,
  isMulti: false,
  validationMsg: '',
}

// warning on this component will be fixed in next release
// https://github.com/JedWatson/react-select/issues/3915
// https://github.com/JedWatson/react-select/issues/4293#issuecomment-743781662
const DropDown = ({
  options,
  name,
  label,
  validationMsg,
  isMulti,
  value,
  isLoading,
  isClearable,
  onChange,
  onBlur,
  isDisabled,
  placeholder,
  id,
}: TDropDown) => {
  // eslint-disable-next-line
  const filterInputValues = (inputValue: string) => {
    // eslint-disable-next-line
    return options?.filter((i: any) => i.label.toLowerCase().includes(inputValue.toLowerCase()),)
  }

  const loadOptions = (inputValue: any) => new Promise<any>((resolve) => {
    setTimeout(() => {
      resolve(filterInputValues(inputValue))
    }, 1000)
  })

  const handleOnChange = (e: any) => {
    onChange(e)
  }

  const handleOnBlur = (e: any) => {
    if (onBlur) onBlur(e)
  }

  return (
    <Basic name={name} label={label} validationMsg={validationMsg}>
      <DropDownContainer>
        <AsyncSelect
          id={id}
          isMulti={isMulti}
          value={options.find((o: DropDownValues) => o.value === value) || null}
          defaultValue={{ label: '', value: '' }}
          isLoading={isLoading || !options}
          classNamePrefix="react-select"
          cacheOptions
          isClearable={isClearable}
          defaultOptions={options}
          loadOptions={loadOptions}
          onChange={handleOnChange}
          isDisabled={isDisabled}
          placeholder={placeholder}
          onBlur={handleOnBlur}
        />
      </DropDownContainer>
    </Basic>
  )
}

DropDown.defaultProps = DropDownDefaultProps

export default React.memo(DropDown)
