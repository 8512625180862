import { colors } from './colors'

export type TiconSize = {
  xxs: string
  xs: string
  sm: string
  md: string
  mdlg: string
  lg: string
}

export const iconSizes: { [property in keyof TiconSize | string]: string } = {
  xxs: '16px',
  xs: '20px',
  sm: '24px',
  md: '28px',
  mdlg: '32px',
  lg: '36px',
}

export type TfontSizesKeys = {
  xxs: string
  xs: string
  sm: string
  md: string
  mdLg: string
  lg: string
  xl: string
  xxl: string
}
// in rems if root font = 16px = 1rem;
const fontSizes: { [property in keyof TfontSizesKeys | string]: string } = {
  xxs: '0.625rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  mdLg: '1.125rem',
  lg: '1.25rem',
  xl: '1.375rem',
  xxl: '2rem',
}

export type TTypeofFontSizes = typeof fontWeights
export type TFontSizeKeys = keyof TTypeofFontSizes

export type TFontWeight = {
  xxs: number
  xs: number
  sm: number
  smL: number
  mdS: number
  md: number
  lg: number
}

const fontWeights: { [property in keyof TFontWeight | string]: number } = {
  xxs: 200,
  xs: 300,
  sm: 400,
  smL: 500,
  mdS: 600,
  md: 700,
  lg: 800,
}

export type TTypeofFontWeights = typeof fontWeights
export type TFontWeightKeys = keyof TTypeofFontWeights

export const breakPoints = { desktop: '1024px', small: '800px' }

const theme = {
  colors,
  iconSizes,
  fontSizes,
  fontWeights,
  breakPoints,
}

export default theme
