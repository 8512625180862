import React from 'react'
import styled from 'styled-components'
import { CheckCircleIcon, CloseIcon, ErrorCircleIcon } from './Icon'
import Text from './Text'

const variants = {
  success: 'success',
  error: 'error',
}

const Container = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`
const MessageBasic = styled.div<{ variant: keyof typeof variants }>`
  border-radius: 10px;
  min-width: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  background-color: ${(props) => {
    if (props.variant === variants.success) {
      return props.theme.colors.toastSuccess
    }
    if (props.variant === variants.error) return props.theme.colors.error

    return props.theme.colors.tundora
  }};
  padding: 8px 16px 8px 16px;
`

const IconContainer = styled.div``

type TMessageProps = {
  variant: keyof typeof variants
  children: string
  onClose: () => void
}

const Message = ({ variant, children, onClose }: TMessageProps) => (
  <MessageBasic variant={variant}>
    <Container>
      {variant === variants.success && <CheckCircleIcon color="eucalyptus" />}
      {variant === variants.error && <ErrorCircleIcon color="carmine" />}
      <Text>{children}</Text>
    </Container>
    <IconContainer onClick={onClose}>
      <CloseIcon color="tundora" isCursor />
    </IconContainer>
  </MessageBasic>
)

export default Message
