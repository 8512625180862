import React, { useContext, useEffect } from 'react'
import TabsComponent from './TabsComponent'
import FamilyGroupForm from './Form/FamilyGroupForm'
import ProvinceRepository from '../../../../../../modules/Province/infraestructure/repositories/provinceRepository'
import saveFamilyGroupController from '../../../../../../modules/Pyme/infrastructure/controllers/saveFamilyGroupController'
import HeaderStep2 from './HeaderStep2'
import ListOfHolders from './ListOfHolders'
import * as S from './styles'
import UI from '../../../../UI'
import { openExternalLink } from '../../../../shared/helpers/utils'
import { IFamilyGroupRepository } from '../../../../../../modules/FamilyGroup/domain/entities'
import TotalGroups, { enabled } from './TotalGroups'
import { IHandleSetActiveIndex } from '../../../../hooks/useStepper'
import { IPymeRepository } from '../../../../../../modules/Pyme/domain'
import DeleteHolderModalContent, { CancelSaveFamilyGroup,
  RepricingFamilyGroup } from './ModalContent'
import CompletedHolders from './CompletedHolders'
import { DjStateValues } from '../../../Djs/constants'
import DjsTable from './DjsTable'
import holdersLocal from './HolderLocalModule'

import stores from '../../../../stores'

const FamilyGroup = ({
  provinceRepository,
  familyGroupRepository,
  handleSetActiveIndex,
  pymeRepository,
}: {
  provinceRepository: ProvinceRepository
  familyGroupRepository: IFamilyGroupRepository
  handleSetActiveIndex: () => IHandleSetActiveIndex
  pymeRepository: IPymeRepository
}) => {
  const {
    provincies,
    handleOnPressTabs,
    handleOnSubmitOnHoldersForm,
    handleOnHolderCreated,
    handleOnHolderDeleted,
    handleOnHolderSelected,
    handleOnSubmitHolderForm,
    initValues,
    holdersListIsFetching,
    handleOnSaveButton,
    handleOnCloseSuccessSave,
    submitFormIsFetching,
    msgDisplayer,
    completedHoldersData,
    getHolders,
    handleRequoteButton,
    handlePostRequoteButton,
    pymeRequoteData,
    pymeRequoteIsFetching,
    hasPymeRequoteError,
  } = saveFamilyGroupController({
    provinceRepository,
    familyGroupRepository,
    pymeRepository,
  })

  const onClickPlan = () => openExternalLink('https://www.omint.com.ar/PlanDeSalud/Planes')

  const { handleDisplayModal, handleModalContent } = useContext(
    UI.Modal.ModalContext,
  )

  const { completedFG } = enabled()

  const handleOnPressDeleteHolder = (id: string) => {
    handleDisplayModal(true)
    handleModalContent(
      <DeleteHolderModalContent
        onPressCancel={() => handleDisplayModal(false)}
        onPressConfirm={() => {
          handleOnHolderDeleted(id)
          handleDisplayModal(false)
        }}
      />,
    )
  }

  const handleOnPressGoBack = () => {
    handleDisplayModal(true)
    handleModalContent(
      <CancelSaveFamilyGroup
        onPressCancel={() => handleDisplayModal(false)}
        onPressConfirm={() => {
          handleDisplayModal(false)
          handleSetActiveIndex().prev()
        }}
      />,
    )
  }

  const handleOnPressContinue = async () => {
    handleDisplayModal(true)
    handleRequoteButton()
  }

  const recentRequoteDate = new Date()
  const pymePrice: any = pymeRequoteData
  const context = useContext(stores.PymeRequoteContext)
  const requoteDateTime = {
    dmy: '',
    time: '',
  }

  const today = () => {
    requoteDateTime.dmy = recentRequoteDate.toLocaleDateString()
    requoteDateTime.time = recentRequoteDate.toLocaleTimeString()
    return requoteDateTime
  }
  useEffect(() => {
    handleModalContent(
      <RepricingFamilyGroup
        onPressCancel={() => {
          handleDisplayModal(false)
        }}
        onPressConfirmRequote={() => {
          context.requotePriceContext(pymePrice.Total, today())
          handlePostRequoteButton(
            handleSetActiveIndex().next,
            handleDisplayModal,
          )
        }}
        onPressRequoteAgain={handleRequoteButton}
        price={pymeRequoteData}
        isLoading={pymeRequoteIsFetching}
        hasError={hasPymeRequoteError}
      />,
    )
  }, [pymeRequoteIsFetching])

  return (
    <UI.Cards.Card>
      <S.FamilyGroupContainer>
        <S.PlansContainer>
          <UI.Button variant="outlined" onClick={onClickPlan}>
            Ver planes
          </UI.Button>
        </S.PlansContainer>
        <S.HeaderContainer>
          <HeaderStep2 />
          <S.TotalGroupContainer>
            <TotalGroups />
          </S.TotalGroupContainer>
        </S.HeaderContainer>
        <S.TabsContainer>
          <S.GroupsContainer>
            <TabsComponent onPressTabs={handleOnPressTabs} />
            <CompletedHolders />
          </S.GroupsContainer>
        </S.TabsContainer>
        <S.ListOfHoldersContainer>
          <ListOfHolders
            onClickSelectHandler={handleOnHolderSelected}
            onClickDeleteHandler={handleOnPressDeleteHolder}
            onClickAddHolderHandler={handleOnHolderCreated}
            isFetching={holdersListIsFetching}
          />
        </S.ListOfHoldersContainer>
        <S.FormContainer>
          <S.FormLoadingWrapper isLoading={holdersListIsFetching}>
            <UI.LoadingWrapper isLoading={holdersListIsFetching}>
              {holdersLocal.getHolderSelectedId() ? (
                <>
                  <FamilyGroupForm
                    initValues={initValues}
                    onSubmitHolderForm={handleOnSubmitHolderForm}
                    provincies={provincies}
                    onSubmitOnHoldersForm={handleOnSubmitOnHoldersForm}
                    pymeRepository={pymeRepository}
                    provinceRepository={provinceRepository}
                    familyGroupRepository={familyGroupRepository}
                    getHolders={getHolders}
                    memberType={holdersLocal.getMemberType()}
                  />
                  {completedHoldersData && (
                    <DjsTable completedHolders={completedHoldersData} />
                  )}
                </>
              ) : (
                <p>Seleccione un titular</p>
              )}
            </UI.LoadingWrapper>
          </S.FormLoadingWrapper>
          <hr />
          {holdersLocal.getHolder()?.Paso === DjStateValues.enviada ? (
            <S.OnHoldMessage>
              <UI.Text size="xs">
                A la espera de recepción de Declaración Jurada completa por
                parte del titular
              </UI.Text>
            </S.OnHoldMessage>
          ) : null}
          <S.ButtonsFooterContainer>
            <UI.Button variant="outlined" onClick={handleOnPressGoBack}>
              Anterior
            </UI.Button>
            <UI.Button
              variant="outlined"
              type="button"
              onClick={handleOnSaveButton}
              isLoading={submitFormIsFetching}
              disabled={!holdersLocal.getHolderSelectedId()}
            >
              <S.StickyButtonIcon>
                <UI.Icons.Save2Icon size="xxs" color={!holdersLocal.getHolderSelectedId() ? 'tundora' : 'mariner'} />
              </S.StickyButtonIcon>
              <UI.Text size="xs" color={!holdersLocal.getHolderSelectedId() ? 'tundora' : 'mariner'} weight="mdS">
                Guardar
              </UI.Text>
            </UI.Button>
            <UI.Button
              type="button"
              disabled={!completedFG.continueBt}
              onClick={() => handleOnPressContinue()}
            >
              Continuar
            </UI.Button>
          </S.ButtonsFooterContainer>
        </S.FormContainer>
      </S.FamilyGroupContainer>
      {msgDisplayer.msg && (
        <S.MessageContainer>
          <UI.Message
            variant={msgDisplayer.error ? 'error' : 'success'}
            onClose={handleOnCloseSuccessSave}
          >
            {msgDisplayer.msg}
          </UI.Message>
        </S.MessageContainer>
      )}
    </UI.Cards.Card>
  )
}

export default FamilyGroup
