import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'

// Datepicker PROPS https://reactdatepicker.com
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'

import 'react-datepicker/dist/react-datepicker.css'

import useIsMobile from '../hooks/useIsMobile'
import Basic from './FieldBasic'
import { CalendarIcon } from './Icon'

registerLocale('es', es)

const DateContainer = styled.div`
  .react-datepicker__input-container > input {
    width: 100%;
    height: 2rem;
    border: 1px solid ${({ theme }) => theme.colors.silverChalice};
    border-radius: 3px;
    color: ${({ theme }) => theme.colors.greyishBrownTwo};
    padding-left: 0.5rem;
    background-color: ${({ theme }) => theme.colors.alabaster};

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.mariner}!important;
      outline-offset: 0px;
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.silverChalice};
      font-size: 0.8rem;
    }
  }
`
const IconContainer = styled.div`
  position: absolute;
  margin-top: -1.8rem;
  margin-left: 10.5rem;

  @media (max-width: 799px) {
    display: none;
  }
`

interface IDate {
  label?: any
  name: string
  // usually this is used when we need to trigger other functions outside the form or whatever
  onChangeDate?: (date: Date) => void
  onChange: any
  date?: any
  placeholder?: string
  validationMsg?: string | any
  showMonthDropdown?: boolean
  showYearDropdown?: boolean
  disabled?: boolean
}

const Date: FC<IDate> = ({
  name,
  label,
  onChange,
  onChangeDate,
  date,
  placeholder,
  validationMsg,
  showMonthDropdown,
  showYearDropdown,
  disabled,
}) => {
  const [newDate, setDate] = useState(date)
  const isMobile = useIsMobile()

  useEffect(() => {
    onChange(name, newDate)
  }, [newDate, name, onChange])

  return (
    <Basic name={name} label={label} validationMsg={validationMsg}>
      <DateContainer>
        <DatePicker
          selected={date}
          showPopperArrow={!isMobile}
          placeholderText={placeholder}
          onChange={(dateSelected: Date) => {
            if (onChangeDate) {
              onChangeDate(dateSelected)
            }
            setDate(dateSelected)
          }}
          locale="es"
          dateFormat="dd/MM/YYY"
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          disabled={disabled}
        />
        <IconContainer>
          <CalendarIcon size="xxs" color="tundora" isCursor />
        </IconContainer>
      </DateContainer>
    </Basic>
  )
}

Date.defaultProps = {
  label: '',
  date: '',
  placeholder: 'DD/MM/YYYY',
  validationMsg: '',
  onChangeDate: () => {},
  showMonthDropdown: false,
  showYearDropdown: false,
  disabled: false,
}

export default Date
