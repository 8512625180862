export type TColorsM = {
  deepBlue: string
  powderBlue: string
  powderBlueTwo: string
  darkishBlue: string
  darkishBlueTwo: string
  flatBlue: string
  sapphire: string
  facebookBlue: string
  darkGreyBlue: string
  paleGrey: string
  paleGreyTwo: string
  paleGreyThree: string
  paleGreyFour: string
  paleGreyFive: string
  sky: string
  lightPeriwinkle: string
  metallicBlue: string
  brownishGrey: string
  warmGrey: string
  warmGreyTwo: string
  warmGreyThree: string
  warmGreyFour: string
  carmine: string
  cornflowerBlue: string
  grey: string
  greyBlue: string
  paleBlue: string
  perrywinkle: string
  chatbotGrey: string
  placeholderGrey: string
  green: string
  greenTwo: string
  cloudyBlue: string
  greyishBrown: string
  yellow: string
  greyishBrownTwo: string
  lightGrey: string
  maize: string
  maize20: string
  scarlet: string
  scarlet10: string
  lightNavy: string
  lightPink: string
  white: string
  emergency: string
  warning: string
  error: string
  info: string
  success: string
  toastSuccess: string
  concrete: string
  aliceBlue: string
  resolutionBlue: string
  sail: string
  emperor: string
  mariner: string
  alto: string
  midnightBlue: string
  eucalyptus: string
  silver: string
  shipCove: string
  foam: string
  chathamsBlue: string
  altoAlpha: string
  tundora: string
  surfieGreen: string
  transparent: string
  milkPunch: string
  california: string
}
// Use this url in order to name the color: http://chir.ag/projects/name-that-color
export const colors: { [property in keyof TColorsM | string]: string } = {
  deepBlue: '#001689',
  powderBlue: '#a9d4ff',
  powderBlueTwo: '#c0dffe',
  darkishBlue: '#023979',
  darkishBlueTwo: '#00347c',
  flatBlue: '#396fa5',
  sapphire: '#2136a5',
  facebookBlue: '#4267B2',
  darkGreyBlue: '#34495e',
  paleGrey: '#edf6ff',
  paleGreyTwo: '#dfdce0',
  paleGreyThree: '#f3f3f3',
  paleGreyFour: '#cce8ff',
  paleGreyFive: '#f4faff',
  sky: '#7fbefe',
  lightPeriwinkle: '#d4e0ec',
  metallicBlue: '#517293',
  brownishGrey: '#696969',
  warmGrey: '#808080',
  warmGreyTwo: '#979797',
  warmGreyThree: '#9b9b9b',
  warmGreyFour: '#919191',
  carmine: '#ab0015',
  cornflowerBlue: '#5f9cd8',
  grey: '#d6d6d6',
  greyBlue: '#8aa7c3',
  paleBlue: '#e7f2fd',
  perrywinkle: '#88b7e6',
  chatbotGrey: '#464646',
  placeholderGrey: '#b6b6b6',
  green: '#417505',
  greenTwo: '#e7efde',
  cloudyBlue: '#bbccde',
  greyishBrown: '#4a4a4a',
  yellow: '#ebbf21',
  greyishBrownTwo: '#585858',
  lightGrey: '#dddddd',
  maize: '#ffdc4e',
  maize20: 'rgba(255, 220, 78, 0.2)',
  scarlet: '#d0021b',
  scarlet10: 'rgba(208, 2, 27, 0.1)',
  lightNavy: '#122c76',
  lightPink: '#ffe2e6',
  wildSand: '#f4f4f4',
  emergency: '#910A0A',
  warning: '#FFED48',
  dawnPink: '#efe6de',
  error: '#F3A3AF',
  info: '#94CBFF',
  success: '#ABDA7B',
  toastSuccess: '#E7EFDE',
  concrete: '#F2F2F2',
  aliceBlue: '#F5FCFF',
  resolutionBlue: '#00387E',
  sail: '#A0D9F7',
  emperor: '#4F4F4F',
  mariner: '#2F79C2',
  alto: '#D8D8D8',
  midnightBlue: '#00205C',
  eucalyptus: '#208149',
  silver: '#C4C4C4',
  shipCove: '#678FB8',
  foam: '#ECF7FD',
  chathamsBlue: '#123979',
  silverChalice: '#AFAFAF',
  alabaster: '#FAFAFA',
  white: '#FFFFFF',
  altoAlpha: 'rgba(212, 212, 212, 0.3)',
  tundora: '#4D4D4D',
  surfieGreen: '#0E8973',
  transparent: 'transparent',
  milkPunch: '#FFF7D8',
  california: '#FF9E0D',
}

export type TtypeofColors = typeof colors

export type TColors = keyof TtypeofColors
