import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'

import StyleProvider from '../shared/styles/StylesProvider'
import Login from '../Pages/Login'
import PrivateRoute from './PrivateRoute'
import WelcomePage from '../Pages/FirstLogin/FirstLoginPage'
import HomeScreen from '../Pages/Home'
import RedirectRoot from './RedirectRoot'
import RedirectResetPassword from './RedirectResetPassword'
import PageCompanyApplication from '../Pages/CompanyApplication'
import UpdatePasswordPage from '../Pages/UpdatePassword'

import endpoints from '../shared/constants/endpoints'
import UI from '../UI'
import RepositoriesProvider from './RepositoriesProvider'
import DJsGuard from './DjsGuard'
import RedirectAfterPasswordUpdate from '../Pages/RedirectAfterPasswordUpdate'

export const Routes = () => {
  const {
    pymeRepository,
    provinceRepository,
    familyGroupRepository,
    loginRepository,
    djsRepository,
    questionRepository,
  } = RepositoriesProvider()

  return (
    <StyleProvider>
      <UI.Modal.ModalProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              <RedirectResetPassword>
                <RedirectRoot>
                  <Login loginRepository={loginRepository} />
                </RedirectRoot>
              </RedirectResetPassword>
            </Route>
            <Route exact path="/claveRenovada">
              <RedirectAfterPasswordUpdate />
            </Route>

            <PrivateRoute path="/welcome">
              <WelcomePage pymeRepository={pymeRepository} />
            </PrivateRoute>
            <PrivateRoute path="/renovarClave">
              <UpdatePasswordPage loginRepository={loginRepository} />
            </PrivateRoute>

            <PrivateRoute path="/home">
              <HomeScreen pymeRepository={pymeRepository} />
            </PrivateRoute>

            <PrivateRoute path={endpoints.registrationRequest}>
              <PageCompanyApplication
                pymeRepository={pymeRepository}
                provinceRepository={provinceRepository}
                familyGroupRepository={familyGroupRepository}
              />
            </PrivateRoute>

            <Route path="/djs/:guid">
              <DJsGuard
                familyGroupRepository={familyGroupRepository}
                djsRepository={djsRepository}
                questionRepository={questionRepository}
              />
            </Route>
          </Switch>
        </Router>
      </UI.Modal.ModalProvider>
    </StyleProvider>
  )
}

export default Routes
