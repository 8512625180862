import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import UI from '../../../UI'
import { imagesMimeTypes } from '../../../shared/constants/mimeTypes'
import customizeAccountController, { Step4Form } from '../../../../../modules/Pyme/infrastructure/controllers/customizeAccountController'
import { IPymeRepository } from '../../../../../modules/Pyme/domain'

const validMimeTypes = [
  imagesMimeTypes.jpe,
  imagesMimeTypes.jpeg,
  imagesMimeTypes.png,
  imagesMimeTypes.jpg,
]
const validateStep4 = yup.object().shape({
  guardarLogo: yup
    .mixed()
    .required('Debe ingresar un logo')
    .test(
      'fileFormat',
      'debe ser un archivo valido, png, jpg, jpeg',
      (value) => value && validMimeTypes.includes(value.type),
    ),
})

const initialValues: Step4Form = { guardarLogo: '' }

// Screen where user customize their account
const Step4 = ({
  pymeRepository,
  handleSetActiveIndex,
}: {
  pymeRepository: IPymeRepository
  handleSetActiveIndex: any
}) => {
  const { isLoading, errorMsg, handleOnSubmit, handleOnUploadLater } = customizeAccountController({
    handleSetActiveIndex,
    pymeRepository,
  })

  return (
    <>
      <UI.Text color="mariner" size="xl" weight="md">
        Ingresá tu imagen o logo
      </UI.Text>
      <Formik
        initialValues={initialValues}
        validationSchema={validateStep4}
        onSubmit={(value: Step4Form, { setSubmitting }) => {
          setSubmitting(false)
          handleOnSubmit(value)
        }}
      >
        {(props: {
          errors: {
            guardarLogo: string
          }
          handleSubmit: any
          isValid: boolean
          isSubmitting: boolean
          setFieldValue: any
        }) => (
          <form onSubmit={props.handleSubmit}>
            <UI.Text color="carmine">{errorMsg}</UI.Text>
            <UI.Image
              name="guardarLogo"
              id="guardarLogo"
              alt="logo"
              onChange={props.setFieldValue}
              label="Adjuntá tu archivo"
              withPreview
              validationMsg={props.errors.guardarLogo}
            />
            <UI.Button
              type="submit"
              disabled={!props.isValid || props.isSubmitting}
              isLoading={isLoading}
            >
              Guardar
            </UI.Button>
          </form>
        )}
      </Formik>
      <UI.Text
        underlined
        color="mariner"
        weight="md"
        isCursor
        onClick={handleOnUploadLater}
      >
        Subir después
      </UI.Text>
    </>
  )
}

export default Step4
