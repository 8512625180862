import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import Text from '../../UI/Text'
import { CardBasic } from '../../UI/Card'

const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 1rem 0rem 1rem;
`

const CardContent = styled.div`
  width: 100%;
  padding: 0.1rem 1rem 0.7rem 1rem;
`

export const CardNoLine: FC<{
  children: ReactNode
  title: string
}> = ({ children, title }) => (
  <CardBasic>
    <CardHeader>
      <Text color="mariner" weight="mdS">
        {title}
      </Text>
    </CardHeader>
    <CardContent>{children}</CardContent>
  </CardBasic>
)

export default {
  CardNoLine,
}
