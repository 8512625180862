import React from 'react'
import UI from '../../../UI'
import confirmDataController from '../../../../../modules/Pyme/infrastructure/controllers/confirmDataController'
import { IPymeRepository } from '../../../../../modules/Pyme/domain'

// Screen where user confirm data
const Step3 = ({
  handleSetActiveIndex,
  pymeRepository,
}: {
  handleSetActiveIndex: any
  pymeRepository: IPymeRepository
}) => {
  const { dataPyme, errorMsg, handleOnPressSubmit, isFetching } = confirmDataController({
    handleSetActiveIndex,
    pymeRepository,
  })

  return isFetching ? (
    <UI.Spinner />
  ) : (
    <>
      <UI.Text color="mariner" weight="lg" size="xl">
        Confirmá los datos de la empresa
      </UI.Text>
      <UI.Text>{`Razón social: ${dataPyme?.RazonSocial} `}</UI.Text>
      <UI.Text>{`CUIT de la empresa: ${dataPyme?.CUIT}`}</UI.Text>
      <UI.Text>{`Mail: ${dataPyme?.Mail}`}</UI.Text>
      <UI.Text>{`Teléfono: ${dataPyme?.Telefono}`}</UI.Text>
      <UI.Text>{`Provincia: ${dataPyme?.Provincia}`}</UI.Text>
      {dataPyme?.Localidad && (
        <UI.Text>{`Localidad: ${dataPyme?.Localidad}`}</UI.Text>
      )}
      <UI.Text color="scarlet">{errorMsg}</UI.Text>
      {dataPyme && (
        <UI.Button onClick={handleOnPressSubmit}>Continuar</UI.Button>
      )}
    </>
  )
}

export default Step3
