import { TChild,
  TFamilyGroupFormValues } from '../../../../app/infraestructure/Pages/CompanyApplication/Steps/Step2/constants'
import constants from '../../../../app/infraestructure/shared/constants'
import { memberTypesLabel } from '../../../commons/member'
import { IFamilyGroupRepository } from '../../domain/entities'

type PostHolderResponse = {
  error: boolean
  msg: string
}

const postHolderResponse = (
  error: boolean,
  msg: string,
): PostHolderResponse => ({
  error,
  msg,
})

const isHoldersDataComplete = (
  formValues: TFamilyGroupFormValues,
  memberType: string,
): boolean => {
  const hasContributions = () => {
    if (
      memberType === memberTypesLabel.Individuales
      && formValues?.contributions === 0
    ) {
      return true
    }
    if (formValues?.contributions > 0) return true
    return false
  }

  if (!formValues.name) return false
  if (!formValues.lastName) return false
  if (!constants.userRelated.sexValues.includes(formValues.sex)) return false
  if (!formValues.dni) return false
  if (!formValues.cuil) return false
  if (!formValues.birthDay) return false
  if (!formValues.email) return false
  if (!formValues.age) return false
  if (!formValues.phone) return false
  if (!hasContributions()) return false
  if (!formValues.street) return false
  if (!formValues.streetNumber) return false
  if (!formValues.province) return false
  if (!formValues.postalCode) return false

  return true
}

const isHoldersCoupleComplete = (
  formValues: TFamilyGroupFormValues,
): boolean => {
  if (!formValues.includeCouple) return true
  if (!formValues.coupleName) return false
  if (!formValues.coupleLastName) return false
  if (!constants.userRelated.sexValues.includes(formValues.coupleSex)) {
    return false
  }
  if (!formValues.coupleDni) return false
  if (!formValues.coupleBirthday) return false
  if (!formValues.couplePhone) return false

  return true
}

const areHoldersChildsComplete = (
  formValues: TFamilyGroupFormValues,
): boolean => {
  if (formValues.childs.length === 0) return true

  const areChildComplete: boolean[] = formValues.childs.map(
    (children: TChild) => {
      if (!children.name) return false
      if (!children.lastName) return false
      if (!constants.userRelated.sexValues.includes(children.sex)) return false
      if (!children.childDni) return false
      if (!children.birthDay) return false

      return true
    },
  )

  return areChildComplete.every((v) => v === true)
}

export default class PostHolderDjs {
  readonly repository: IFamilyGroupRepository

  constructor(repository: IFamilyGroupRepository) {
    this.repository = repository
  }

  async run(guid: string, memberType: string): Promise<PostHolderResponse> {
    try {
      const familyGroupDetail = await this.repository.getFamilysGroupDetail(
        guid,
      )

      if (!familyGroupDetail) {
        return postHolderResponse(
          true,
          'Faltan datos del titular y/o grupo familiar',
        )
      }
      if (!isHoldersDataComplete(familyGroupDetail, memberType)) {
        return postHolderResponse(true, 'Faltan datos del titular a completar')
      }
      if (!isHoldersCoupleComplete(familyGroupDetail)) {
        return postHolderResponse(true, 'Faltan datos de la Pareja a completar')
      }
      if (!areHoldersChildsComplete(familyGroupDetail)) {
        return postHolderResponse(
          true,
          'Faltan completar datos de algun/os hijos',
        )
      }

      const response = await this.repository.postHolderDjs(guid)

      if (response.status !== constants.httpStatusCodes.HTTP_OK_STATUS) {
        postHolderResponse(true, 'Error 500, no se pudo enviar la solicitud')
      }

      return postHolderResponse(false, '')
    } catch (error) {
      return postHolderResponse(true, 'Se produjo un error insperado')
    }
  }
}
