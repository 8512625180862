import { IFamilyGroupRepository } from '../../domain/entities'

export default class GetCompletedHoldersData {
  readonly repository: IFamilyGroupRepository

  constructor(repository: IFamilyGroupRepository) {
    this.repository = repository
  }

  async run(guid: string): Promise<any> {
    return this.repository.getCompletedHoldersData(guid)
  }
}
