import React from 'react'
import styled from 'styled-components'
import UI from '../../../../UI'
import { numbers } from '../../../../shared/helpers'
import { ErrorWarningIcon } from '../../../../UI/Icon'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem 0 3rem;
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 4rem;
  gap: 1rem;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const WarningMessage = styled.div`
  display: flex;
  gap: 0.54rem;
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.5rem;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.dawnPink};
  margin-bottom: 1rem;
`

const DeleteHolderModalContent = ({
  onPressCancel,
  onPressConfirm,
}: {
  onPressCancel: () => void
  onPressConfirm: () => void
}) => (
  <ModalContainer>
    <UI.Text color="mariner" weight="mdS" size="lg">
      Atención
    </UI.Text>
    <BodyContainer>
      <UI.Text color="chathamsBlue">
        Se eliminará el colaborador y todo su grupo familiar.
      </UI.Text>
    </BodyContainer>
    <ButtonsContainer>
      <UI.Button variant="outlined" onClick={onPressCancel}>
        Cancelar
      </UI.Button>
      <UI.Button onClick={onPressConfirm}>Confirmar</UI.Button>
    </ButtonsContainer>
  </ModalContainer>
)

const CancelSaveFamilyGroup = ({
  onPressCancel,
  onPressConfirm,
}: {
  onPressCancel: () => void
  onPressConfirm: () => void
}) => (
  <ModalContainer>
    <UI.Text color="mariner" weight="mdS" size="lg">
      Atención
    </UI.Text>
    <BodyContainer>
      <UI.Text color="chathamsBlue" weight="smL" size="mdLg">
        ¿Estás seguro que querés abandonar el proceso?
      </UI.Text>
      <UI.Text>
        Los datos que no hayan sido guardados hasta el momento se perderán.
      </UI.Text>
    </BodyContainer>
    <ButtonsContainer>
      <UI.Button variant="outlined" onClick={onPressCancel}>
        Volver
      </UI.Button>
      <UI.Button onClick={onPressConfirm}>Salir sin guardar</UI.Button>
    </ButtonsContainer>
  </ModalContainer>
)

const RepricingFamilyGroup = ({
  onPressCancel,
  onPressConfirmRequote,
  onPressRequoteAgain,
  price,
  isLoading,
  hasError,
}: {
  onPressCancel: () => void
  onPressConfirmRequote: () => void
  onPressRequoteAgain: () => void
  price: any
  isLoading: boolean
  hasError: boolean
}) => (
  <ModalContainer>
    {isLoading && (
      <>
        <UI.Text color="mariner" weight="mdS" size="lg">
          Cotización en curso
        </UI.Text>
        <BodyContainer>
          <UI.Text color="chathamsBlue">Esto puede demorar un poco</UI.Text>
          <UI.Spinner />
        </BodyContainer>
      </>
    )}

    {hasError && !isLoading && (
      <>
        <WarningMessage>
          <ErrorWarningIcon color="california" size="lg" />
        </WarningMessage>
        <UI.Text color="mariner" weight="mdS" size="lg">
          Ocurrió un error
        </UI.Text>
        <BodyContainer>
          <UI.Text color="chathamsBlue">Por favor reintentá más tarde</UI.Text>
        </BodyContainer>
        <ButtonsContainer>
          <UI.Button variant="outlined" onClick={onPressCancel}>
            Anterior
          </UI.Button>
          <UI.Button onClick={onPressRequoteAgain}>Reintentar</UI.Button>
        </ButtonsContainer>
      </>
    )}

    {!hasError && !isLoading && price && (
      <>
        <UI.Text color="mariner" weight="mdS" size="lg">
          Atención
        </UI.Text>
        <BodyContainer>
          <TextContainer>
            <UI.Text color="chathamsBlue">
              Las modificaciones realizadas cambiarán el
            </UI.Text>
            <UI.Text color="chathamsBlue">
              valor inicial de tu cotización.
            </UI.Text>
          </TextContainer>
        </BodyContainer>
        <UI.Text color="darkishBlue" weight="mdS" size="mdLg">
          Valor de la nueva cotización
        </UI.Text>
        <BodyContainer>
          <UI.Text color="darkishBlue" weight="lg" size="xxl">
            {`$ ${
              price?.Total
                ? `${numbers.numberWithSeparators({
                  number: price.Total,
                  withThousandSeparator: true,
                })}`
                : ''
            }`}
          </UI.Text>
        </BodyContainer>
        <ButtonsContainer>
          <UI.Button variant="outlined" onClick={onPressCancel}>
            Seguir editando
          </UI.Button>
          <UI.Button onClick={onPressConfirmRequote}>
            Aceptar y continuar
          </UI.Button>
        </ButtonsContainer>
      </>
    )}
  </ModalContainer>
)

export default DeleteHolderModalContent
export { CancelSaveFamilyGroup, RepricingFamilyGroup }
