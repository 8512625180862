import React, { FC } from 'react'
import styled from 'styled-components'
import UI from '../../../../UI'

const RequestDetailsContainer = styled.div`
  border: 0.5px solid #c6c6c6;
  width: 67%;
  border-radius: 4px;
  display: grid;
  grid-template-areas:
    'familyGroups amountPayable'
    'personalInformation paymentMethod';
  grid-column-gap: 64px;
  grid-row-gap: 24px;
  padding: 1.5rem 1rem;
`

const NumberOfFamilyGroups = styled.div`
  grid-area: familyGroups;
`

const AmountPayable = styled.div`
  grid-area: amountPayable;
`

const PersonalInformation = styled.div`
  grid-area: personalInformation;
`

const PaymentMethod = styled.div`
  grid-area: paymentMethod;
`

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Title = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.chathamsBlue};
  font-weight: ${({ theme }) => theme.fontWeights.mdS};
`

const Line = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.silver};
`

const DetailsC = styled.div`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
const DetailsValueContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
// -----------------------
type TDetail = {
  label: string
  value: string
}

type TDataPymeSummary = {
  numberOfFamilyGroups: TDetail
  personalInformation: TDetail
  paymentMethod: TDetail
  amountPayable: TDetail
}

const Details: FC<{
  title: string
  details: any
}> = ({ title, details }) => (
  <DetailContainer>
    <Title>{title}</Title>
    <Line />
    <DetailsC>
      {(details || []).map(
        (props: TDetail) => props && (
        <Row key={props.label || props.value}>
          <UI.Text size="sm" weight="mdS" color="tundora">
            {props.label}
          </UI.Text>
          <DetailsValueContainer>
            {Array.isArray(props.value) ? (
              props.value.map((value) => (
                <UI.Text size="sm" weight="sm" color="tundora">
                  {value}
                </UI.Text>
              ))
            ) : (
              <UI.Text size="sm" weight="sm" color="tundora">
                {props.value}
              </UI.Text>
            )}
          </DetailsValueContainer>
        </Row>
        ),
      )}
    </DetailsC>
  </DetailContainer>
)

const RequestDetails: FC<{
  dataPymeSummary: TDataPymeSummary
}> = ({ dataPymeSummary }) => (
  <RequestDetailsContainer>
    <NumberOfFamilyGroups>
      <Details
        title="Cantidad de grupos familiares"
        details={dataPymeSummary.numberOfFamilyGroups}
      />
    </NumberOfFamilyGroups>
    <AmountPayable>
      <Details title="Monto a pagar" details={dataPymeSummary.amountPayable} />
    </AmountPayable>
    <PersonalInformation>
      <Details
        title="Datos de facturación"
        details={dataPymeSummary.personalInformation}
      />
    </PersonalInformation>
    <PaymentMethod>
      <Details title="Método de pago" details={dataPymeSummary.paymentMethod} />
    </PaymentMethod>
  </RequestDetailsContainer>
)

export default RequestDetails
