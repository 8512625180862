import { useState, useEffect } from 'react'
import { Steps } from '../../../commons'
import GetPymeSummary from '../../application/useCase/getPymeSummary'
import { PaymentLabel } from '../../application/useCase/savePaymentMethod/PaymentType'
import UpdateSteps from '../../application/useCase/updateSteps'
import { IPymeRepository } from '../../domain'
import GetLastQuote from '../../application/useCase/getLastQuote'
import { numbers } from '../../../../app/infraestructure/shared/helpers'

const mapPymeSummaryData = (response: any) => {
  const { Pyme, MedioPago } = response

  return {
    numberOfFamilyGroups: [
      {
        label: 'Relación de dependencia',
        value: Pyme.Asignaciones[0].Empleados,
      },
      { label: 'Monotributistas', value: Pyme.Asignaciones[1].Empleados },
      { label: 'Particulares', value: Pyme.Asignaciones[2].Empleados },
    ],
    amountPayable: [
      {
        label: 'Valor de cotización',
        value: `$${numbers.numberWithSeparators({
          number: Pyme.ValorCotizacion,
          withThousandSeparator: true,
        })}`,
      },
      {
        label: 'Planes',
        value: Pyme.Planes.map(
          ({ Cantidad, Nombre }: any) => `${Cantidad} ${Nombre}`,
        ),
      },
    ],
    personalInformation: [
      { label: 'Razón Social', value: Pyme.RazonSocial },
      { label: 'CUIT', value: Pyme.CUIT },
      { label: 'Mail', value: Pyme.Mail },
      { label: 'Teléfono', value: Pyme.Telefono },
      { label: 'Provincia', value: Pyme.Provincia },
      { label: 'Localidad', value: Pyme.Localidad },
    ],
    paymentMethod: [
      { label: 'Tipo', value: PaymentLabel[MedioPago.TipoPago] },
      MedioPago.TipoPago === 1 && { label: 'CBU', value: MedioPago.InfoExtra },
    ],
  }
}

const detailController = ({
  pymeRepository,
}: {
  pymeRepository: IPymeRepository
}) => {
  const [dataPymeSummary, setDataPymeSummary] = useState<any | null>(null)
  const [pymeSummaryIsLoading, setPymeSummaryIsLoading] = useState(false)
  const [errorPymeSummary, setErrorPymeSummary] = useState('')

  const getPymeSummaryUseCase = new GetPymeSummary(pymeRepository)
  const updateStepUseCase = new UpdateSteps(pymeRepository)
  const getLastQuote = new GetLastQuote(pymeRepository)

  useEffect(() => {
    async function getData() {
      setPymeSummaryIsLoading(true)

      try {
        const response = await getPymeSummaryUseCase.run()
        await getLastQuote.getLastQuote(false)
        const mapData = mapPymeSummaryData(response.data)

        return setDataPymeSummary(mapData)
      } catch (err) {
        setDataPymeSummary(null)
        return setErrorPymeSummary('Error en conseguir data pyme.')
      } finally {
        setPymeSummaryIsLoading(false)
      }
    }

    getData()
  }, [])

  const finishRequest = () => {
    updateStepUseCase.run(Steps.Completo)
  }

  return {
    dataPymeSummary,
    pymeSummaryIsLoading,
    errorPymeSummary,
    finishRequest,
  }
}

export default detailController
