import styled from 'styled-components'
import { TColorsM } from '../../shared/styles/colors'

export const CardsContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 3rem 0;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const InfoContainer = styled.div`
  display: flex;
  gap: 6rem;
`

export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const Spacing = styled.div`
  margin-bottom: 1rem;
`

export const SpacingTitle = styled.div`
  margin-bottom: 0.5rem;
  > span {
    display: inline-block;
    margin-right: 15px;
  }
`

export const ChipStatus = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.paleGrey};
  padding: 2px 10px;
  border-radius: 10px;
`

export type TextProps = {
  color?: keyof TColorsM
}

export const ModalContainer = styled.div`
  width: 709px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const RowSplittedEqually = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  > * {
    flex: 1;
  }
`

export const PaymentTypeAlign = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RowSplitted = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`

export const Content = styled.div`
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export default {
  CardsContainer,
  Column,
  Row,
  InfoContainer,
  Spacing,
  SpacingTitle,
  ChipStatus,
  ModalContainer,
  ModalContent,
  RowSplittedEqually,
  Content,
  RowSplitted,
  PaymentTypeAlign,
  ButtonContainer,
}
