import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../../UI/Icon'

const MAX_TO_SHOW = 3

type TCompletedHolder = {
  fullName: string
  answers: string[]
}

type TCompletedHolders = {
  questions: string[]
  holders: TCompletedHolder[]
}

const DjsTableContainer = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.greyishBrownTwo}`};
  border-radius: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
`

const Head = styled.div`
  display: flex;
  width: 100%;
`

const Holders = styled.div<{
  columns: number
}>`
  position: relative;
  width: 30%;
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
`

const ArrowLogo = styled.div<{
  left?: boolean
}>`
  width: 0.813rem;
  cursor: pointer;
  position: absolute;
  top: 14px;
  ${({ left }) => (left ? 'left: -1.375rem' : 'right: -0.375rem')}
`

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
`

const Fullname = styled.p`
  color: ${({ theme }) => theme.colors.chathamsBlue};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`

const Title = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.mdS};
  font-size: 1.125rem;
  line-height: 1.313rem;
  color: ${({ theme }) => theme.colors.emperor};
  width: 70%;
`

const QAContainer = styled.div`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const Question = styled.div<{
  isLastRow: boolean
}>`
  width: 60%;

  font-weight: ${({ theme }) => theme.fontWeights.sm};
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.chathamsBlue};
  padding: 14.08px;
  border-bottom: 1px solid
    ${({ theme, isLastRow }) => (isLastRow ? 'transparent' : theme.colors.silverChalice)};
`

const Answers = styled.div<{
  columns: number
  isLastRow: boolean
}>`
  border-bottom: 1px solid
    ${({ theme, isLastRow }) => (isLastRow ? 'transparent' : theme.colors.silverChalice)};
  width: 30%;
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  grid-template-rows: 1fr;
  place-items: center;
`

const Answer = styled.div``

const DjsTable = ({
  completedHolders,
}: {
  completedHolders: TCompletedHolders
}) => {
  const { questions, holders } = completedHolders

  const [indexCounter, setIndexCounter] = React.useState(0)
  const filterHolders: TCompletedHolder[] = holders.slice(
    indexCounter,
    indexCounter + MAX_TO_SHOW,
  )

  const nextHolders = () => indexCounter < holders.length - MAX_TO_SHOW
    && setIndexCounter(indexCounter + 1)
  const prevHolders = () => indexCounter > 0 && setIndexCounter(indexCounter - 1)

  return (
    <DjsTableContainer>
      <Head>
        <Title>Declaraciones juradas de salud</Title>
        <Holders columns={filterHolders.length}>
          {indexCounter > 0 && (
            <ArrowLogo left onClick={prevHolders}>
              <Icons.ArrowIosBackIcon size="lg" color="tundora" />
            </ArrowLogo>
          )}
          {filterHolders.map((holder: TCompletedHolder) => (
            <Holder key={`${holder.fullName}`}>
              <Icons.PersonCircleIcon size="mdlg" />
              <Fullname>{holder.fullName}</Fullname>
            </Holder>
          ))}
          {indexCounter < holders.length - MAX_TO_SHOW && (
            <ArrowLogo onClick={nextHolders}>
              <Icons.ArrowIosForwardIcon size="lg" color="tundora" />
            </ArrowLogo>
          )}
        </Holders>
      </Head>
      <QAContainer>
        {questions.map((question: string, q_index: number) => (
          <Row key={`${question}`}>
            <Question isLastRow={questions.length - 1 === q_index}>
              {question}
            </Question>
            <Answers
              isLastRow={questions.length - 1 === q_index}
              columns={filterHolders.length}
            >
              {filterHolders.map((holder: TCompletedHolder) => (
                <Answer key={`${holder.fullName}`}>
                  {holder.answers[q_index]}
                </Answer>
              ))}
            </Answers>
          </Row>
        ))}
      </QAContainer>
    </DjsTableContainer>
  )
}

export default DjsTable
