import { IPymeRepository } from '../../domain'

export default class SaveCompanyLogo {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async saveCompanyLogo(image: FormData): Promise<any> {
    return this.repository.saveCompanyLogo(image)
  }
}
