// userName input
export const userNameField = 'userName'
export const userLabelTextField = 'Usuario'

// password input
export const passwordField = 'password'
export const passwordLabelTextField = 'Clave'

export interface IInitialValues {
  userName: string
  password: string
}
export const initialValues: IInitialValues = {
  userName: '',
  password: '',
}
