export const DecodeCoveragePlans = (coveragePlans: string): number => {
  const planId = parseInt(
    coveragePlans.substring(0, coveragePlans.indexOf('-')),
    10,
  )
  if (coveragePlans.includes('_')) return planId
  return -1
}

export const EncodeCoveragePlans = (
  idPlan: number,
  codigoPlan: string,
): string => `${idPlan}-${codigoPlan}`

export default {
  DecodeCoveragePlans,
  EncodeCoveragePlans,
}
