import { IPymeRepository } from '../../../domain'
import { SummaryResponseServer } from './Summary'

export default class GetPymeSummary {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async run(): Promise<SummaryResponseServer> {
    return this.repository.getPymeSummary()
  }
}
