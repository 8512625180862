import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import stores from '../../stores'
import UI from '../../UI'
import { HeaderBlue } from '../../UI/Header'

const PageContainer = styled.div``
const PageContent = styled.div`
  width: 90%;
  height: calc(100vh - 15rem);
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Container = styled.div`
  width: 40rem;
`

const Content = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`

const RedirectAfterPasswordUpdate = () => {
  const context = useContext(stores.LoginContext)
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      context.handleOnPressLogout()
      history.push('/')
    }, 3000)
  }, [])

  return (
    <PageContainer>
      <HeaderBlue>Empresas</HeaderBlue>
      <PageContent>
        <Container>
          <UI.Cards.Card>
            <Content>
              <UI.Spinner width="4rem;" heigth="4rem;" />
              <UI.Text
                weight="mdS"
                size="lg"
                color="mariner"
                textAlign="center"
              >
                Te estamos redirigiendo para que puedas ingresar con tu nueva
                contraseña
              </UI.Text>
            </Content>
          </UI.Cards.Card>
        </Container>
      </PageContent>
    </PageContainer>
  )
}

export default RedirectAfterPasswordUpdate
