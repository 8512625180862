import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import { useHistory } from 'react-router-dom'
import AuthRepo from '../../../../modules/Login/infraestructure/repositories'
import { loginController } from '../Login/controller'
import UI from '../../UI'
import Notification from './Notification'
import { CheckCircleIcon, ErrorCircleIcon } from '../../UI/Icon'
import { UpdatePasswordPageContainer, Container } from './styles'

const initialValues = {
  Password: '',
  ConfirmarPassword: '',
}

const validationForm = yup.object().shape({
  Password: yup
    .string()
    .min(6, 'La password debe tener mas de 6 caracteres')
    .required('El campo es requerido'),
  ConfirmarPassword: yup
    .string()
    .min(6, 'La password debe tener mas de 6 caracteres')
    .oneOf([yup.ref('Password'), null], 'Ambas claves deben ser iguales')
    .required('El campo es requerido'),
})

const UpdatePasswordPage = ({
  loginRepository,
}: {
  loginRepository: AuthRepo
}) => {
  const {
    errorMsg,
    successMsg,
    hasError,
    hasSuccess,
    isLoading,
    handleOnUpdatePassword,
  } = loginController({ loginRepository })

  const history = useHistory()

  const handleOnPressLogout = () => history.push('/claveRenovada')

  return (
    <UpdatePasswordPageContainer>
      <Container>
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnUpdatePassword}
          validationSchema={validationForm}
        >
          {({
            values,
            handleSubmit,
            errors,
            handleChange,
            isValid,
            isSubmitting,
            touched,
            handleBlur,
          }) => (hasSuccess || hasError ? (
            <Notification
              icon={
                  hasSuccess ? (
                    <CheckCircleIcon color="eucalyptus" size="lg" />
                  ) : (
                    <ErrorCircleIcon color="carmine" size="lg" />
                  )
                }
              message={successMsg || errorMsg}
              buttonLabel={hasSuccess ? 'Continuar' : 'Volver a intentar'}
              onClickHandler={() => handleOnPressLogout()}
            />
          ) : (
            <form onSubmit={handleSubmit}>
              <UI.Text color="mariner" weight="mdS" size="lg">
                Modificá tu contraseña
              </UI.Text>
              <UI.TextField
                value={values.Password}
                label="Elegí tu nueva contraseña"
                name="Password"
                password
                validationMsg={
                    errors.Password && touched.Password ? errors.Password : ''
                  }
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Ingresar"
              />
              <UI.TextField
                value={values.ConfirmarPassword}
                label="Confirmá tu nueva contraseña"
                name="ConfirmarPassword"
                password
                validationMsg={
                    errors.ConfirmarPassword && touched.ConfirmarPassword
                      ? errors.ConfirmarPassword
                      : ''
                  }
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Ingresar"
              />
              <UI.Button
                type="submit"
                disabled={!isValid || isSubmitting}
                isLoading={isLoading}
              >
                Continuar
              </UI.Button>
            </form>
          ))}
        </Formik>
      </Container>
    </UpdatePasswordPageContainer>
  )
}

export default UpdatePasswordPage
