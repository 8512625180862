import * as yup from 'yup'

const validationSchema = yup.object().shape({
  altura: yup.string().test({
    name: 'alturaintegrantefamiliar',
    exclusive: false,
    params: {},
    message: 'La altura no es válida',
    test(value: any) {
      const cleanNumberAsString = value && value.replace('mts', '').replaceAll('_', '0').replace(',', '')
      const numericValue = value && parseFloat(cleanNumberAsString)
      const testResult = numericValue > 0.1

      return testResult
    },
  }),
  peso: yup.string().test({
    name: 'alturaintegrantefamiliar',
    exclusive: false,
    params: {},
    message: 'El peso no es válido',
    test(value: any) {
      const cleanNumberAsString = value && value.replace('k', '').replaceAll('_', '').replace(',', '')
      const numericValue = value && parseFloat(cleanNumberAsString)
      const testResult = numericValue

      return testResult
    },
  }),
})

export default validationSchema
