import { useContext, useState } from 'react'

import LoginRepository from '../../../../modules/Login/infraestructure/repositories'
import { AutenthicateUser } from '../../../../modules/Login/application'
import { LoginParams,
  RecoverPasswordParams,
  UpdatePasswordParams } from '../../../../modules/Login/domain'
import stores from '../../stores'

export type TLoginController = {
  isLoading: boolean
  hasError: boolean
  setHasError: (error: boolean) => void
  errorMsg: string
  hasSuccess: boolean
  successMsg: string
  handleOnPressSubmit: (formValues: LoginParams) => void
  handleOnUpdatePassword: (formValues: UpdatePasswordParams) => void
  handleOnRecoverPassword: (formValues: RecoverPasswordParams) => void
}

export const loginController = ({
  loginRepository,
}: {
  loginRepository: LoginRepository
}): TLoginController => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [hasSuccess, setHasSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const loginStore = useContext(stores.LoginContext)

  const loginUseCase = new AutenthicateUser(loginRepository)

  /*
    user: juan.jerez@valtech.com
    pass: 19817202
    PrimerIngreso: true
    <------------------>
    user: pym@es.com
    pass: empresa
    PrimerIngreso: false
    <------------------>
    user: asesor@omint.com
    pass: 13485863
    PrimerIngreso: false
  */
  const handleOnPressSubmit = async (formValues: LoginParams) => {
    try {
      setHasError(false)
      setIsLoading(true)

      const response = await loginUseCase.authenticate({
        userName: formValues.userName,
        password: formValues.password,
      })

      if (response.status === 401) {
        setErrorMsg('Los datos ingresados no existen')

        return setHasError(true)
      }

      if (response.status === 500) {
        setErrorMsg('Se ha producido un error inesperado')

        return setHasError(true)
      }

      return loginStore.handleOnSuccessLogin(response.data)
    } catch (error) {
      setErrorMsg('Error inesperado')

      return setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnRecoverPassword = async (formValues: RecoverPasswordParams) => {
    try {
      setHasError(false)
      setIsLoading(true)

      const response = await loginUseCase.recoverPassword({
        userName: formValues.userName,
      })

      if (response.status === 404) {
        setErrorMsg('El usuario no se encuentra registrado')

        return setHasError(true)
      }

      if (response.status === 200) {
        setErrorMsg('Se ha enviado un mail para recupero de contraseña')

        return setHasError(true)
      }

      if (response.status === 500) {
        setErrorMsg('Se ha producido un error inesperado')

        return setHasError(true)
      }

      return response.data
    } catch (error) {
      setErrorMsg('Error inesperado')

      return setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnUpdatePassword = async (formValues: UpdatePasswordParams) => {
    try {
      setHasError(false)
      setIsLoading(true)

      const response = await loginUseCase.updatePassword({
        Password: formValues.Password,
        ConfirmarPassword: formValues.ConfirmarPassword,
      })

      if (response.status === 404 || response.status === 500) {
        setErrorMsg('La contraseña no pudo ser modificada')

        return setHasError(true)
      }

      if (response.status === 200) {
        setHasSuccess(true)
        setSuccessMsg('Has modificado tu contraseña exitosamente')

        return setHasError(true)
      }

      return response.data
    } catch (error) {
      setErrorMsg('Error inesperado')

      return setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    hasError,
    setHasError,
    errorMsg,
    hasSuccess,
    successMsg,
    handleOnPressSubmit,
    handleOnUpdatePassword,
    handleOnRecoverPassword,
  }
}
