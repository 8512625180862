import { GetAllProvinciesServerResponse } from './GetAllProvinciesServerResponse'
import ProvinceRepository from '../../infraestructure/repositories/provinceRepository'

export default class GetAllProvinciesUseCase {
  private repository: ProvinceRepository

  constructor(repository: ProvinceRepository) {
    this.repository = repository
  }

  async getAllProvincies(): Promise<GetAllProvinciesServerResponse> {
    return this.repository.getAllProvincies()
  }
}
