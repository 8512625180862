import React from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'

import UI from '../../../UI'
import { IPymeRepository } from '../../../../../modules/Pyme/domain'
import * as S from '../styles'
import validateAdvisorsCodeController from '../../../../../modules/Pyme/infrastructure/controllers/validateAdvisorSecurityCodeController'
import { AdvisorSecurityCodeParam } from '../../../../../modules/Pyme/application/useCase/validateAdvisorsSecurityCode/AdvisorSecurityCodeParam'

const validationSchema = yup.object().shape({
  Codigo: yup.string().required('El campo es requerido'),
})

const initialValues: AdvisorSecurityCodeParam = {
  Codigo: '',
}

// Screen where user need to validate advisors security code
const Step1 = ({
  handleSetActiveIndex,
  pymeRepository,
}: {
  handleSetActiveIndex: any
  pymeRepository: IPymeRepository
}) => {
  const { errorMsg, isLoading, handleOnSubmitForm } = validateAdvisorsCodeController({
    handleSetActiveIndex,
    pymeRepository,
  })

  return (
    <>
      <UI.Text color="mariner" weight="lg" size="xl">
        Código de seguridad
      </UI.Text>
      <UI.Text color="greyishBrownTwo" size="sm">
        Cargá el código que te brindó tu asesor.
      </UI.Text>
      <S.FormContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmitForm}
        >
          {({
            values,
            handleSubmit,
            errors,
            handleChange,
            isValid,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <UI.TextField
                value={values.Codigo}
                onChange={handleChange}
                name="Codigo"
                label="Código"
                placeholder="Ingresá el código de seguridad"
                validationMsg={errors.Codigo}
              />
              <UI.Text color="scarlet">{errorMsg}</UI.Text>
              <UI.Button
                type="submit"
                disabled={!isValid || isSubmitting}
                isLoading={isLoading}
              >
                Continuar
              </UI.Button>
            </form>
          )}
        </Formik>
      </S.FormContainer>
    </>
  )
}

export default Step1
