import React, { FC } from 'react'
import styled from 'styled-components'
import UI from '../../UI'

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

type TProps = {
  icon: any
  message: string
  buttonLabel: string
  onClickHandler: () => void
}

const Notification: FC<TProps> = ({
  icon,
  message,
  buttonLabel,
  onClickHandler,
}) => (
  <NotificationContainer>
    {icon}
    <UI.Text weight="sm" size="mdLg" color="chathamsBlue">
      {message}
    </UI.Text>
    <UI.Button onClick={onClickHandler}>{buttonLabel}</UI.Button>
  </NotificationContainer>
)

export default Notification
