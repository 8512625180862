const useIsMobile = () => {
  // The current width of the viewport
  const width = window.innerWidth

  // The width below which the mobile view should be rendered
  const breakpoint = 800

  return width <= breakpoint
}

export default useIsMobile
