import Answer from '../../domain/entities/Answer'
import IDjsRepository from '../../domain/IDjsRepository'

export default class GetMemberDjsByDni {
  private repository: IDjsRepository

  constructor(repository: IDjsRepository) {
    this.repository = repository
  }

  run(dni: number): Answer | undefined {
    return this.repository.getMemberDjsByDni(dni)
  }
}
