import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: flex;
  gap: 1rem;
`

export const InputsContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`

export const ButtonsFooterContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 2rem 0;
`
export const ListOfHoldersContainer = styled.div`
  grid-area: list;
`

export const FamilyGroupContainer = styled.div`
  display: grid;
  grid-template-areas:
    'header header'
    'tabs tabs'
    'list form';
  grid-template-columns: 205px 1fr;
  width: 100%;
`

export const HeaderContainer = styled.div`
  grid-area: header;
  padding: 1rem 0;
`

export const TabsContainer = styled.div`
  grid-area: tabs;
`

export const FormContainer = styled.div`
  grid-area: form;
  padding: 1rem 0 0 1rem;
`

export const DeclaracionContainer = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`

export const TotalCompleteGroups = styled.div`
  margin-top: 1.5rem;
  color: black;
  font-weight: 600;
  width: 15rem;
  text-align: center;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 16px;
  background-color: #f3f3f3;
`
export const ChildrenButton = styled.div`
  color: black;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
`

export const InlineInputs = styled.div`
  display: flex;
  gap: 1rem;
  justify-items: center;
  align-items: center;
  margin-top: 0.5rem;
`
export const ConfirmationMessage = styled.div`
  display: flex;
  gap: 0.54rem;
  height: 2rem;
  width: 20rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 14px;
  background-color: #e7efde;
`

export const OnHoldMessage = styled(ConfirmationMessage)`
  width: 27rem;
  margin: 1rem 0 -1rem 0;
`

export const DjButtonsContainer = styled.div`
  display: flex;
  gap: 2rem;
`

export const MessageContainer = styled.div`
  position: fixed;
  bottom: 5rem;
  left: 12rem;
`

export const TotalGroupContainer = styled.div`
  display: flex;
  justify-content: end;
`
export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const StickyButtonIcon = styled.div`
  margin-top: -1.5px;
  padding-right: 3px;
`

export const GroupsContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
`
export const ElementSpacing = styled.div`
  margin-top: 0.5rem;
`

export const PlansContainer = styled.div`
  position: absolute;
  right: 20rem;
`
export const FormLoadingWrapper = styled.div<{ isLoading: boolean }>`
  ${(props) => props.isLoading
    && `
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`

export const PhoneInputs = styled.div`
  display: flex;
  width: 12rem;
  gap: 1rem;
`

export const AreaCodeInput = styled.div`
  display: flex;
  width: 3.5rem;
`
