import React from 'react'
import styled from 'styled-components'

import { TColorsM } from '../shared/styles/colors'
import { TFontWeight, TfontSizesKeys } from '../shared/styles/theme'

import { fontSizes, colorsProvider, fontWeightsProvider } from './helpers'

const TextBase = styled.span.attrs((p: any) => ({
  name: p.name,
}))<TextProps>`
  display: flex;
  text-align: ${(props) => props.textAlign || ''};
  line-height: 1.5;
  ${(props) => props.capitalFirstLetter && 'text-transform: capitalize;'}
  ${(props) => props.underlined && 'text-decoration: underline;'}
  ${(props) => props.isCursor && 'cursor: pointer;'}
  ${(props) => props.verticalAlign && `align-items: ${props.verticalAlign};`}
  ${(props) => props.horizontalAlign && `justify-content: ${props.horizontalAlign};`}
`

const MainText = styled(TextBase)`
  ${({ size }) => fontSizes[size]}
  ${({ color }) => colorsProvider[color]}
  ${({ weight }) => fontWeightsProvider[weight]}
`

export type TextProps = {
  textAlign?: string
  children: string | number
  onClick?: () => void
  color?: keyof TColorsM
  size?: keyof TfontSizesKeys
  weight?: keyof TFontWeight
  capitalFirstLetter?: boolean
  underlined?: boolean
  isCursor?: boolean
  verticalAlign?:
  | 'baseline'
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'stretch'
  horizontalAlign?:
  | 'center'
  | 'end'
  | 'start'
  | 'flex-end'
  | 'flex-start'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
} & typeof defaultProps

const defaultProps = {
  onClick: () => {},
  color: 'emperor',
  size: 'md',
  weight: 'sm',
  underlined: false,
  isCursor: false,
  textAlign: '',
  capitalFirstLetter: false,
  verticalAlign: 'start',
  horizontalAlign: 'start',
}

export const TextBasic = ({
  children,
  size,
  color,
  weight,
  textAlign,
  capitalFirstLetter,
  underlined,
  isCursor,
  onClick,
  verticalAlign,
  horizontalAlign,
}: TextProps) => (
  <MainText
    name={children || undefined}
    size={size}
    color={color}
    weight={weight}
    textAlign={textAlign}
    capitalFirstLetter={capitalFirstLetter}
    underlined={underlined}
    isCursor={isCursor}
    onClick={onClick}
    verticalAlign={verticalAlign}
    horizontalAlign={horizontalAlign}
  >
    {children}
  </MainText>
)

TextBasic.defaultProps = defaultProps

export default TextBasic
