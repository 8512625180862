import { MemberTypesValues } from '../../../commons/member'
import IQuestion from '../../domain/entity/Question'
import IQuestionRepository from '../../domain/QuestionRepository'

export default class GetQuestionsByMemberTypeId {
  private repository: IQuestionRepository

  constructor(repository: IQuestionRepository) {
    this.repository = repository
  }

  async run(memberTypeId: MemberTypesValues): Promise<IQuestion[]> {
    return this.repository.getQuestionsByMemberTypeId(memberTypeId)
  }
}
