import { IPymeRepository } from '../../../domain'
import MedioDePago from './PaymentMethodData'

export default class GetPaymentMethodData {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async run(IdPyme: number): Promise<{
    error: boolean
    msg: string
    data: MedioDePago | null
  }> {
    try {
      const response = await this.repository.getPaymentMethodData(IdPyme)

      return {
        error: false,
        msg: '',
        data: response.data,
      }
    } catch (error) {
      return {
        error: true,
        msg: 'Se produjo un error',
        data: null,
      }
    }
  }
}
