import { MemberTypesValues } from '../../../commons/member'

export default class Answer {
  readonly dni: number

  readonly altura: number

  readonly peso: number

  readonly preguntas: any

  readonly memberTypeId: MemberTypesValues

  readonly nombre: string

  readonly apellido: string

  constructor(
    dni: number,
    altura: number,
    peso: number,
    preguntas: any,
    memberTypeId: MemberTypesValues,
    nombre: string,
    apellido: string,
  ) {
    this.dni = dni
    this.altura = altura
    this.peso = peso
    this.preguntas = preguntas
    this.memberTypeId = memberTypeId
    this.nombre = nombre
    this.apellido = apellido
  }

  static create(props: {
    dni: number
    altura: number
    peso: number
    preguntas: any
    memberTypeId: MemberTypesValues
    nombre: string
    apellido: string
  }): Answer {
    const answer = new Answer(
      props.dni,
      props.altura,
      props.peso,
      props.preguntas,
      props.memberTypeId,
      props.nombre,
      props.apellido,
    )

    return answer
  }
}
