import { UpdatePasswordParam } from './UpdatePasswordParam'
import { IPymeRepository } from '../../../domain'

export default class UpdatePassword {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  // without typed response until backend retrieve data
  async updatePassword(param: UpdatePasswordParam): Promise<any> {
    return this.repository.updatePassword(param)
  }
}
