import { useContext } from 'react'
import DjsRepository from '../../../modules/Djs/infrastructure/repository'
import FamilyGroupRepository from '../../../modules/FamilyGroup/infrastructure/repository'
import AuthRepo from '../../../modules/Login/infraestructure/repositories'
import ProvinceRepository from '../../../modules/Province/infraestructure/repositories/provinceRepository'
import PymeRepository from '../../../modules/Pyme/infrastructure/repositories/pymeRepository'
import QuestionRepository from '../../../modules/Questions/infrastructure/repository/questionsRepository'
import services from '../services'
import stores from '../stores'

// TODO: create the others repositories instances and provide to all the pages who needed
const RepositoriesProvider = () => {
  const loginStore = useContext(stores.LoginContext)

  const portalHttpClient = services.http({
    token: loginStore.token,
    logoutUser: () => loginStore.handleOnPressLogout(),
  }).portalPymeApi
  const ventaHttpClient = services.http({
    token: loginStore.token,
    logoutUser: () => loginStore.handleOnPressLogout(),
  }).ventaDigitalApi
  const publicHttpClient = services.http({
    token: '',
    logoutUser: () => {},
  }).publicApi

  // repos
  const pymeRepository = new PymeRepository(portalHttpClient)
  const provinceRepository = new ProvinceRepository(ventaHttpClient)
  const familyGroupRepository = new FamilyGroupRepository(portalHttpClient)
  const loginRepository = new AuthRepo(portalHttpClient)
  const questionRepository = new QuestionRepository(publicHttpClient)
  const djsRepository = new DjsRepository(portalHttpClient, questionRepository)

  const repositories = {
    pymeRepository,
    provinceRepository,
    familyGroupRepository,
    loginRepository,
    djsRepository,
    questionRepository,
  }

  return repositories
}

export default RepositoriesProvider
