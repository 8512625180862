import React, { useState, useEffect } from 'react'
import GetIfAllDjsAreComplete from '../../../../../../modules/FamilyGroup/application/useCase/getIfAllDjsAreComplete'
import { IFamilyGroupRepository } from '../../../../../../modules/FamilyGroup/domain/entities'
import UI from '../../../../UI'
import * as S from './styles'

const PaymentScreenGuard = ({
  children,
  familyGroupRepository,
}: {
  children: JSX.Element
  familyGroupRepository: IFamilyGroupRepository
}) => {
  const [isFetching, setIsFetching] = useState(true)
  const [canContinue, setCanContinue] = useState(false)

  const getIfAllDjsAreCompleteUseCase = new GetIfAllDjsAreComplete(
    familyGroupRepository,
  )

  useEffect(() => {
    const getHoldersList = async () => {
      try {
        setIsFetching(true)
        const response = await getIfAllDjsAreCompleteUseCase.run()

        setCanContinue(response)
      } catch (error) {
        setCanContinue(false)
      } finally {
        setIsFetching(false)
      }
    }

    getHoldersList()
  }, [])

  if (isFetching) {
    return (
      <S.SpinnerContainer>
        <UI.Text>Validando...</UI.Text>
        <UI.Spinner />
      </S.SpinnerContainer>
    )
  }

  return canContinue ? (
    children
  ) : (
    <UI.Text>No puede acceder al pago, quedan DJs sin completar</UI.Text>
  )
}

export default PaymentScreenGuard
