import { THoldersListByMemberTypeServerResponse } from '../../domain/DTOs'
import { IFamilyGroupRepository } from '../../domain/entities'

export default class GetHoldersList {
  readonly repository: IFamilyGroupRepository

  constructor(repository: any) {
    this.repository = repository
  }

  async run(): Promise<THoldersListByMemberTypeServerResponse> {
    return this.repository.getHoldersList()
  }
}
