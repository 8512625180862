import { useEffect, useState } from 'react'
import GetPymeRequote from '../../../../modules/Pyme/application/useCase/getRequoteData'
import { IPymeRepository } from '../../../../modules/Pyme/domain'
import constants from '../../shared/constants'
import GetDataPyme from '../../../../modules/Pyme/application/useCase/getDataPyme'

const RequoteCardController = ({
  pymeRepository,
}: {
  pymeRepository: IPymeRepository
}) => {
  const [pymeId, setPymeId] = useState<number | null>(null)
  const [pymeRequoteData, setPymeRequoteData] = useState(null)

  const [pymeIdErrorMsg, setPymeIdErrorMsg] = useState('')
  const [hasPymeRequoteError, setHasPymeRequoteError] = useState(false)
  const [pymeRequoteIsFetching, setPymeRequoteIsFetching] = useState(false)

  const getPymeRequoteUseCase = new GetPymeRequote(pymeRepository)
  const getPymeDataUseCase = new GetDataPyme(pymeRepository)

  const handleRequoteButton = async () => {
    try {
      if (pymeId) {
        setHasPymeRequoteError(false)
        setPymeRequoteIsFetching(true)

        const response = await getPymeRequoteUseCase.run(pymeId)

        if (response?.status === constants.httpStatusCodes.HTTP_BAD_REQUEST) {
          setHasPymeRequoteError(true)
          setPymeRequoteIsFetching(false)
          setPymeRequoteData(null)
          return
        }

        if (
          response?.status
          === constants.httpStatusCodes.HTTP_UNEXPECTED_ERROR_STATUS
        ) {
          setHasPymeRequoteError(true)
          setPymeRequoteIsFetching(false)
          setPymeRequoteData(null)
          return
        }

        setPymeRequoteData(response.data)
      }
    } catch (error) {
      setHasPymeRequoteError(true)
      setPymeRequoteIsFetching(false)
      setPymeRequoteData(null)
      return
    } finally {
      setPymeRequoteIsFetching(false)
    }
  }

  useEffect(() => {
    const getPymeData = async () => {
      try {
        setPymeIdErrorMsg('')
        const response = await getPymeDataUseCase.getDataPyme()

        return setPymeId(response.data.Id)
      } catch (error) {
        return setPymeIdErrorMsg('Error en conseguir el ID de la Pyme')
      }
    }

    getPymeData()
  }, [])

  return {
    handleRequoteButton,
    pymeRequoteData,
    pymeRequoteIsFetching,
    hasPymeRequoteError,
    pymeIdErrorMsg,
  }
}

export default RequoteCardController
