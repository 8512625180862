import React, { useState, createContext, useRef, useMemo } from 'react'

export type TPymeRequote = {
  children: React.ReactNode
}

export type TPymeRequoteContext = {
  total: number
  date: {
    dmy: string
    time: string
  }
  requotePriceContext: (total: any, date: { dmy: string; time: string }) => void
}

export const PymeRequoteContext = createContext({} as TPymeRequoteContext)

export const PymeRequote = ({ children }: TPymeRequote) => {
  const [totalRequote, setTotal] = useState(0)
  const [dateRequote, setDate] = useState({
    dmy: '',
    time: '',
  })

  const requotePriceContext = useRef(
    (total: number, date: { dmy: string; time: string }) => {
      setTotal(total)
      setDate(date)
    },
  )
  const totalProviderValue = useMemo<{
    total: number
    requotePriceContext:(
    total: number,
    date: { dmy: string; time: string },
    ) => void
    date: {
      dmy: string
      time: string
    }
  }>(
      () => ({
        total: totalRequote,
        requotePriceContext: (total, date) => requotePriceContext.current(total, date),
        date: dateRequote,
      }),
      [totalRequote, requotePriceContext, dateRequote],
      )

  return (
    <PymeRequoteContext.Provider value={totalProviderValue}>
      {children}
    </PymeRequoteContext.Provider>
  )
}

export default { PymeRequote }
