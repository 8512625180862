import React, { useEffect, useReducer, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { IHandleSetActiveIndex } from '../../../../hooks/useStepper'
import { IFamilyGroupRepository } from '../../../../../../modules/FamilyGroup/domain/entities'
import PaymentScreenGuard from './ScreenGuard'
import { PaymentActions,
  paymentFormInitValues,
  paymentFormValidationSchema } from './Shared'
import paymentReducer from './State'
import UI from '../../../../UI'
import * as S from './styles'
import SavePaymentMethod from '../../../../../../modules/Pyme/application/useCase/savePaymentMethod'
import { IPymeRepository,
  TDataPyme } from '../../../../../../modules/Pyme/domain'
import GetDataPyme from '../../../../../../modules/Pyme/application/useCase/getDataPyme'
import constants from '../../../../shared/constants'
import UpdateSteps from '../../../../../../modules/Pyme/application/useCase/updateSteps'
import GetPaymentMethodData from '../../../../../../modules/Pyme/application/useCase/getPaymentMethodData'
import MedioDePago from '../../../../../../modules/Pyme/application/useCase/getPaymentMethodData/PaymentMethodData'
import { PaymentFormValues } from '../../../../../../modules/Pyme/application/useCase/savePaymentMethod/PaymentFormValues'
import { PaymentType } from '../../../../../../modules/Pyme/application/useCase/savePaymentMethod/PaymentType'
import { Steps } from '../../../../../../modules/commons'

const PaymentScreen = ({
  handleSetActiveIndex,
  familyGroupRepository,
  pymeRepository,
}: {
  handleSetActiveIndex: () => IHandleSetActiveIndex
  familyGroupRepository: IFamilyGroupRepository
  pymeRepository: IPymeRepository
}) => {
  const [state, dispatch] = useReducer(paymentReducer, {
    isContinueBtnDisabled: true,
    isFormFetching: false,
    error: '',
  })
  const [pymeData, setPymeData] = useState<TDataPyme | null>(null)
  const [paymentMethodData, setPaymentMethodData] = useState<MedioDePago | null>(null)
  const getPymeDataUseCase = new GetDataPyme(pymeRepository)
  const savePaymentMethodUseCase = new SavePaymentMethod(pymeRepository)
  const updateStepUseCase = new UpdateSteps(pymeRepository)
  const getPaymentMethodDataUseCase = new GetPaymentMethodData(pymeRepository)

  const handleOnPressSubmit = (formValues: PaymentFormValues) => {
    const savePaymentMethod = async () => {
      try {
        dispatch({ type: PaymentActions.SET_FORM_IS_FETCHING, payload: true })
        dispatch({
          type: PaymentActions.SET_ERROR,
          payload: '',
        })
        dispatch({
          type: PaymentActions.SET_CONTINUE_BTN_STATE,
          payload: true,
        })

        if (pymeData) {
          const response = await savePaymentMethodUseCase.run({
            IdPyme: pymeData.Id,
            formValues,
          })

          if (
            response.status === constants.httpStatusCodes.HTTP_OK_NO_CONTENT
          ) {
            return dispatch({
              type: PaymentActions.SET_CONTINUE_BTN_STATE,
              payload: false,
            })
          }

          if (
            response.status
            === constants.httpStatusCodes.HTTP_NON_AUTHORITATIVE_INFORMATION
          ) {
            return dispatch({
              type: PaymentActions.SET_ERROR,
              payload: response.data,
            })
          }

          return dispatch({
            type: PaymentActions.SET_ERROR,
            payload:
              response.data.Message || 'No se pudo guardar, consulte a soporte',
          })
        }

        return dispatch({
          type: PaymentActions.SET_ERROR,
          payload: 'No se tienen datos de la empresa',
        })
      } catch (error) {
        return dispatch({
          type: PaymentActions.SET_ERROR,
          payload: 'Se produjo un error',
        })
      } finally {
        dispatch({ type: PaymentActions.SET_FORM_IS_FETCHING, payload: false })
      }
    }

    savePaymentMethod()
  }

  useEffect(() => {
    const getPymeData = async () => {
      const response = await getPymeDataUseCase.getDataPyme()

      setPymeData(response.data)
    }

    getPymeData()
  }, [])

  useEffect(() => {
    if (pymeData) {
      const getPamynetMethodData = async () => {
        const response = await getPaymentMethodDataUseCase.run(pymeData.Id)

        setPaymentMethodData(response.data)
      }

      getPamynetMethodData()
    }
  }, [pymeData])

  useEffect(() => {
    updateStepUseCase.run(Steps.Pago)
  }, [])

  return (
    <PaymentScreenGuard familyGroupRepository={familyGroupRepository}>
      <S.PageContent>
        <S.PaymentScreenContainer>
          <Formik
            initialValues={paymentFormInitValues(paymentMethodData)}
            onSubmit={handleOnPressSubmit}
            enableReinitialize
            validationSchema={paymentFormValidationSchema}
            validateOnMount
          >
            {(props: FormikProps<PaymentFormValues>) => (
              <form onSubmit={props.handleSubmit}>
                <S.HeaderContainer>
                  <UI.Text color="mariner" size="mdLg" weight="md">
                    Seleccioná el método de pago
                  </UI.Text>
                  <S.TextContainer>
                    <UI.Text>
                      Una vez revisada la documentación, nos estaremos
                      comunicando con vos para enviarte la factura.
                    </UI.Text>
                    <UI.Text>
                      El pago será efectuado de acuerdo al método que elijas.
                    </UI.Text>
                  </S.TextContainer>
                </S.HeaderContainer>

                <S.BtPaymentContainer>
                  <S.PaymentButtons
                    onClick={() => props.setFieldValue('paymentMethod', PaymentType.debit)}
                    isSelected={
                      props.values.paymentMethod === PaymentType.debit
                    }
                  >
                    <S.IconBackground>
                      <UI.Icons.BankIcon size="xs" color="mariner" />
                    </S.IconBackground>
                    <UI.Text color="mariner" weight="md">
                      Débito Automático
                    </UI.Text>
                  </S.PaymentButtons>

                  <S.PaymentButtons
                    onClick={() => props.setFieldValue('paymentMethod', PaymentType.transfer)}
                    isSelected={
                      props.values.paymentMethod === PaymentType.transfer
                    }
                  >
                    <S.IconBackground>
                      <UI.Icons.TransferIcon size="xs" color="mariner" />
                    </S.IconBackground>
                    <UI.Text color="mariner" weight="md">
                      Transferencia
                    </UI.Text>
                  </S.PaymentButtons>
                </S.BtPaymentContainer>
                <S.FormContainer disabled={state.isFormFetching}>
                  {props.values.paymentMethod === PaymentType.debit && (
                    <UI.TextField
                      name="cbu"
                      label="CBU"
                      placeholder="Ingresar"
                      onChange={(event: { target: HTMLInputElement }) => props.setFieldValue('cbu', event.target.value)}
                      value={props.values.cbu}
                      validationMsg={
                        props.errors.cbu
                        && `El CBU debe tener 22 caracteres, ${
                          props.values.cbu.toString().length
                        } de 22`
                      }
                    />
                  )}
                  {props.errors.paymentMethod && (
                    <UI.Text color="carmine">
                      {props.errors.paymentMethod}
                    </UI.Text>
                  )}
                  {state.error && (
                    <UI.Text color="carmine">{state.error}</UI.Text>
                  )}
                  <S.PaymentContent>
                    <UI.Text weight="md" color="mariner">
                      Datos de facturación
                    </UI.Text>
                    <S.Row>
                      <UI.TextField
                        name="nameAndLastName"
                        label="Nombre y Apellido"
                        placeholder="Ingresar"
                        onChange={(event: { target: HTMLInputElement }) => props.setFieldValue(
                          'nameAndLastName',
                          event.target.value,
                        )}
                        value={props.values.nameAndLastName}
                        validationMsg={props.errors.nameAndLastName}
                      />
                      <UI.MaskInput
                        name="grossIncome"
                        label="Ingresos brutos"
                        placeholder="Ingresar"
                        setFieldValue={props.setFieldValue}
                        value={props.values.grossIncome}
                        validationMsg={props.errors.grossIncome}
                        radix=","
                        thousandsSeparator="."
                      />
                    </S.Row>
                    <S.Row>
                      <UI.TextField
                        name="street"
                        label="Calle"
                        placeholder="Ingresar"
                        onChange={(event: { target: HTMLInputElement }) => props.setFieldValue('street', event.target.value)}
                        value={props.values.street}
                        validationMsg={props.errors.street}
                      />
                      <UI.TextField
                        name="streetNumber"
                        label="Número"
                        placeholder="Ingresar"
                        onChange={(event: { target: HTMLInputElement }) => props.setFieldValue(
                          'streetNumber',
                          event.target.value,
                        )}
                        value={props.values.streetNumber}
                        validationMsg={props.errors.streetNumber}
                      />
                    </S.Row>
                    <S.Row>
                      <UI.TextField
                        name="floorDpto"
                        label="Piso / Depto"
                        placeholder="Opcional"
                        onChange={(event: { target: HTMLInputElement }) => props.setFieldValue('floorDpto', event.target.value)}
                        value={props.values.floorDpto}
                        validationMsg={props.errors.floorDpto}
                      />
                      <UI.TextField
                        name="postalCode"
                        label="Código Postal"
                        placeholder="Ingresar"
                        onChange={(event: { target: HTMLInputElement }) => props.setFieldValue('postalCode', event.target.value)}
                        value={props.values.postalCode}
                        validationMsg={props.errors.postalCode}
                      />
                    </S.Row>
                  </S.PaymentContent>
                </S.FormContainer>
                <S.ButtonsFooter>
                  <UI.Button
                    type="button"
                    variant="outlined"
                    onClick={() => handleSetActiveIndex().prev()}
                  >
                    Anterior
                  </UI.Button>
                  <UI.Button
                    type="submit"
                    variant="outlined"
                    disabled={!props.isValid}
                    isLoading={state.isFormFetching}
                  >
                    <S.SaveButtonIcon>
                      <UI.Icons.Save2Icon
                        size="xxs"
                        color={!props.isValid ? 'tundora' : 'mariner'}
                      />
                    </S.SaveButtonIcon>
                    Guardar
                  </UI.Button>

                  <UI.Button
                    type="button"
                    onClick={() => handleSetActiveIndex().next()}
                    disabled={state.isContinueBtnDisabled}
                  >
                    Continuar
                  </UI.Button>
                </S.ButtonsFooter>
              </form>
            )}
          </Formik>
        </S.PaymentScreenContainer>
      </S.PageContent>
    </PaymentScreenGuard>
  )
}

export default PaymentScreen
