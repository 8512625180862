// extends or take complete list if is needed in the future (https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes)
const localeStrings = {
  enUs: 'en-US',
  esEs: 'es-ES',
}

type TTypeOfLocaleStrings = typeof localeStrings

type TLocaleStrings = keyof TTypeOfLocaleStrings

/**
 * Given number return a number with separators (commas, dots) depending on the localeString setting
 * @params { number } - number
 * @params { string } - localeString
 * @params { number } - maximumFractionDigits
 * @params { boolean } - withThousandSeparator
 *
 * @return { string }
 */
export const numberWithSeparators = ({
  number,
  localeString = 'esEs',
  maximumFractionDigits = 2,
  withThousandSeparator = false,
}: {
  number: number
  localeString?: TLocaleStrings
  maximumFractionDigits?: number
  withThousandSeparator?: boolean
}): string => {
  try {
    if (Number.isNaN(number)) throw new Error('Not a number')

    const num = number.toLocaleString(localeStrings[localeString], {
      maximumFractionDigits,
    })

    return withThousandSeparator
      ? num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      : num
  } catch (err) {
    return number.toString()
  }
}

export default {
  numberWithSeparators,
}
