import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Text from '../Text'
import NavBar from '../NavBar'
import { IPymeRepository } from '../../../../modules/Pyme/domain'
import MainLayerController from './controller'

const Container = styled.div`
  display: grid;
  grid-template-columns: 248px 1fr;
  grid-template-areas: 'nav page';
  min-height: 100vh;
`
const PageContainer = styled.div``
const PageContent = styled.div`
  width: 90%;
  margin: 1rem auto;
`
const InternalHeaderContainer = styled.div``
const InternalHeader = styled.div`
  height: 3.7rem;
  background-color: ${(props) => props.theme.colors.chathamsBlue};
  padding: 0px 2.5rem;
  display: flex;
  align-items: center;
`
const InternalHeaderFootLine = styled.div`
  height: 0.25rem;
  background-color: ${(props) => props.theme.colors.sail};
`

const MainLayerHeader = ({ children }: { children: string }) => (
  <InternalHeaderContainer>
    <InternalHeader>
      <Text weight="md" color="white" size="lg">
        {children}
      </Text>
    </InternalHeader>
    <InternalHeaderFootLine />
  </InternalHeaderContainer>
)

const MainLayer = ({
  pymeRepository,
  children,
  headerTitle = 'Inicio',
  activeIndex,
}: {
  pymeRepository: IPymeRepository
  children: ReactNode
  headerTitle: string
  activeIndex: number
}) => {
  const { data, pymeLogo, lastQuote } = MainLayerController({ pymeRepository })
  return (
    <Container>
      <NavBar
        dataPyme={data}
        pymeLogo={pymeLogo}
        lastQuote={lastQuote}
        activeScreen={activeIndex}
      />
      <PageContainer>
        <MainLayerHeader>{headerTitle}</MainLayerHeader>
        <PageContent>{children}</PageContent>
      </PageContainer>
    </Container>
  )
}

export default MainLayer
