import { HOLDER_STEPS } from '../../../../shared/constants/commons'

export const familyGroupFormInitialValues = (
  formData: TFamilyGroupFormValues | null,
): TFamilyGroupFormValues => ({
  holderId: formData?.holderId || 0,
  name: formData?.name || '',
  lastName: formData?.lastName || '',
  sex:
    typeof formData?.sex !== 'undefined' && formData.sex >= 0
      ? formData?.sex
      : -1,
  dni: formData?.dni || NaN,
  cuil: formData?.cuil || NaN,
  birthDay: formData?.birthDay || new Date(),
  email: formData?.email || '',
  repeatEmail: formData?.repeatEmail || '',
  age: formData?.age || 0,
  areaCode: formData?.areaCode || '',
  phone: formData?.phone || '',
  contributions: formData?.contributions || 0,
  street: formData?.street || '',
  streetNumber: formData?.streetNumber || '',
  floor: formData?.floor || '',
  province: formData?.province || -1,
  location: formData?.location || -1,
  postalCode: formData?.postalCode || '',
  // couple data,
  includeCouple: formData?.includeCouple || false,
  coupleId: formData?.coupleId || 0,
  coupleName: formData?.coupleName || '',
  coupleLastName: formData?.coupleLastName || '',
  coupleSex:
    typeof formData?.coupleSex !== 'undefined' && formData.coupleSex >= 0
      ? formData?.coupleSex
      : -1,
  coupleDni: formData?.coupleDni || NaN,
  coupleCuil: formData?.coupleCuil || 0,
  coupleBirthday: formData?.coupleBirthday || new Date(),
  coupleAge: formData?.coupleAge || 0,
  coupleAreaCode: formData?.coupleAreaCode || '',
  couplePhone: formData?.couplePhone || '',
  // childs data,
  childsAmount: formData?.childsAmount || 0,
  childs: formData?.childs.map((c) => ({ ...c, childId: c.childId || 0 })) || [],
  coveragePlans: formData?.coveragePlans || '-1',
  pmo: formData?.pmo || false,
})

export type TChild = {
  name: string
  lastName: string
  age: number
  sex: number
  childDni: number
  birthDay: Date
  childId: number
}

export type TFamilyGroupFormValues = {
  holderId: number
  name: string
  lastName: string
  sex: number
  dni: number
  cuil: number
  birthDay: Date
  email: string
  repeatEmail: string
  age: number
  areaCode: string
  phone: string
  contributions: number
  street: string
  streetNumber: string
  floor: string
  province: number
  location: number
  postalCode: string
  // couple data,
  includeCouple: boolean
  coupleId: number
  coupleName: string
  coupleLastName: string
  coupleSex: number
  coupleDni: number
  coupleCuil: number
  coupleBirthday: Date
  coupleAge: number
  coupleAreaCode: string
  couplePhone: string
  // childs data,
  childsAmount: number
  childs: TChild[]
  coveragePlans: string
  pmo: boolean
}

export const HOLDER_STATUS = {
  INCOMPLETE: 1,
  COMPLETE: 2,
}

export const StepsMapper = {
  [HOLDER_STEPS.INCOMPLETE]: 'Formulario Incompleto',
  [HOLDER_STEPS.COMPLETE]: 'DJS a enviar',
  [HOLDER_STEPS.DJ_SEND]: 'DJS enviada',
  [HOLDER_STEPS.DJ_COMPLETE]: 'DJS completa',
  [HOLDER_STEPS.FORM_COMPLETE]: 'Formulario Completo',
}

export default {
  familyGroupFormInitialValues,
  HOLDER_STATUS,
  HOLDER_STEPS,
  StepsMapper,
}
