import React, { FC } from 'react'
import styled from 'styled-components'
import { StyledIconBase } from '@styled-icons/styled-icon'

// Icons
import { Circle } from '@styled-icons/boxicons-regular/Circle'
import { Dot } from '@styled-icons/octicons/Dot'
import { Business } from '@styled-icons/material/Business'
import { People } from '@styled-icons/material/People'
import { Logout } from '@styled-icons/material/Logout'
import { KeyboardArrowDown } from '@styled-icons/material/KeyboardArrowDown'
import { KeyboardArrowUp } from '@styled-icons/material/KeyboardArrowUp'
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle'
import { Save2 } from '@styled-icons/remix-line/Save2'
import { Delete } from '@styled-icons/material/Delete'
import { Plus } from '@styled-icons/bootstrap/Plus'
import { DotFill } from '@styled-icons/octicons/DotFill'
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload'
import { Close } from '@styled-icons/ionicons-sharp/Close'
import { ErrorCircle } from '@styled-icons/fluentui-system-filled/ErrorCircle'
import { ErrorWarning } from '@styled-icons/remix-line/ErrorWarning'
import { FacebookSquare } from '@styled-icons/boxicons-logos/FacebookSquare'
import { Mail } from '@styled-icons/material/Mail'
import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'
import { ChevronUp } from '@styled-icons/evaicons-solid/ChevronUp'
import { ChevronDown } from '@styled-icons/evaicons-solid/ChevronDown'
import { Calendar } from '@styled-icons/bootstrap/Calendar'
import { PersonCircle } from '@styled-icons/ionicons-sharp/PersonCircle'
import { ArrowIosForward } from '@styled-icons/evaicons-solid/ArrowIosForward'
import { ArrowIosBack } from '@styled-icons/evaicons-solid/ArrowIosBack'
import { Transfer } from '@styled-icons/boxicons-regular/Transfer'
import { Bank } from '@styled-icons/bootstrap/Bank'
import { TColorsM } from '../shared/styles/colors'
import { iconSizes, TiconSize } from '../shared/styles/theme'

const defaultProps: TIcon = {
  color: 'silver',
  size: 'md',
  title: '',
  isCursor: false,
}

type TIcon = {
  color?: keyof TColorsM
  size?: keyof TiconSize
  title?: string | undefined
  isCursor?: boolean
}

type TIconWrapper = {
  color: keyof TColorsM
  isCursor: boolean
}

export const IconWrapper = styled.div<TIconWrapper>`
  ${StyledIconBase} {
    color: ${(props) => props.theme.colors[props.color]};
  }
  ${(props) => props.isCursor && 'cursor: pointer;'}
`

IconWrapper.defaultProps = { color: 'silver', isCursor: false }

export const CircleIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Circle size={iconSizes[size]} title={title} />
  </IconWrapper>
)
CircleIcon.defaultProps = defaultProps

export const DotIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Dot size={iconSizes[size]} title={title} />
  </IconWrapper>
)
DotIcon.defaultProps = defaultProps

export const BusinessIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Business size={iconSizes[size]} title={title} />
  </IconWrapper>
)
BusinessIcon.defaultProps = defaultProps

export const PeopleIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <People size={iconSizes[size]} title={title} />
  </IconWrapper>
)
PeopleIcon.defaultProps = defaultProps

export const LogoutIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Logout size={iconSizes[size]} title={title} />
  </IconWrapper>
)
LogoutIcon.defaultProps = defaultProps

export const KeyboardArrowDownIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <KeyboardArrowDown size={iconSizes[size]} title={title} />
  </IconWrapper>
)
KeyboardArrowDownIcon.defaultProps = defaultProps

export const KeyboardArrowUpIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <KeyboardArrowUp size={iconSizes[size]} title={title} />
  </IconWrapper>
)
KeyboardArrowUpIcon.defaultProps = defaultProps

export const DotFillIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <DotFill size={iconSizes[size]} title={title} />
  </IconWrapper>
)
DotFillIcon.defaultProps = defaultProps

export const CloudUploadIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <CloudUpload size={iconSizes[size]} title={title} />
  </IconWrapper>
)
CloudUploadIcon.defaultProps = defaultProps

export const CloseIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Close size={iconSizes[size]} title={title} />
  </IconWrapper>
)
CloseIcon.defaultProps = defaultProps

export const DeleteIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Delete size={iconSizes[size]} title={title} />
  </IconWrapper>
)
DeleteIcon.defaultProps = defaultProps

export const PlusIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'md',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Plus size={iconSizes[size]} title={title} />
  </IconWrapper>
)
PlusIcon.defaultProps = defaultProps

export const CheckCircleIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <CheckCircle size={iconSizes[size]} title={title} />
  </IconWrapper>
)
CheckCircleIcon.defaultProps = defaultProps

export const Save2Icon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Save2 size={iconSizes[size]} title={title} />
  </IconWrapper>
)
Save2Icon.defaultProps = defaultProps

export const ErrorCircleIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <ErrorCircle size={iconSizes[size]} title={title} />
  </IconWrapper>
)
ErrorCircleIcon.defaultProps = defaultProps

export const ErrorWarningIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <ErrorWarning size={iconSizes[size]} title={title} />
  </IconWrapper>
)
ErrorWarningIcon.defaultProps = defaultProps

export const ChevronUpIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <ChevronUp size={iconSizes[size]} title={title} />
  </IconWrapper>
)
ChevronUpIcon.defaultProps = defaultProps

export const ChevronDownIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <ChevronDown size={iconSizes[size]} title={title} />
  </IconWrapper>
)
ChevronDownIcon.defaultProps = defaultProps

export const FacebookSquareIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <FacebookSquare size={iconSizes[size]} title={title} />
  </IconWrapper>
)
FacebookSquareIcon.defaultProps = defaultProps

export const MailIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Mail size={iconSizes[size]} title={title} />
  </IconWrapper>
)
MailIcon.defaultProps = defaultProps

export const WhatsappIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Whatsapp size={iconSizes[size]} title={title} />
  </IconWrapper>
)
WhatsappIcon.defaultProps = defaultProps

export const InstagramIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Instagram size={iconSizes[size]} title={title} />
  </IconWrapper>
)
InstagramIcon.defaultProps = defaultProps

export const CalendarIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Calendar size={iconSizes[size]} title={title} />
  </IconWrapper>
)
CalendarIcon.defaultProps = defaultProps

export const PersonCircleIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <PersonCircle size={iconSizes[size]} title={title} />
  </IconWrapper>
)
PersonCircleIcon.defaultProps = defaultProps

export const ArrowIosForwardIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <ArrowIosForward size={iconSizes[size]} title={title} />
  </IconWrapper>
)
ArrowIosForwardIcon.defaultProps = defaultProps

export const ArrowIosBackIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <ArrowIosBack size={iconSizes[size]} title={title} />
  </IconWrapper>
)
ArrowIosBackIcon.defaultProps = defaultProps

export const BankIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Bank size={iconSizes[size]} title={title} />
  </IconWrapper>
)
BankIcon.defaultProps = defaultProps

export const TransferIcon: FC<TIcon> = ({
  color = 'silver',
  size = 'sm',
  title = '',
  isCursor = false,
}) => (
  <IconWrapper color={color} isCursor={isCursor}>
    <Transfer size={iconSizes[size]} title={title} />
  </IconWrapper>
)
TransferIcon.defaultProps = defaultProps
