import * as yup from 'yup'
import { ILocation,
  IProvince } from '../../../../../../modules/Province/domain/Province'
import { cuilValidator } from '../../../../shared/helpers/utils'
import * as Step1Contants from './constants'

const { requiredField, today } = Step1Contants

export const FormSchema = (provincies: IProvince[]) => yup.object().shape({
  razonSocial: yup.string().required(requiredField),
  cuit: yup.string().test({
    name: 'cuilValidation',
    exclusive: false,
    params: {},
    message: 'Cuil no válido',
    test(value: any) {
      return value ? cuilValidator(value) : false
    },
  }),
  mail: yup.string().email('Email invalido').required(requiredField),
  codigoArea: yup
    .number()
    .test('codigoAreaMinLength', 'Mínimo 2', (code) => {
      if (!code) return false

      return code?.toString().length >= 2
    })
    .test('codigoAreaMaxLength', 'Máximo 4', (code) => {
      if (!code) return false

      return code?.toString().length <= 4
    }),
  telefono: yup
    .number()
    .test('telefonoMinLength', 'Mínimo 6', (code) => {
      if (!code) return false

      return code?.toString().length >= 6
    })
    .test('telefonoMaxLength', 'Máximo 12', (code) => {
      if (!code) return false

      return code?.toString().length <= 12
    }),
  provincia: yup
    .number()
    .min(0, 'Debes seleccionar una provincia')
    .required(requiredField),
  localidad: yup.number().test({
    name: 'requerirlocalidad',
    exclusive: false,
    params: {},
    message: 'Debes seleccionar una localidad',
    test(value: any) {
      const pr: ILocation[] | undefined = provincies?.find(
        (p: IProvince) => p.id === this.parent.provincia,
      )?.localidades

      if (!pr || !pr.length) return true
      if (value === -1) return false

      return true
    },
  }),
  fechaVigencia: yup
    .date()
    .min(today, 'La fecha no puede ser anterior a hoy')
    .required(requiredField),
})

export default FormSchema
