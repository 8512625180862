import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as S from '../Pages/CompanyApplication/Steps/Step2/styles'
import UI from '../UI'
import IDjsRepository from '../../../modules/Djs/domain/IDjsRepository'
import GetFamilysGroupData from '../../../modules/FamilyGroup/application/useCase/getFamilyGroupData'
import { IFamilyGroupRepository } from '../../../modules/FamilyGroup/domain/entities'
import { TFamilyGroup } from '../../../modules/Pyme/domain/entity/saveFamilyGroupDto'
import IQuestionRepository from '../../../modules/Questions/domain/QuestionRepository'
import DJsScreen from '../Pages/Djs'

const DJsGuard = ({
  familyGroupRepository,
  djsRepository,
  questionRepository,
}: {
  familyGroupRepository: IFamilyGroupRepository
  djsRepository: IDjsRepository
  questionRepository: IQuestionRepository
}) => {
  const { guid }: { guid: string } = useParams()
  const [data, setData] = useState<TFamilyGroup | null>(null)
  const [errorMsg, setErrorMsg] = useState('')
  const getFamilyGroupDataUseCase = new GetFamilysGroupData(
    familyGroupRepository,
  )

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getFamilyGroupDataUseCase.run(guid)

        setData(response)
      } catch (error) {
        setErrorMsg('Hubo un error al traer los datos del grupo familiar...')
      }
    }

    getData()
  }, [])

  if (errorMsg) return <p>{errorMsg}</p>

  if (data) {
    return (
      <DJsScreen
        data={data}
        djsRepository={djsRepository}
        questionRepository={questionRepository}
        guid={guid}
      />
    )
  }

  return (
    <S.LoaderContainer>
      <UI.Spinner />
      <UI.Text size="xl">Cargando...</UI.Text>
    </S.LoaderContainer>
  )
}

export default DJsGuard
