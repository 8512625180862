import { PaymentState, PaymentAction, PaymentActions } from './Shared'

export const PaymentReducer = (
  state: PaymentState,
  action: PaymentAction,
): PaymentState => {
  switch (action.type) {
    case PaymentActions.SET_FORM_IS_FETCHING: {
      return {
        ...state,
        isFormFetching: action.payload,
      }
    }

    case PaymentActions.SET_CONTINUE_BTN_STATE: {
      return {
        ...state,
        isContinueBtnDisabled: action.payload,
      }
    }

    case PaymentActions.SET_ERROR: {
      return {
        ...state,
        error: action.payload,
      }
    }

    default:
      return state
  }
}

export default PaymentReducer
