import { Steps } from '../../../commons'
import { IPymeRepository } from '../../domain'

export default class UpdateSteps {
  repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async run(step: Steps): Promise<void> {
    return this.repository.updateSteps(step)
  }
}
