import { useEffect, useState } from 'react'
import GetAllProvinciesUseCase from '../../../../../../modules/Province/application/useCase/getAllProvincies'
import { Province } from '../../../../../../modules/Province/domain/Province'
import ProvinceRepository from '../../../../../../modules/Province/infraestructure/repositories/provinceRepository'
import GetDataPymeUseCase from '../../../../../../modules/Pyme/application/useCase/getDataPyme'
import PutDataPymeUseCase from '../../../../../../modules/Pyme/application/useCase/putDataPyme'
import { IPymeRepository,
  TDataPyme } from '../../../../../../modules/Pyme/domain'
import { Asignations,
  SaveCompanyDataDTO } from '../../../../../../modules/Pyme/application/useCase/putDataPyme/SaveCompanyDataDTO'
import { SaveCompanyFormData } from '../../../../../../modules/Pyme/domain/entity/SaveCompanyFormData'
import UpdateSteps from '../../../../../../modules/Pyme/application/useCase/updateSteps'
import { Steps } from '../../../../../../modules/commons'

const isPymeDataComplete = (pymeData: TDataPyme): boolean => {
  const checks = []
  checks.push(!!pymeData.RazonSocial)
  checks.push(!!pymeData.CUIT)
  checks.push(!!pymeData.Mail)
  checks.push(!!pymeData.Telefono)
  checks.push(!!pymeData.Provincia)
  checks.push(!!pymeData.FechaVigencia)

  return checks.every((v) => v === true)
}

const getLocalitiesFromProvinceId = (
  provincies: Province[],
  provId: number,
): number => provincies.find((p) => p.id === provId)?.localidades.length || 0

const companyDataMapper = (
  props: SaveCompanyFormData,
  dataPyme: TDataPyme,
  provincies: Province[],
): SaveCompanyDataDTO => {
  const assignations: Asignations[] = []

  assignations.push(props.enRelacionDeDependencia)

  assignations.push(props.particulares)

  assignations.push(props.monotributistas)

  const data = {
    Id: dataPyme.Id,
    RazonSocial: props.razonSocial,
    CUIT: props.cuit,
    Mail: props.mail,
    Telefono: `${props.codigoArea}-${props.telefono}`,
    IdProvincia: props.provincia,
    Asignaciones: assignations,
    fechaVigencia: new Date(props.fechaVigencia),
  }

  if (getLocalitiesFromProvinceId(provincies, props.provincia) > 0) {
    Object.assign(data, { IdLocalidad: props.localidad })
  }

  return data
}

const saveOrEditCompanyDataController = ({
  pymeRepository,
  provinceRepository,
}: {
  pymeRepository: IPymeRepository
  provinceRepository: ProvinceRepository
}) => {
  // data
  const [pymeData, setPymeData] = useState<TDataPyme | null>(null)
  const [companyDataSaved, setCompanyDataSaved] = useState(false)

  const [newChangesSaved, setNewChanges] = useState(false)

  const [provincies, setProvincies] = useState<Province[]>([])
  const [msgOnSavePyme, setMsgOnSavePyme] = useState({
    error: false,
    msg: '',
  })
  // fetch states
  const [isFetching, setIsFetching] = useState(false)
  const [pymeDataIsFetching, setPymeDataIsFetching] = useState(false)
  const [companyDataIsFetching, setCompanyDataIsFetching] = useState(false)

  // error msgs
  const [errorMsgOnFetchProvincies, setErrorMsgOnFetchProvincies] = useState('')
  const [errorMsgOnPutCompanyData, setErrorMsgOnPutCompanyData] = useState('')
  const [errorMsgOnFetchPymeData, setErrorMsgOnFetchPymeData] = useState('')

  // province
  const useCase = new GetAllProvinciesUseCase(provinceRepository)

  // pyme
  const getDataPymeUseCase = new GetDataPymeUseCase(pymeRepository)
  const putCompanyDataUseCase = new PutDataPymeUseCase(pymeRepository)
  const updateStepUseCase = new UpdateSteps(pymeRepository)

  const handleOnCloseSuccessSave = () => setMsgOnSavePyme({ error: false, msg: '' })

  const handleNewChanges = () => setNewChanges(false)

  const handleOnSaveCompanyData = async (props: SaveCompanyFormData) => {
    try {
      setErrorMsgOnPutCompanyData('')
      setMsgOnSavePyme({ error: false, msg: '' })
      setCompanyDataIsFetching(true)
      const response = await getDataPymeUseCase.getDataPyme()
      const dataToApi = companyDataMapper(props, response.data, provincies)
      await putCompanyDataUseCase.putDataPyme(dataToApi)

      return (
        setCompanyDataSaved(true),
        setNewChanges(true),
        setMsgOnSavePyme({
          error: false,
          msg: 'Cambios guardados',
        })
      )
    } catch (error) {
      return setErrorMsgOnPutCompanyData('Error en guardar/editar datos!')
    } finally {
      setCompanyDataIsFetching(false)
    }
  }

  useEffect(() => {
    const getAllProvincies = async () => {
      try {
        setErrorMsgOnFetchProvincies('')
        setIsFetching(true)
        const response = await useCase.getAllProvincies()

        return setProvincies(response.data.provincias)
      } catch (error) {
        setProvincies([])
        return setErrorMsgOnFetchProvincies('No se pudo traer las provincias')
      } finally {
        setIsFetching(false)
      }
    }

    getAllProvincies()
  }, [])

  useEffect(() => {
    const getPymeData = async () => {
      try {
        setPymeDataIsFetching(true)
        const response = await getDataPymeUseCase.getDataPyme()

        return setPymeData(response.data)
      } catch (error) {
        return setErrorMsgOnFetchPymeData(
          'Error al obtener datos de la empresa y poder precargar el formulario',
        )
      } finally {
        setPymeDataIsFetching(false)
      }
    }

    getPymeData()
  }, [])

  useEffect(() => {
    if (pymeData) setCompanyDataSaved(isPymeDataComplete(pymeData))
  }, [pymeData])

  useEffect(() => {
    updateStepUseCase.run(Steps.DatosPymes)
  }, [])

  return {
    provincies,
    errorMsgOnFetchProvincies,
    isFetching,
    handleOnSaveCompanyData,
    companyDataSaved,
    errorMsgOnPutCompanyData,
    companyDataIsFetching,
    pymeData,
    pymeDataIsFetching,
    errorMsgOnFetchPymeData,
    msgOnSavePyme,
    handleOnCloseSuccessSave,
    newChangesSaved,
    handleNewChanges,
  }
}

export default saveOrEditCompanyDataController
