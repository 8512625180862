import { Asignation,
  TDataPyme } from '../../../../../../modules/Pyme/domain/entity/DataPyme'
import { SaveCompanyFormData } from '../../../../../../modules/Pyme/domain/entity/SaveCompanyFormData'
import { memberTypes } from '../../../../../../modules/commons/member'

export const Step1FormInitialValues = ({
  pymeData,
}: {
  pymeData: TDataPyme
}): SaveCompanyFormData => {
  const desregulado = pymeData.Asignaciones.find(
    (a) => a.IdTipoAfiliacion === memberTypes.desrregulado,
  ) as Asignation
  const monotributista = pymeData.Asignaciones.find(
    (a) => a.IdTipoAfiliacion === memberTypes.monotributo,
  ) as Asignation
  const particular = pymeData.Asignaciones.find(
    (a) => a.IdTipoAfiliacion === memberTypes.individual,
  ) as Asignation

  const desreguladoCapitas = desregulado?.Capitas || 0
  const monotributistaCapitas = monotributista?.Capitas || 0
  const particularCapitas = particular?.Capitas || 0

  const [codArea, tel] = pymeData.Telefono.split('-')

  const data = {
    razonSocial: pymeData?.RazonSocial || '',
    cuit: pymeData?.CUIT || '',
    mail: pymeData?.Mail || '',
    codigoArea: parseInt(codArea, 10) || 0,
    telefono: parseInt(tel, 10) || 0,
    provincia: pymeData?.IdProvincia || -1,
    localidad: pymeData?.IdLocalidad || -1,
    fechaVigencia: pymeData?.FechaVigencia
      ? `${pymeData.FechaVigencia}.000Z`
      : '',
    colaboradores:
      desreguladoCapitas + monotributistaCapitas + particularCapitas,
    enRelacionDeDependencia: desregulado,
    monotributistas: monotributista,
    particulares: particular,
  }

  return data
}

export const requiredField = 'El campo es requerido'
export const today = new Date()

export const companyCovarageDatesOptions: { label: string; value: string }[] = Array(3)
  .fill(null)
  .reduce((acc, curr, index) => {
    const date = new Date()
    const format = (number: number) => (number > 9 ? number : `0${number}`)

    date.setDate(1)
    date.setMonth(date.getMonth() + (index + 1))
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    const day = format(date.getDate())
    const month = format(date.getMonth() + 1)
    const year = date.getFullYear()

    const nextDateFormatted = `${day}/${month}/${year}`

    const option = { label: nextDateFormatted, value: date.toISOString() }
    acc.push(option)

    return acc
  }, [])

export default {
  Step1FormInitialValues,
  requiredField,
  today,
  companyCovarageDatesOptions,
}
