import { TServerResponse } from '../../../../../app/domain/httpClient'
import constants from '../../../../../app/infraestructure/shared/constants'
import { IPymeRepository } from '../../../domain'
import { PaymentMethodParams } from './PaymentMethodParams'
import { PaymentType } from './PaymentType'

export const cuilValidator = (cuil: string): boolean => {
  const cuitCuil = cuil.replaceAll('-', '')

  if (cuitCuil.length !== 11) {
    return false
  }

  const [checkDigit, ...rest] = cuitCuil.split('').map(Number).reverse()

  const total = rest.reduce(
    (acc, cur, index) => acc + cur * (2 + (index % 6)),
    0,
  )

  const mod11 = 11 - (total % 11)

  if (mod11 === 11) {
    return checkDigit === 0
  }

  if (mod11 === 10) {
    return false
  }

  return checkDigit === mod11
}

const mapInfoExtraByPaymentMethod = (
  paymentMethod: PaymentType,
  cbu: string,
): string => {
  if (paymentMethod === PaymentType.debit) return cbu

  return ''
}

export default class SavePaymentMethod {
  readonly repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async run(params: PaymentMethodParams): Promise<TServerResponse<any>> {
    if (params.formValues.paymentMethod === PaymentType.none) {
      return Promise.resolve({
        status: constants.httpStatusCodes.HTTP_NON_AUTHORITATIVE_INFORMATION,
        data: 'Debe seleccionar un tipo de pago',
      })
    }
    if (!params.IdPyme) {
      return Promise.resolve({
        status: constants.httpStatusCodes.HTTP_NON_AUTHORITATIVE_INFORMATION,
        data: 'Falta el id de la Pyme',
      })
    }
    if (
      params.formValues.paymentMethod === PaymentType.debit
      && !params.formValues.cbu
    ) {
      return Promise.resolve({
        status: constants.httpStatusCodes.HTTP_NON_AUTHORITATIVE_INFORMATION,
        data: 'Debe ingresar un CBU',
      })
    }
    if (
      params.formValues.paymentMethod === PaymentType.debit
      && params.formValues.cbu.length !== 22
    ) {
      return Promise.resolve({
        status: constants.httpStatusCodes.HTTP_NON_AUTHORITATIVE_INFORMATION,
        data: 'CBU no válido',
      })
    }

    return this.repository.savePaymentMethod({
      MedioPago: {
        IdPyme: params.IdPyme,
        TipoPago: params.formValues.paymentMethod,
        InfoExtra: mapInfoExtraByPaymentMethod(
          params.formValues.paymentMethod,
          params.formValues.cbu,
        ),
      },
      DatoFacturacion: {
        NombreCompleto: params.formValues.nameAndLastName,
        IIBB: params.formValues.grossIncome,
        Calle: params.formValues.street,
        Numero: params.formValues.streetNumber,
        Resto: params.formValues.floorDpto || '',
        CodigoPostal: params.formValues.postalCode,
      },
    })
  }
}
