import { useState, useEffect, useContext } from 'react'

import GetDataPyme from '../../../../modules/Pyme/application/useCase/getDataPyme'
import GetLastQuote from '../../../../modules/Pyme/application/useCase/getLastQuote'
import GetPymeLogo from '../../../../modules/Pyme/application/useCase/getPymeLogo'
import { ILastQuote,
  IPymeRepository,
  TDataPyme } from '../../../../modules/Pyme/domain'
import stores from '../../stores'

const MainLayerController = ({
  pymeRepository,
}: {
  pymeRepository: IPymeRepository
}) => {
  const [data, setData] = useState<TDataPyme | null>(null)
  const [errorMsg, setErrorMsg] = useState('')
  const [pymeLogo, setPymeLogo] = useState<any>(null)
  const [errorPymeLogoMsg, setErrorPymeLogoMsg] = useState('')
  const [lastQuote, setLastQuote] = useState<ILastQuote | null>(null)

  const getDataPymeUseCase = new GetDataPyme(pymeRepository)
  const getPymeLogoUseCase = new GetPymeLogo(pymeRepository)
  const getLastQuoteUseCase = new GetLastQuote(pymeRepository)

  useEffect(() => {
    async function getData() {
      try {
        setErrorMsg('')
        const response = await getDataPymeUseCase.getDataPyme()

        return setData(response.data)
      } catch (err) {
        setData(null)
        return setErrorMsg('Error en conseguir data pyme.')
      }
    }

    getData()
  }, [])

  useEffect(() => {
    async function getPymeLogo() {
      try {
        setErrorPymeLogoMsg('')

        // this just for typescript warning
        if (!data?.Id) return

        const response = await getPymeLogoUseCase.run(data.Id)

        setPymeLogo(response)
      } catch (err) {
        setErrorPymeLogoMsg('No se pudo obtener el avatar')
      }
    }

    if (data?.Id) {
      getPymeLogo()
    }
  }, [data])

  const context = useContext(stores.PymeRequoteContext)
  const requoteDateTime = {
    dmy: '',
    time: '',
  }

  useEffect(() => {
    async function getLastQuote() {
      try {
        const response = await getLastQuoteUseCase.getLastQuote()

        const today = () => {
          requoteDateTime.dmy = response.data.FechaUltimaCotizacion
          requoteDateTime.time = response.data.HoraUltimaCotizacion
          return requoteDateTime
        }
        context.requotePriceContext(response.data.Total, today())

        return setLastQuote(response.data)
      } catch (err) {
        return setLastQuote(null)
      }
    }

    getLastQuote()
  }, [])

  return {
    data,
    errorMsg,
    pymeLogo,
    errorPymeLogoMsg,
    lastQuote,
  }
}

export default MainLayerController
