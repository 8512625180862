import { IApi, TServerResponse } from '../../../../app/domain/httpClient'
import IQuestion from '../../domain/entity/Question'
import IQuestionRepository from '../../domain/QuestionRepository'

export default class QuestionRepository implements IQuestionRepository {
  private httpClient: IApi

  private questions: { [key: number]: IQuestion[] } = {}

  constructor(http: IApi) {
    this.httpClient = http
  }

  async getQuestionsByMemberTypeId(memberTypeId: number): Promise<IQuestion[]> {
    if (!this.questions[memberTypeId]) {
      this.questions[memberTypeId] = (
        await this.httpClient.get<TServerResponse<IQuestion[]>>('/preguntas', {
          params: { tipo: memberTypeId },
        })
      ).data
    }

    return this.questions[memberTypeId]
  }
}
