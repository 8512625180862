import React from 'react'
import { Tabs, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { THoldersListByMemberTypeKeys } from '../../../../../../modules/FamilyGroup/domain/DTOs'
import S from '../../styles'

const TabsComponent = ({
  onPressTabs,
}: {
  onPressTabs: (memberType: THoldersListByMemberTypeKeys) => void
}) => (
  <Tabs>
    <S.StyledTabList>
      <S.StyledTab onClick={() => onPressTabs('Desregulados')}>
        Relación de dependencia
      </S.StyledTab>
      <S.StyledTab onClick={() => onPressTabs('Monotributistas')}>
        Monotributistas
      </S.StyledTab>
      <S.StyledTab onClick={() => onPressTabs('Individuales')}>
        Particulares
      </S.StyledTab>
    </S.StyledTabList>
    <S.GrayLine />
    <TabPanel />
    <TabPanel />
    <TabPanel />
  </Tabs>
)

export default TabsComponent
