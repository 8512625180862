import React from 'react'
import { useLocation } from 'react-router-dom'
import { IFamilyGroupRepository } from '../../../../modules/FamilyGroup/domain/entities'
import ProvinceRepository from '../../../../modules/Province/infraestructure/repositories/provinceRepository'
import { IPymeRepository } from '../../../../modules/Pyme/domain'
import useStepper, { IHandleSetActiveIndex } from '../../hooks/useStepper'
import UI from '../../UI'
import CompanyApplication from './Steps/Step1'
import FamilyGroup from './Steps/Step2'
import PaymentScreen from './Steps/Step3'
import Detail from './Steps/Step4'
import S from './styles'

const titles = ['Datos de la empresa', 'Grupos familiares', 'Método de pago']
const screens = [
  (props: {
    handleSetActiveIndex: () => IHandleSetActiveIndex
    pymeRepository: IPymeRepository
    provinceRepository: ProvinceRepository
  }) => <CompanyApplication {...props} />,
  (props: {
    provinceRepository: ProvinceRepository
    familyGroupRepository: IFamilyGroupRepository
    handleSetActiveIndex: () => IHandleSetActiveIndex
    pymeRepository: IPymeRepository
  }) => <FamilyGroup {...props} />,
  (props: {
    handleSetActiveIndex: () => IHandleSetActiveIndex
    familyGroupRepository: IFamilyGroupRepository
    pymeRepository: IPymeRepository
  }) => <PaymentScreen {...props} />,
  (props: {
    handleSetActiveIndex: () => IHandleSetActiveIndex
    pymeRepository: IPymeRepository
  }) => <Detail {...props} />,
]

const PageCompanyApplication = ({
  pymeRepository,
  provinceRepository,
  familyGroupRepository,
}: {
  pymeRepository: IPymeRepository
  provinceRepository: ProvinceRepository
  familyGroupRepository: IFamilyGroupRepository
}) => {
  const location = useLocation<{ step: number }>()
  const step = location.state?.step
  const { activeIndex, itemsTitle, handleSetActiveIndex } = useStepper({
    data: titles,
    activeScreen: step,
  })

  return (
    <UI.MainLayer
      headerTitle="Solicitud de alta"
      pymeRepository={pymeRepository}
      activeIndex={activeIndex}
    >
      <UI.Cards.Card>
        <UI.Steppers.Stepper titleItems={itemsTitle} active={activeIndex} />
      </UI.Cards.Card>
      <S.ScreensContainer>
        {screens[activeIndex]({
          handleSetActiveIndex,
          pymeRepository,
          provinceRepository,
          familyGroupRepository,
        })}
      </S.ScreensContainer>
    </UI.MainLayer>
  )
}

export default PageCompanyApplication
