import Answer from '../../../../modules/Djs/domain/entities/Answer'
import { TDjsForm } from '../../../../modules/Djs/domain/valueObjects'

export const initialValues = (data: Answer | any): TDjsForm => ({
  altura: data?.altura || 0,
  peso: data?.peso || 0,
  ...data?.preguntas,
})

export const DjStateValues = {
  datosIncompletos: 2,
  enviar: 3, // cuando están los datos completos
  enviada: 4,
  completa: 5,
}

export default {
  initialValues,
  DjStateValues,
}
