import { IApi } from '../../../../app/domain/httpClient'
import { GetAllProvinciesServerResponse } from '../../application/useCase/GetAllProvinciesServerResponse'

export default class ProvinceRepository implements ProvinceRepository {
  readonly http: IApi

  private provincies: GetAllProvinciesServerResponse | null

  constructor(httpClient: IApi) {
    this.http = httpClient
    this.provincies = null
  }

  async getAllProvincies(): Promise<GetAllProvinciesServerResponse> {
    if (!this.provincies) {
      this.provincies = await this.http.get<GetAllProvinciesServerResponse>(
        '/Provincias',
      )
    }

    return this.provincies
  }
}
