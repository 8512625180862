import React, { ReactElement, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import Stores from '../stores'

const RedirectResetPassword = ({ children }: { children: ReactElement }) => {
  const { isAuth, isResetPassword } = useContext(Stores.LoginContext)

  if (!isAuth) {
    return children
  }

  return isResetPassword ? <Redirect to="/renovarClave" /> : children
}

export default RedirectResetPassword
