import { IFamilyGroupRepository } from '../../domain/entities'

export default class GetFamilysGroupData {
  private repository: IFamilyGroupRepository

  constructor(repository: IFamilyGroupRepository) {
    this.repository = repository
  }

  run(guid: string) {
    return this.repository.getFamilyGroupData(guid)
  }
}
