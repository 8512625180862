import { DataPymeServerResponse, IPymeRepository } from '../../../domain'

type GetDataPymeProp = boolean

export default class GetDataPyme {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async getDataPyme(props?: GetDataPymeProp): Promise<DataPymeServerResponse> {
    return this.repository.getDataPyme(props)
  }
}
