import { TFamilyGroupFormValues } from '../../../../app/infraestructure/Pages/CompanyApplication/Steps/Step2/constants'
import { IFamilyGroupRepository } from '../../domain/entities'

export default class GetFamilysGroupDetail {
  readonly repository: IFamilyGroupRepository

  constructor(repository: IFamilyGroupRepository) {
    this.repository = repository
  }

  async run(guid: string): Promise<TFamilyGroupFormValues> {
    return this.repository.getFamilysGroupDetail(guid)
  }
}
