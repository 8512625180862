export const openExternalLink = (url: string) => {
  window.open(url, '_blank')
}

export const cuilValidator = (cuil: string): boolean => {
  const cuitCuil = cuil.replaceAll('-', '')

  if (cuitCuil.length !== 11) {
    return false
  }

  const [checkDigit, ...rest] = cuitCuil.split('').map(Number).reverse()

  const total = rest.reduce(
    (acc, cur, index) => acc + cur * (2 + (index % 6)),
    0,
  )

  const mod11 = 11 - (total % 11)

  if (mod11 === 11) {
    return checkDigit === 0
  }

  if (mod11 === 10) {
    return false
  }

  return checkDigit === mod11
}

export default {
  openExternalLink,
  cuilValidator,
}
