import React from 'react'
import Spinner from './Spinner'

interface LoadingWrapperProps {
  children: any
  isLoading: boolean
}

const LoadingWrapper = ({ isLoading, children }: LoadingWrapperProps) => (isLoading ? <Spinner /> : children)

export default LoadingWrapper
