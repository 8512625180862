import React, { ReactElement, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Stores from '../stores'

// This component prevent to navigate between children routes if user is
// NOT authenticated
const PrivateRoute = ({
  children,
  path,
}: {
  children: ReactElement
  path: string
}) => {
  const { isAuth } = useContext(Stores.LoginContext)

  return isAuth ? <Route path={path}>{children}</Route> : <Redirect to="/" />
}

export default PrivateRoute
