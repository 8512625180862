import React from 'react'
import { THoldersList } from '../../../../../../modules/FamilyGroup/domain/DTOs'
import holdersLocal from './HolderLocalModule'
import { HOLDER_STATUS } from './constants'
import * as S from './styles'
import constants from '../../../../shared/constants'

const {
  commons: { HOLDER_STEPS },
} = constants

export const enabled = () => {
  const allHolders = holdersLocal.getHolders()
  const totalHolders = (allHolders?.Desregulados.length || 0)
    + (allHolders?.Individuales.length || 0)
    + (allHolders?.Monotributistas.length || 0)

  const completedFG = {
    total: totalHolders,
    groupsCompleted: 0,
    groupsWithDjsCompleted: 0,
    continueBt: false,
  }

  allHolders?.Desregulados.forEach((h: THoldersList) => {
    const { Estado, PasoDesdeFuente } = h
    if (Estado === HOLDER_STATUS.COMPLETE) {
      completedFG.groupsCompleted += 1
    }
    if (PasoDesdeFuente === HOLDER_STEPS.DJ_COMPLETE) {
      completedFG.groupsWithDjsCompleted += 1
    }
  })
  allHolders?.Individuales.forEach((h: THoldersList) => {
    const { Estado, PasoDesdeFuente } = h
    if (Estado === HOLDER_STATUS.COMPLETE) {
      completedFG.groupsCompleted += 1
    }
    if (PasoDesdeFuente === HOLDER_STEPS.DJ_COMPLETE) {
      completedFG.groupsWithDjsCompleted += 1
    }
  })
  allHolders?.Monotributistas.forEach((h: THoldersList) => {
    const { Estado, PasoDesdeFuente } = h
    if (Estado === HOLDER_STATUS.COMPLETE) {
      completedFG.groupsCompleted += 1
    }
    if (PasoDesdeFuente === HOLDER_STEPS.DJ_COMPLETE) {
      completedFG.groupsWithDjsCompleted += 1
    }
  })

  completedFG.continueBt = completedFG.total === completedFG.groupsWithDjsCompleted

  return { completedFG }
}

const TotalGroups = () => {
  const { completedFG } = enabled()

  return (
    <S.TotalCompleteGroups>
      Total grupos completos:
      {completedFG.groupsWithDjsCompleted}
      /
      {completedFG.total}
    </S.TotalCompleteGroups>
  )
}

export default TotalGroups
