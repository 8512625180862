import React from 'react'

import C from './components'
import Stores from './stores'

const App = () => {
  // TODO: create logger
  // eslint-disable-next-line
  console.log(
    `%c Enviroment: ${process.env.REACT_APP_ENV}`,
    'background-color: #1ABC9C;',
  )

  return (
    <Stores.Login>
      <Stores.PymeRequote>
        <C.Routes />
      </Stores.PymeRequote>
    </Stores.Login>
  )
}

export default App
