import React, { FC, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from './Icon'
import Text from './Text'

export const CardBasic = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CardContainer = styled(CardBasic)`
  padding: 1rem 2rem;
  gap: 2rem;
`

// card normally used with forms
const Card: FC<{ children: ReactNode }> = ({ children }) => (
  <CardContainer>{children}</CardContainer>
)

// CARD TOGGLE
const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 1rem;
`

const CardContent = styled.div`
  width: 100%;
  padding: 0.7rem 1rem;
`

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  color: ${(props) => props.theme.colors.alto};
`

export const CardToggle: FC<{
  children: ReactNode
  title: string
}> = ({ children, title }) => {
  const [open, setOpen] = useState(false)

  const handleOnPressToggle = () => setOpen(!open)

  return (
    <CardBasic>
      <CardHeader onClick={handleOnPressToggle}>
        <Text color="mariner" weight="mdS">
          {title}
        </Text>
        {open ? (
          <KeyboardArrowUpIcon color="alto" />
        ) : (
          <KeyboardArrowDownIcon color="alto" />
        )}
      </CardHeader>
      {open && (
        <>
          <Hr />
          <CardContent>{children}</CardContent>
        </>
      )}
    </CardBasic>
  )
}

export const CardSimple: FC<{
  children: ReactNode
  title: string
}> = ({ children, title }) => (
  <CardBasic>
    <CardHeader>
      <Text color="mariner" weight="mdS">
        {title}
      </Text>
    </CardHeader>
    <Hr style={{ width: '95%', color: '#C4C4C4' }} />
    <CardContent>{children}</CardContent>
  </CardBasic>
)

const CardInfoContent = styled(CardBasic)`
  padding: 0.7rem 1rem;
  flex-direction: row;
  justify-content: space-between;

  > :first-child {
    width: 77%;
  }
  > :nth-child(2) {
    width: 20%;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
`
// card normally used with data and action button
export const CardInfo: FC<{
  data: ReactNode
  actions: ReactNode
}> = ({ data, actions }) => (
  <CardInfoContent>
    <Column>{data}</Column>
    <Column>{actions}</Column>
  </CardInfoContent>
)

export default {
  Card,
  CardToggle,
  CardInfo,
  CardSimple,
}
