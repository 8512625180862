import React from 'react'
import constants from '../../../../shared/constants'
import S from '../../styles'
import holdersLocal from './HolderLocalModule'

const {
  commons: { HOLDER_STEPS },
} = constants

const CompletedHolders = () => {
  const { completed, totalOfHolders } = (() => {
    const holdersList = holdersLocal.getHoldersList()
    const statusHolder: {
      completed: number
      totalOfHolders: number
    } = {
      completed: 0,
      totalOfHolders: 0,
    }

    statusHolder.completed = holdersList?.filter(
      ({ PasoDesdeFuente }) => PasoDesdeFuente === HOLDER_STEPS.DJ_COMPLETE,
    ).length || 0
    statusHolder.totalOfHolders = holdersList?.length || 0

    return statusHolder
  })()

  return (
    <S.CompletedHoldersContainer
      completed={completed}
      totalOfHolders={totalOfHolders}
      memberType={holdersLocal.getMemberType()}
    >
      {`${completed} de ${totalOfHolders} grupos completos`}
    </S.CompletedHoldersContainer>
  )
}

export default CompletedHolders
