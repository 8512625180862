import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import Basic from './FieldBasic'
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from './Icon'

export const FieldNumberContainer = styled.div<{
  disabled?: boolean
  tiny?: boolean
}>`
  position: relative;

  > input {
    width: ${({ tiny }) => (tiny ? '50%' : '100%')};
    height: 2rem;
    border: 1px solid ${({ theme }) => theme.colors.silverChalice};
    border-radius: 3px;
    color: ${({ theme, disabled }) => (disabled ? theme.colors.silver : theme.colors.greyishBrownTwo)};
    padding-left: 0.5rem;
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.silver : theme.colors.alabaster)};
  }

  /* Remove default Arrows */
  /* Chrome, Safari, Edge, Opera */
  > input::-webkit-outer-spin-button,
  > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  > input[type='number'] {
    -moz-appearance: textfield;
  }
`

export const ArrowsContainer = styled.div<{ disabled?: boolean }>`
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  top: -5px;
  right: 5px;
  transform: scale(0.7);

  svg {
    fill: ${({ theme, disabled }) => (disabled ? theme.colors.warmGreyThree : theme.colors.tundora)};
  }
`

export const IconContainer = styled.div<{ onClick?: any; disabled?: boolean }>`
  cursor: pointer;

  svg {
    fill: ${({ theme, disabled }) => (disabled ? theme.colors.warmGreyThree : theme.colors.tundora)};
  }
`

type TInputNumber = {
  name: string
  label: string
  onChange?: (event: any) => void
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void
  value?: number
  onlyPositive?: boolean
  validationMsg?: string
  disabled?: boolean
  placeholder?: string
  withArrows?: boolean
  withoutDot?: boolean
  tiny?: boolean
  maxDigits?: number
  onBlur?: (e: any) => void
  helperText?: string
}

const FieldNumber: FC<TInputNumber> = ({
  value: providedValue,
  onChange,
  onlyPositive,
  label,
  setFieldValue,
  validationMsg,
  disabled,
  name,
  placeholder,
  withArrows,
  withoutDot,
  tiny,
  maxDigits,
  onBlur,
  helperText,
}) => {
  // could be number or empty ''
  const [value, setValue] = useState<number | string>(providedValue || '')

  const handleOnKeyDown = (event: any) => {
    if ((event.key === '.' || event.key === '-') && withoutDot) {
      event.preventDefault()
    }
  }

  const handleOnPaste = (event: any) => {
    const pasteData = event.clipboardData.getData('text')
    if (pasteData.includes('.') && withoutDot) {
      const finalData = pasteData.replace('.', '')
      setValue(finalData)
      if (onChange) onChange(event)
      event.preventDefault()
    }
  }

  const handleOnChange = (event: any) => {
    let inputValue = event.target.value
    if (onlyPositive) {
      if (inputValue < 0) return setValue(0)
    }
    if (maxDigits) {
      if (inputValue.toString().length > maxDigits) {
        inputValue = inputValue.substring(0, maxDigits)
      }
    }
    if (onChange && !maxDigits) onChange(event)
    return setValue(inputValue)
  }

  const handleOnPressArrows = (increment: boolean) => {
    if (!disabled) {
      let val: number = typeof value === 'string' ? 0 : value

      if (increment) {
        val += 1
      }
      if (!increment) {
        val -= 1
      }

      setValue(val)
    }

    return {}
  }

  useEffect(() => {
    if (setFieldValue) setFieldValue(name, value)
  }, [value])

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e)
  }

  return (
    <Basic
      name={name}
      label={label}
      validationMsg={validationMsg}
      helperText={helperText}
    >
      <FieldNumberContainer tiny={tiny}>
        <input
          name={name}
          aria-label={name}
          type="number"
          onChange={handleOnChange}
          value={providedValue}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={handleOnKeyDown}
          onPaste={handleOnPaste}
          onBlur={handleOnBlur}
        />
        {withArrows && (
          <ArrowsContainer disabled={disabled}>
            <IconContainer onClick={() => handleOnPressArrows(true)}>
              <KeyboardArrowUpIcon color="tundora" size="xxs" />
            </IconContainer>
            <IconContainer
              disabled={onlyPositive && value <= 0}
              onClick={() => (onlyPositive
                ? value >= 1 && handleOnPressArrows(false)
                : handleOnPressArrows(false))}
            >
              <KeyboardArrowDownIcon color="tundora" size="xxs" />
            </IconContainer>
          </ArrowsContainer>
        )}
      </FieldNumberContainer>
    </Basic>
  )
}

FieldNumber.defaultProps = {
  value: 0,
  disabled: false,
  validationMsg: '',
  onlyPositive: false,
  placeholder: '',
  withArrows: true,
  setFieldValue: () => {},
  withoutDot: false,
  onChange: () => {},
  tiny: false,
  maxDigits: 0,
  onBlur: () => {},
  helperText: '',
}

export default FieldNumber
