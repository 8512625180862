import React, { useState } from 'react'
import { openExternalLink } from '../../shared/helpers/utils'
import Spinner from '../../UI/Spinner'
import * as Icons from '../../UI/Icon'
import HeaderAdvisor from './HeaderAdvisor'
import { AdvisorContainer,
  ContactMedias,
  ContactMediasRow,
  SpinnerContainer,
  CustomButton,
  ContactText,
  ClickeableIcon } from './stylesAdvisor'
import { ILastQuote } from '../../../../modules/Pyme/domain'

interface IAdvisor {
  lastQuote: ILastQuote | null
}

const Advisor: React.FC<IAdvisor> = ({ lastQuote }) => {
  const [displayInfo, setDisplayInfo] = useState(false)
  const toggleInfo = () => setDisplayInfo(!displayInfo)
  const contactWspBasePath = 'https://api.whatsapp.com/send?phone=549'

  if (!lastQuote) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }
  const { Nombre, Instagram, Facebook, Mail, Telefono, Foto } = lastQuote.Asesor
  const onClickInstagram = () => openExternalLink(Instagram)
  const onClickFacebook = () => openExternalLink(Facebook)
  const onClickMail = () => openExternalLink(`mailto:${Mail}`)
  const onClickTelefono = () => openExternalLink(`${contactWspBasePath}${Telefono}`)
  return (
    <AdvisorContainer>
      <HeaderAdvisor foto={Foto} nombre={Nombre} />
      <CustomButton>
        {!displayInfo && <ContactText>Contactar</ContactText>}
        {displayInfo && (
          <ContactMedias>
            <ContactMediasRow>
              <ClickeableIcon
                onClick={() => (Telefono ? onClickTelefono() : '')}
              >
                <Icons.WhatsappIcon
                  color={Telefono ? 'chathamsBlue' : 'silver'}
                  isCursor={!!Telefono}
                />
              </ClickeableIcon>
              <ClickeableIcon
                onClick={() => (Facebook ? onClickFacebook() : '')}
              >
                <Icons.FacebookSquareIcon
                  color={Facebook ? 'chathamsBlue' : 'silver'}
                  isCursor={!!Facebook}
                />
              </ClickeableIcon>
            </ContactMediasRow>
            <ContactMediasRow>
              <ClickeableIcon
                onClick={() => (Instagram ? onClickInstagram() : '')}
              >
                <Icons.InstagramIcon
                  color={Instagram ? 'chathamsBlue' : 'silver'}
                  isCursor={!!Instagram}
                />
              </ClickeableIcon>
              <ClickeableIcon onClick={() => (Mail ? onClickMail() : '')}>
                <Icons.MailIcon
                  color={Mail ? 'chathamsBlue' : 'silver'}
                  isCursor={!!Mail}
                />
              </ClickeableIcon>
            </ContactMediasRow>
          </ContactMedias>
        )}
        {displayInfo ? (
          <ClickeableIcon onClick={toggleInfo}>
            <Icons.ChevronUpIcon color="chathamsBlue" isCursor />
          </ClickeableIcon>
        ) : (
          <ClickeableIcon onClick={toggleInfo}>
            <Icons.ChevronDownIcon color="chathamsBlue" isCursor />
          </ClickeableIcon>
        )}
      </CustomButton>
    </AdvisorContainer>
  )
}

export default Advisor
