import { IApi, TServerResponse } from '../../../app/domain/httpClient'
import { TFamilyGroupFormValues } from '../../../app/infraestructure/Pages/CompanyApplication/Steps/Step2/constants'
import constants from '../../../app/infraestructure/shared/constants'
import { getYearsFromDate } from '../../../app/infraestructure/shared/helpers/date'
import { EncodeCoveragePlans } from '../../../app/infraestructure/shared/helpers/encoders'
import { TFamilyGroup,
  TSaveFamilyGroup,
  TSocios } from '../../Pyme/domain/entity/saveFamilyGroupDto'
import { THoldersList,
  THoldersListByMemberTypeKeys,
  THoldersListByMemberTypeServerResponse } from '../domain/DTOs'
import { IFamilyGroupRepository } from '../domain/entities'

export default class FamilyGroupRepository implements IFamilyGroupRepository {
  readonly httpClient: IApi

  private holdersList: THoldersListByMemberTypeServerResponse | null

  constructor(httpClient: IApi) {
    this.httpClient = httpClient
    this.holdersList = null
  }

  async getHoldersList(): Promise<THoldersListByMemberTypeServerResponse> {
    this.holdersList = await this.httpClient.get<THoldersListByMemberTypeServerResponse>(
      '/GrupoFamiliar/listado',
    )

    return this.holdersList
  }

  getHoldersListByMemberType(
    memberType: THoldersListByMemberTypeKeys,
  ): THoldersList[] | null {
    return this.holdersList?.data[memberType] || null
  }

  // guid harcoded because back obtains it from login token
  async postHolderDjs(guid: string): Promise<TServerResponse<any>> {
    const response = await this.httpClient.post<TServerResponse<any>>(
      `/grupofamiliar/solicitardjs?guid=${guid}`,
      {},
    )
    return response
  }

  async saveFamilyGroups(
    param: TSaveFamilyGroup,
  ): Promise<TServerResponse<{ Message: string }>> {
    const response = await this.httpClient.post<
    Promise<TServerResponse<{ Message: string }>>
    >('/GrupoFamiliar/Guardar', param)

    return response
  }

  async getFamilysGroupDetail(guid: string): Promise<TFamilyGroupFormValues> {
    const { data } = await this.httpClient.get<TServerResponse<TFamilyGroup>>(
      '/GrupoFamiliar',
      { guid },
    )

    const holderData = data.Socios.find(
      (asociate: TSocios) => asociate.TipoSocio === constants.userRelated.asociateTypes.holder,
    ) as TSocios
    const coupleData = data.Socios.find(
      (asociate: TSocios) => asociate.TipoSocio === constants.userRelated.asociateTypes.spouse,
    )
    const childData = data.Socios.filter(
      (asociate: TSocios) => asociate.TipoSocio === constants.userRelated.asociateTypes.child,
    )
    return {
      holderId: holderData.Id,
      name: holderData.Nombre,
      lastName: holderData.Apellido,
      sex: holderData.Sexo >= 0 ? holderData.Sexo : -1,
      dni: parseInt(holderData.Dni, 10),
      cuil: parseInt(
        holderData.Cuil.replaceAll('-', '').replaceAll('"', ''),
        10,
      ),
      birthDay: new Date(holderData.FechaNacimiento),
      email: holderData.Mail,
      repeatEmail: holderData.Mail,
      age: getYearsFromDate(new Date(holderData.FechaNacimiento)),
      areaCode: holderData.TelefonoMovil.split('-')[0],
      phone: holderData.TelefonoMovil.split('-')[1],
      contributions: holderData.Aporte,
      street: data.Direccion.Calle,
      streetNumber: data.Direccion.Altura,
      floor: data.Direccion.Depto,
      province: data.Direccion.IdProvincia,
      location: data.Direccion.IdLocalidad ? data.Direccion.IdLocalidad : 0,
      postalCode: data.Direccion.CodigoPostal,
      includeCouple: !!coupleData,
      coupleId: coupleData?.Id || 0,
      coupleName: coupleData?.Nombre || '',
      coupleLastName: coupleData?.Apellido || '',
      coupleSex:
        typeof coupleData?.Sexo !== 'undefined' && coupleData.Sexo >= 0
          ? coupleData.Sexo
          : -1,
      coupleDni: coupleData?.Dni ? parseInt(coupleData.Dni, 10) : 0,
      coupleCuil: coupleData?.Cuil
        ? parseInt(coupleData?.Cuil.replaceAll('-', '').replaceAll('"', ''), 10)
        : 0,
      coupleBirthday: coupleData?.FechaNacimiento
        ? new Date(coupleData.FechaNacimiento)
        : new Date(),
      coupleAreaCode: coupleData?.TelefonoMovil.split('-')[0] || '',
      couplePhone: coupleData?.TelefonoMovil.split('-')[1] || '',
      coupleAge: coupleData?.FechaNacimiento
        ? getYearsFromDate(new Date(coupleData.FechaNacimiento))
        : 0,
      childsAmount: 0, // TODO: remove field, outdated
      childs: childData.map((asociate: TSocios) => ({
        childId: asociate.Id,
        name: asociate.Nombre,
        lastName: asociate.Apellido,
        age: getYearsFromDate(new Date(asociate.FechaNacimiento)),
        sex:
          typeof asociate?.Sexo !== 'undefined' && asociate.Sexo >= 0
            ? asociate.Sexo
            : -1,
        childDni: parseInt(asociate.Dni, 10),
        birthDay: new Date(asociate.FechaNacimiento),
      })),
      coveragePlans: EncodeCoveragePlans(data.IdPlan, data.CodPlan),
      pmo: data.Pmo,
    }
  }

  public async getFamilyGroupData(guid: string): Promise<TFamilyGroup> {
    const { data } = await this.httpClient.get<TServerResponse<TFamilyGroup>>(
      '/GrupoFamiliar',
      { guid },
    )

    return data
  }

  public async getIfAllDjsAreComplete(): Promise<
  TServerResponse<{ PuedeContinuar: boolean }>
  > {
    const response = await this.httpClient.get<
    TServerResponse<{ PuedeContinuar: boolean }>
    >('/ValidarDjsCompleta')

    return response
  }

  public async getCompletedHoldersData(guid: string): Promise<any> {
    const { data } = await this.httpClient.get<any>(
      '/DjsCompletaPorGrupoFamiliar',
      { guid },
    )

    return data
  }
}
