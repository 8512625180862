import axios, { AxiosError } from 'axios'

import { IApi } from '../../../domain/httpClient'
import constants from '../../shared/constants'

function isAxiosError(error: any): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined
}

class DevHttpClient implements IApi {
  http: any

  baseUrl: string | undefined

  // TODO: remove basePath undefined type
  constructor(
    token: string,
    basePath: string | undefined,
    logoutUser: () => void,
  ) {
    this.http = axios.create({
      baseURL: basePath,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (token) {
      this.http.defaults.headers.common.Authorization = `Bearer ${token}`
    }

    this.http.interceptors.response.use(
      (response: any) => {
        if (response.data.Token) {
          this.http.defaults.headers.common.Authorization = `Bearer ${response.data.Token}`
        }

        return response
      },
      (err: any) => {
        if (
          err.response?.status
          === constants.httpStatusCodes.HTTP_SESSION_EXPIRED
        ) {
          logoutUser()
        }

        throw err
      },
    )
  }

  public get(url: string, params?: any, responseType?: string) {
    return this.http({
      method: 'GET',
      url,
      params,
      responseType,
    })
  }

  public async post(url: string, params: any) {
    try {
      const response = await this.http({
        method: 'POST',
        url,
        data: params,
      })

      return response
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.isAxiosError) {
          return error.response
        }

        throw error
      }

      throw error
    }
  }

  public async put(url: string, params: any) {
    try {
      return this.http({
        method: 'PUT',
        url,
        data: params,
      })
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.isAxiosError) {
          return error.response
        }

        throw error
      }

      throw error
    }
  }
}

export default DevHttpClient
