import { IApi, TServerResponse } from '../../../../app/domain/httpClient'
import { LoginRepository,
  LoginParams,
  LoginResponse,
  RecoverPasswordParams,
  UpdatePasswordParams } from '../../domain'

type AuthLoginResponse = TServerResponse<LoginResponse>

export default class AuthRepo implements LoginRepository {
  private http: IApi

  constructor(httpClient: IApi) {
    this.http = httpClient
  }

  async loginUser(credentials: LoginParams): Promise<AuthLoginResponse> {
    const response = await this.http.post<AuthLoginResponse>(
      '/login',
      credentials,
    )

    return response
  }

  async updatePassword(params: UpdatePasswordParams): Promise<any> {
    const response = await this.http.post<any>('/CambioPassword', params)

    return response
  }

  async recoverPassword(credentials: RecoverPasswordParams): Promise<any> {
    const response = await this.http.post<any>(
      `/ResetPassword?email=${credentials.userName}`,
      credentials,
    )

    return response
  }
}
