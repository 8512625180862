import React, { useEffect, useState } from 'react'
import GetDataCoveragePlans from '../../../../../../../modules/Pyme/application/useCase/getDataCoveragePlans'
import { memberTypesPlural } from '../../../../../../../modules/commons/member'
import UI from '../../../../../UI'
import { DropDownValues } from '../../../../../UI/DropDown'
import holdersLocal from '../HolderLocalModule'
import { EncodeCoveragePlans } from '../../../../../shared/helpers/encoders'

const PlansCoverage = ({
  setFieldValue,
  error,
  pymeRepository,
  age,
  provId,
  value,
  isDisabled,
}: any) => {
  const [planes, setPlanes] = useState<any>(null)
  const [fetchError, setFetchError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const getCovergePlansUseCase = new GetDataCoveragePlans(pymeRepository)

  useEffect(() => {
    const getPlans = async () => {
      try {
        setIsLoading(true)

        const response = await getCovergePlansUseCase.run({
          idProvincia: provId,
          edad: age,
          tipoAfiliacion: memberTypesPlural[holdersLocal.getMemberType()],
        })

        setPlanes(response.data)
      } catch (catchError) {
        setFetchError(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (age && provId) {
      getPlans()
    }
  }, [age, provId])

  useEffect(() => {
    if (planes && value) {
      if (value === '-1') return

      if (value.toString().includes('-')) {
        const idPlanSelected = value?.split('-')[0]
        const codigoPlanSelected = value?.split('-')[1]
        const planSelected = (planes.PlanesDisponibles || []).find(
          (p: any) => p.Codigo === codigoPlanSelected
            && p.Id.toString() === idPlanSelected.toString(),
        )

        setFieldValue('pmo', planSelected?.PMO)
        setFieldValue(
          'coveragePlans',
          EncodeCoveragePlans(idPlanSelected, codigoPlanSelected),
        )
      }
    }
  }, [value, planes])

  return (
    <UI.DropDown
      value={value}
      name="coveragePlans"
      label="Plan elegido"
      id="coveragePlans"
      onChange={(option: DropDownValues) => {
        setFieldValue('coveragePlans', option.value)
      }}
      options={(planes ? planes.PlanesDisponibles : []).map(
        (p: any): DropDownValues => ({
          label: p.Nombre,
          value: EncodeCoveragePlans(p.Id, p.Codigo),
        }),
      )}
      validationMsg={error || fetchError}
      isLoading={isLoading}
      placeholder="Seleccioná el plan"
      isDisabled={isDisabled}
    />
  )
}

export default React.memo(PlansCoverage)
