import { useState } from 'react'

type TUseStepper = {
  data: string[]
  activeScreen?: number
}

export interface IHandleSetActiveIndex {
  prev: () => void
  next: () => void
}

const useStepper = (props: TUseStepper) => {
  const [itemsTitle, setItemsTitle] = useState(props.data)
  const [activeIndex, setActiveIndex] = useState(props.activeScreen || 0)

  const handleSetActiveIndex: () => IHandleSetActiveIndex = () => {
    const currentIndex = activeIndex

    return {
      prev: () => {
        const index = currentIndex === 0 ? currentIndex : currentIndex - 1

        setActiveIndex(index)
      },
      next: () => {
        const index = currentIndex + 1

        setActiveIndex(index)
      },
    }
  }

  return {
    activeIndex,
    handleSetActiveIndex,
    setItemsTitle,
    itemsTitle,
  }
}

export default useStepper
