import React, { useState, useEffect } from 'react'
import GetAllProvinciesUseCase from '../../../../../../../modules/Province/application/useCase/getAllProvincies'
import { Province } from '../../../../../../../modules/Province/domain/Province'
import ProvinceRepository from '../../../../../../../modules/Province/infraestructure/repositories/provinceRepository'
import UI from '../../../../../UI'
import { DropDownValues } from '../../../../../UI/DropDown'

type ProvinceDropDown = {
  provinceRepository: ProvinceRepository
  value: any
  error: string
  setFieldValue: (fieldName: string, value: any) => void
}

const ProvinciesDropDown = ({
  provinceRepository,
  value,
  error,
  setFieldValue,
}: ProvinceDropDown) => {
  const [provincies, setProvincies] = useState<Province[]>([])
  const [provErrorMsg, setProvErrorMsg] = useState('')
  const [provIsFetching, setProvIsFetching] = useState(false)

  const getAllProvinciesUseCase = new GetAllProvinciesUseCase(
    provinceRepository,
  )

  useEffect(() => {
    const getAllProvincies = async () => {
      try {
        setProvErrorMsg('')
        setProvIsFetching(true)
        const response = await getAllProvinciesUseCase.getAllProvincies()

        return setProvincies(response.data.provincias)
      } catch (err) {
        return setProvErrorMsg('Error en traer las provincias')
      } finally {
        setProvIsFetching(false)
      }
    }

    getAllProvincies()
  }, [])

  return (
    <UI.DropDown
      name="province"
      label="Provincia"
      id="province"
      value={value}
      onChange={(option: DropDownValues) => {
        setFieldValue('province', option.value)
        setFieldValue('location', -1)
      }}
      options={
        provincies?.map(
          (p: Province): DropDownValues => ({
            label: p.nombre,
            value: p.id,
          }),
        ) || [{ label: '', value: '' }]
      }
      validationMsg={error || provErrorMsg}
      isLoading={provIsFetching}
    />
  )
}

export default React.memo(ProvinciesDropDown)
