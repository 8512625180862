import React from 'react'
import styled from 'styled-components'
import UI from '../../../../UI'

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 56, 126, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36vw;
  padding: 48px 35px 28px;
  border-radius: 4px;
  gap: 20px;
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Separator = styled.div`
  height: 1.5rem;
`

const ConfirmCancelModalFinishRequest = ({
  onPressCancel,
  onPressConfirmCancel,
}: {
  onPressCancel: () => void
  onPressConfirmCancel: () => void
}) => (
  <Container>
    <ModalContainer>
      <UI.Text color="mariner" weight="mdS" size="lg">
        Atención
      </UI.Text>
      <BodyContainer>
        <UI.Text
          textAlign="center"
          size="mdLg"
          weight="sm"
          color="chathamsBlue"
        >
          Estás por enviar tu solicitud de alta.
        </UI.Text>
        <UI.Text
          textAlign="center"
          size="mdLg"
          weight="sm"
          color="chathamsBlue"
        >
          Si lo hacés, no podrás regresar a editar la información.
        </UI.Text>
        <Separator />
        <UI.Text
          textAlign="center"
          size="mdLg"
          weight="sm"
          color="chathamsBlue"
        >
          ¿Estás seguro de enviarla?
        </UI.Text>
      </BodyContainer>
      <ButtonsContainer>
        <UI.Button onClick={onPressConfirmCancel}>Confirmar</UI.Button>
        <UI.Button variant="outlined" onClick={onPressCancel}>
          Cancelar
        </UI.Button>
      </ButtonsContainer>
    </ModalContainer>
  </Container>
)

export default ConfirmCancelModalFinishRequest
