import React, { useState } from 'react'
import { Formik } from 'formik'

import UI from '../../UI'
import OMINT_LOGO from '../../shared/images/logo_omint_azul.svg'
import S from './styles'

import * as constants from './constants'
import { loginController } from './controller'
import AuthRepo from '../../../../modules/Login/infraestructure/repositories'

const {
  userNameField,
  userLabelTextField,
  passwordField,
  passwordLabelTextField,
  initialValues,
} = constants

const PageAuth = ({ loginRepository }: { loginRepository: AuthRepo }) => {
  const [recoverPass, setRecoverPass] = useState(false)
  const {
    handleOnPressSubmit,
    errorMsg,
    hasError,
    isLoading,
    handleOnRecoverPassword,
    setHasError,
  } = loginController({ loginRepository })
  const handleOnBlur = (values: any) => {
    const errors: {
      userName?: string
      password?: string
    } = {}

    if (!values[userNameField]) {
      errors[userNameField] = 'El usuario es requerido'
    }

    if (!values[passwordField]) errors[passwordField] = 'La clave es requerida'

    if (values[userNameField] && values[userNameField].length < 6) {
      errors[userNameField] = 'El usuario debe tener al menos 6 caracteres'
    }

    if (values[passwordField] && values[passwordField].length < 6) {
      errors[passwordField] = 'La password debe tener al menos 6 caracteres'
    }

    return errors
  }

  const handleResetPasswordOnBlur = (values: any) => {
    const errors: {
      userName?: string
    } = {}

    if (!values[userNameField]) {
      errors[userNameField] = 'El usuario es requerido'
    }

    if (values[userNameField] && values[userNameField].length < 6) {
      errors[userNameField] = 'El usuario debe tener al menos 6 caracteres'
    }

    return errors
  }

  const handleRecoverPasswordState = () => {
    setRecoverPass(!recoverPass)
    setHasError(false)
  }

  return (
    <S.ContainerPage>
      <S.LeftContent />
      <S.RightContent>
        {!recoverPass ? (
          <Formik
            initialValues={initialValues}
            validate={handleOnBlur}
            onSubmit={handleOnPressSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <S.Container>
                  <S.LogoOmint alt="omint" src={OMINT_LOGO} />
                  <UI.Text color="chathamsBlue" weight="md">
                    empresas
                  </UI.Text>
                </S.Container>
                <S.Container>
                  <UI.TextField
                    value={values.userName}
                    name={userNameField}
                    label={userLabelTextField}
                    validationMsg={
                      errors.userName && touched.userName ? errors.userName : ''
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Ingresá tu usuario"
                    disabled={isSubmitting}
                  />
                  <UI.TextField
                    value={values.password}
                    name={passwordField}
                    label={passwordLabelTextField}
                    validationMsg={
                      errors.password && touched.password ? errors.password : ''
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    password
                    placeholder="Ingresá tu clave"
                    disabled={isSubmitting}
                  />
                  <UI.Text
                    color="flatBlue"
                    weight="sm"
                    size="xs"
                    isCursor
                    underlined
                    onClick={() => {
                      setFieldValue('password', '')
                      handleRecoverPasswordState()
                    }}
                  >
                    Olvidé mi clave
                  </UI.Text>
                </S.Container>
                <p>{hasError && <UI.Text>{errorMsg}</UI.Text>}</p>
                <UI.Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={!!Object.values(errors).length || isSubmitting}
                >
                  Ingresar
                </UI.Button>
              </form>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={initialValues}
            validate={handleResetPasswordOnBlur}
            onSubmit={handleOnRecoverPassword}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <S.Container>
                  <S.LogoOmint alt="omint" src={OMINT_LOGO} />
                  <UI.Text color="chathamsBlue" weight="md">
                    empresas
                  </UI.Text>
                </S.Container>
                <UI.TextField
                  value={values.userName}
                  name={userNameField}
                  label={userLabelTextField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Ingresá tu usuario"
                  disabled={isSubmitting}
                  validationMsg={
                    errors.userName && touched.userName ? errors.userName : ''
                  }
                />
                <p>{hasError && <UI.Text>{errorMsg}</UI.Text>}</p>
                <UI.Button type="submit" isLoading={isLoading}>
                  Recuperar
                </UI.Button>
                <UI.Button
                  type="button"
                  variant="outlined"
                  onClick={handleRecoverPasswordState}
                >
                  Cancelar
                </UI.Button>
              </form>
            )}
          </Formik>
        )}
        <p>© 2019 - Omint S.A. de Servicios</p>
      </S.RightContent>
    </S.ContainerPage>
  )
}

export default PageAuth
