import React, { FC, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import styled from 'styled-components'
import { CloudUploadIcon } from './Icon'

import Basic from './FieldBasic'

type ImageFieldProps = {
  name: string
  id: string
  alt: string
  label: string
  onChange: any
  withPreview?: boolean
  validationMsg?: string
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
const Input = styled.input`
  display: none;
`

const resizeFile = (file: any) => new Promise((resolve, rejected) => {
  try {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri: any) => {
        resolve(uri)
      },
      'file',
      200,
      200,
    )
  } catch (err) {
    rejected(err)
  }
})

const ImageField: FC<ImageFieldProps> = ({
  name,
  id,
  alt,
  onChange,
  withPreview = false,
  label,
  validationMsg,
}) => {
  const [img, setImg] = useState<any>()

  const handleOnImgChange = async (e: any) => {
    try {
      const file = e.target.files[0]

      const image = await resizeFile(file)

      setImg(image)
      return onChange(name, file)
    } catch (err) {
      return false
    }
  }

  return (
    <Basic name={name} label={label} validationMsg={validationMsg}>
      <Container>
        <CloudUploadIcon />
        <Input
          width="100px"
          height="100px"
          aria-label="guardarLogo"
          type="file"
          id={id}
          alt={alt}
          name={name}
          onChange={handleOnImgChange}
        />
        {withPreview && img && (
          <img
            width="40px"
            height="40px"
            src={URL.createObjectURL(img)}
            alt="preview"
          />
        )}
      </Container>
    </Basic>
  )
}

ImageField.defaultProps = {
  withPreview: false,
  validationMsg: '',
}

export default ImageField
