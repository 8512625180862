import IDjsRepository from '../../domain/IDjsRepository'
import { TAddMemberDjsParams } from '../../domain/valueObjects'

export default class AddMemberDjs {
  private repository: IDjsRepository

  constructor(repository: IDjsRepository) {
    this.repository = repository
  }

  run(props: TAddMemberDjsParams): void {
    this.repository.addMemberDjs(props)
  }
}
