import { useState, useEffect } from 'react'
import shared from '../../../../app/infraestructure/shared'
import GetDataPyme from '../../application/useCase/getDataPyme'
import { IPymeRepository } from '../../domain'

const {
  constants: {
    httpStatusCodes: {
      HTTP_INVALID_CREDENTIALS_STATUS,
      HTTP_UNEXPECTED_ERROR_STATUS,
    },
  },
} = shared

const confirmDataController = ({
  handleSetActiveIndex,
  pymeRepository,
}: {
  handleSetActiveIndex: any
  pymeRepository: IPymeRepository
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [dataPyme, setDataPyme] = useState<any>(null)
  const [errorMsg, setErrorMsg] = useState('')

  const getDataPymeUseCase = new GetDataPyme(pymeRepository)

  useEffect(() => {
    async function getData() {
      try {
        setIsFetching(true)
        const response = await getDataPymeUseCase.getDataPyme()

        if (response.status === HTTP_INVALID_CREDENTIALS_STATUS) {
          return setErrorMsg('No esta autorizado')
        }

        if (response.status === HTTP_UNEXPECTED_ERROR_STATUS) {
          return setErrorMsg('Se ha producido un error inesperado')
        }

        return setDataPyme(response.data)
      } catch (error) {
        return setErrorMsg('Error inesperado')
      } finally {
        setIsFetching(false)
      }
    }

    getData()
  }, [])

  const handleOnPressSubmit = () => handleSetActiveIndex().next()

  return {
    dataPyme,
    errorMsg,
    handleOnPressSubmit,
    isFetching,
  }
}

export default confirmDataController
