import { css } from 'styled-components'

import theme from '../shared/styles/theme'
import { colors } from '../shared/styles/colors'

export const fontSizes: { [keys: string]: any } = Object.keys(
  theme.fontSizes,
).reduce(
  (acc, curr) => Object.assign(acc, {
    [curr]: css`
        font-size: ${() => theme.fontSizes[curr]};
      `,
  }),
  {},
)
export const colorsProvider: { [keys: string]: any } = Object.keys(
  colors,
).reduce(
  (acc, curr) => Object.assign(acc, {
    [curr]: css`
        color: ${() => colors[curr]};
      `,
  }),
  {},
)
export const fontWeightsProvider: { [keys: string]: any } = Object.keys(
  theme.fontWeights,
).reduce(
  (acc, curr) => Object.assign(acc, {
    [curr]: css`
        font-weight: ${() => theme.fontWeights[curr]};
      `,
  }),
  {},
)
export const backgroundColorProvider: { [keys: string]: any } = Object.keys(
  colors,
).reduce(
  (acc, curr) => Object.assign(acc, {
    [curr]: css`
        background-color: ${() => colors[curr]};
      `,
  }),
  {},
)
