import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { TfamilyGroupOmitPmoCodePlan,
  TSocios } from '../../../../modules/Pyme/domain/entity/saveFamilyGroupDto'
import UI from '../../UI'
import { initialValues } from './constants'
import validationSchema from './validationSchema'
import AddMemberDjs from '../../../../modules/Djs/application/useCases/addMemberDjs'
import IDjsRepository from '../../../../modules/Djs/domain/IDjsRepository'
import { TDjsForm } from '../../../../modules/Djs/domain/valueObjects'
import GetMemberDjsByDni from '../../../../modules/Djs/application/useCases/getMemberDjsByDni'
import GetQuestionsByMemberTypeId from '../../../../modules/Questions/application/useCase/getQuestionsByMemberTypeId'
import IQuestionRepository from '../../../../modules/Questions/domain/QuestionRepository'
import IQuestion from '../../../../modules/Questions/domain/entity/Question'
import Answer from '../../../../modules/Djs/domain/entities/Answer'
import S from './styles'
import OMINT_LOGO from '../../shared/images/logo_omint_blanco.svg'
import SaveAllMembersDjs from '../../../../modules/Djs/application/useCases/saveAllMembersDjs'
import SuccessScreen from './SuccessScreen'
import constants from '../../shared/constants'
import DJSGuardScreen from './DjsGuard'

const savedOnHold = 0
const savedSuccessfully = 1
const savedFail = 2

const getDataFromMember = (data: TfamilyGroupOmitPmoCodePlan) => (memberIndex: number): TSocios => data.Socios[memberIndex]
const isLastMember = (memberIndex: number, data: TfamilyGroupOmitPmoCodePlan) => memberIndex === data.Socios.length - 1

const DJsScreen = ({
  data,
  djsRepository,
  questionRepository,
  guid,
}: {
  data: TfamilyGroupOmitPmoCodePlan
  djsRepository: IDjsRepository
  questionRepository: IQuestionRepository
  guid: string
}) => {
  const [memberIndex, setMemberIndex] = useState(0)
  const [questions, setQuestions] = useState<IQuestion[]>()
  const [formInitialValues, setFormInitialValues] = useState<
  Answer | undefined
  >()
  const [djsSaved, setDjsSaved] = useState(savedOnHold)
  const { Dni, TipoSocio, Nombre, Apellido } = getDataFromMember(data)(memberIndex)

  // loading
  const [questionsIsFetching, setQuestionsIsFetching] = useState(false)
  const [saveDjsIsFetching, setSaveDjsIsFetching] = useState(false)

  // errorMsgs
  const [errorMsgFetchingQuestions, setErrorMsgFetchingQuestions] = useState('')

  // useCases
  const addMemberDjsUseCase = new AddMemberDjs(djsRepository)
  const getMemberDjsByDni = new GetMemberDjsByDni(djsRepository)
  const getQuestionsByMemberTypeId = new GetQuestionsByMemberTypeId(
    questionRepository,
  )
  const saveAllMembersDjs = new SaveAllMembersDjs(djsRepository)

  const handleOnSubmitDjs = async () => {
    try {
      setDjsSaved(savedOnHold)
      setSaveDjsIsFetching(true)
      const response = await saveAllMembersDjs.run(guid)

      if (response.status === constants.httpStatusCodes.HTTP_OK_STATUS) {
        return setDjsSaved(savedSuccessfully)
      }

      return setDjsSaved(savedFail)
    } catch (err) {
      return setDjsSaved(savedFail)
    } finally {
      setSaveDjsIsFetching(false)
    }
  }

  const handleOnNextMember = (formValues: TDjsForm) => {
    const { altura, peso, ...rest } = formValues

    addMemberDjsUseCase.run({
      dni: parseInt(Dni, 10),
      altura,
      peso,
      preguntas: { ...rest },
      memberTypeId: TipoSocio,
      nombre: Nombre,
      apellido: Apellido,
    })

    if (memberIndex < data.Socios.length - 1) {
      return setMemberIndex((index) => index + 1)
    }

    return handleOnSubmitDjs()
  }

  const handleOnPrevMember = (formValues: TDjsForm) => {
    const { altura, peso, ...rest } = formValues

    addMemberDjsUseCase.run({
      dni: parseInt(Dni, 10),
      altura,
      peso,
      preguntas: { ...rest },
      memberTypeId: TipoSocio,
      nombre: Nombre,
      apellido: Apellido,
    })

    if (memberIndex > 0) setMemberIndex((index) => index - 1)
  }

  useEffect(() => {
    const getQuestions = async () => {
      try {
        setQuestionsIsFetching(true)
        setErrorMsgFetchingQuestions('')
        const response = await getQuestionsByMemberTypeId.run(
          getDataFromMember(data)(memberIndex).TipoSocio,
        )

        setQuestions(response)
      } catch (error) {
        setErrorMsgFetchingQuestions(
          'Se produjo un error y no se pudo obtener el listado de preguntas',
        )
      } finally {
        setQuestionsIsFetching(false)
      }
    }

    getQuestions()
  }, [memberIndex])

  useEffect(() => {
    setFormInitialValues(
      getMemberDjsByDni.run(
        parseInt(getDataFromMember(data)(memberIndex).Dni, 10),
      ),
    )
  }, [memberIndex])

  return (
    <S.PageContainer>
      <S.HeaderContainer>
        <S.PymesLogo src={OMINT_LOGO} />
        <UI.Text color="white">|</UI.Text>
        <UI.Text color="white">Empresas</UI.Text>
      </S.HeaderContainer>
      {saveDjsIsFetching ? (
        <UI.Spinner />
      ) : (
        <DJSGuardScreen step={data.Paso}>
          <>
            {djsSaved === savedSuccessfully && <SuccessScreen />}
            {djsSaved === savedFail && <SuccessScreen />}
            {djsSaved === savedOnHold && (
              <>
                <UI.Text size="lg" weight="md" color="flatBlue">
                  Completá la declaración jurada de salud de cada integrante de
                  tu grupo familiar
                </UI.Text>
                <UI.Text size="lg" weight="md" color="chathamsBlue">
                  {`${getDataFromMember(data)(memberIndex).Apellido} , ${
                    getDataFromMember(data)(memberIndex).Nombre
                  }`}
                </UI.Text>
                <Formik
                  initialValues={initialValues(formInitialValues)}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm }) => {
                    handleOnNextMember(values)
                    if (!isLastMember(memberIndex, data)) resetForm()
                  }}
                  enableReinitialize
                >
                  {({
                    handleSubmit,
                    values,
                    errors,
                    setFieldValue,
                    isValid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {errorMsgFetchingQuestions && (
                        <p>{errorMsgFetchingQuestions}</p>
                      )}
                      {questionsIsFetching ? (
                        <p>cargando las preguntas...</p>
                      ) : (
                        questions?.map((q, i) => (
                          <div key={q.Id}>
                            <S.Row>
                              <UI.Text size="sm" color="chathamsBlue">
                                {q.Pregunta}
                              </UI.Text>
                              <UI.ToggleSwitch
                                id={q.Id.toString()}
                                value={values[q.Id]}
                                onChange={(v: boolean) => setFieldValue(q.Id.toString(), v)}
                              />
                            </S.Row>
                            {values[q.Id] && q.Opciones.length ? (
                              <S.OptionsContainer>
                                {q.Opciones.map((o) => (
                                  <S.OptionsRow key={o.ID}>
                                    {o.Opcion}
                                    <UI.ToggleSwitch
                                      id={`${q.Id}${o.ID}`}
                                      value={values[q.Id][o.ID]}
                                      onChange={(v: boolean) => setFieldValue(q.Id.toString(), {
                                        ...values[q.Id],
                                        [o.ID]: v,
                                      })}
                                    />
                                  </S.OptionsRow>
                                ))}
                              </S.OptionsContainer>
                            ) : null}
                            {i !== questions.length - 1 && <hr />}
                          </div>
                        ))
                      )}
                      <S.ButtonsContainer>
                        <UI.MaskInput
                          name="altura"
                          value={values.altura.toString()}
                          mask="0,00 mts"
                          setFieldValue={setFieldValue}
                          label="Altura (en mts)"
                          validationMsg={errors.altura}
                        />
                        <UI.MaskInput
                          name="peso"
                          value={values.peso.toString()}
                          mask="000 k"
                          setFieldValue={setFieldValue}
                          label="Peso (en Kilo)"
                          validationMsg={errors.peso}
                        />
                      </S.ButtonsContainer>
                      <S.ButtonsContainer>
                        {memberIndex !== 0 && (
                          <UI.Button
                            onClick={() => handleOnPrevMember(values)}
                            variant="outlined"
                          >
                            Anterior
                          </UI.Button>
                        )}
                        <UI.Button type="submit" disabled={!isValid}>
                          {isLastMember(memberIndex, data)
                            ? 'Finalizar'
                            : 'Continuar'}
                        </UI.Button>
                      </S.ButtonsContainer>
                    </form>
                  )}
                </Formik>
              </>
            )}
          </>
        </DJSGuardScreen>
      )}
    </S.PageContainer>
  )
}

export default DJsScreen
