import styled from 'styled-components'

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.8rem;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
`

export const WarningMessage = styled.div`
  display: flex;
  gap: 0.54rem;
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.5rem;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.dawnPink};
  margin-bottom: 1rem;
`

export const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
`
