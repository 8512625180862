const placeholders = {
  complete: 'ingresar',
  select: 'seleccionar',
  email: 'Ej: mail@mail.com.ar',
  repeatEmail: 'Vuelve a ingrear el email',
  contributions: 'Ingresá el valor en pesos',
}

const validationMsg = {
  fieldRequired: 'El campo es requerido',
  emailInvalid: 'Email no valido',
  ageMustBeGratherThanCustom: (age: number) => `La edad debe ser mayor a ${age}`,
  ageMustBeLowerThanCustom: (age: number) => `La edad debe ser menor a ${age}`,
  provinceMustBeSelected: 'Debes seleccionar una provincia',
  sexMustBeChoosed: 'Debe seleccionar un sexo',
  cuilMustBeCompleted: 'Debe completar el cuil',
  birthDayCouldNotBeGratherThanToday:
    'La fecha de nacimiento no puede ser mayor a hoy',
  contributionsCouldNotBeLessThanCustom: (contribution: number) => `Los aportes no pueden ser menor a ${contribution}`,
  customAtLeastFieldNameAndCharacterLength: (
    fieldName: string,
    length: number,
  ) => `El ${fieldName} debe tener al menos ${length} caracteres`,
  customAtMaxFieldNameAndCharacterLength: (fieldName: string, length: number) => `El ${fieldName} debe tener máximo ${length} caracteres`,
  fieldOnlyLetters: 'El campo solo acepta letras',
}

const validationRegex = {
  onlyLetters: /^[A-Za-z\u00f1\u00d1 ]+$/g,
}

export default {
  placeholders,
  validationMsg,
  validationRegex,
}
