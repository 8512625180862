import React, { useContext, useState } from 'react'
import { TextBasic } from '../../UI/Text'
import { ButtonSummaryContainer,
  PlanSummaryContainer,
  TextContainer } from './stylesAdvisor'
import { ILastQuote, IPymeRepository } from '../../../../modules/Pyme/domain'
import { numbers } from '../../shared/helpers'
import { WarningMessage,
  MainContainer,
  BodyContainer,
  Paragraph } from './stylesPlanSummary'
import Button from '../../UI/Button'
import { ErrorWarningIcon } from '../../UI/Icon'
import SpinnerBasic from '../../UI/Spinner'
import stores from '../../stores'
import RequoteCardController from './RequoteCardController'

const PlanSummaryCard = ({
  pymeRepository,
  showButton,
  showCard,
  lastQuote,
}: {
  pymeRepository: IPymeRepository
  showButton: boolean
  showCard: boolean
  lastQuote: ILastQuote | null
}) => {
  const {
    handleRequoteButton,
    pymeRequoteData,
    pymeRequoteIsFetching,
    hasPymeRequoteError,
  } = RequoteCardController({
    pymeRepository,
  })

  const contextRequote = useContext(stores.PymeRequoteContext)

  const [date, setDate] = useState({
    dmy: lastQuote?.FechaUltimaCotizacion
      ? lastQuote?.FechaUltimaCotizacion
      : '',
    time: lastQuote?.HoraUltimaCotizacion
      ? lastQuote?.HoraUltimaCotizacion
      : '',
  })

  const simpleRequote = (value: any) => {
    if (value) {
      return `$${numbers.numberWithSeparators({
        number: value,
        withThousandSeparator: true,
      })}`
    }
    return '$0'
  }

  const recentRequoteDate = new Date()
  const price: any = pymeRequoteData

  const getActualDate = () => {
    setDate({
      dmy: recentRequoteDate.toLocaleDateString(),
      time: recentRequoteDate.toLocaleTimeString(),
    })
  }

  const handleOnPressUpdate = async () => {
    handleRequoteButton()
    getActualDate()
  }

  return (
    <PlanSummaryContainer displayElement={showCard}>
      {!hasPymeRequoteError && !pymeRequoteIsFetching && (
        <>
          <TextContainer>
            <TextContainer>
              <TextBasic size="md" weight="lg">
                Cotización
              </TextBasic>
              <TextBasic size="md" weight="mdS">
                {price?.Total
                  ? simpleRequote(price?.Total)
                  : simpleRequote(contextRequote?.total)}
              </TextBasic>
              <TextBasic size="sm" weight="sm">
                {date.dmy
                  ? `Ultima actualización: ${date.dmy}`
                  : `Última actualización: ${contextRequote?.date.dmy}`}
              </TextBasic>
              <TextBasic size="sm" weight="sm">
                {date.time
                  ? `Horario: ${date.time}`
                  : `Horario: ${contextRequote?.date.time}`}
              </TextBasic>
            </TextContainer>

            <TextBasic size="sm" weight="md">
              Valores aproximados
            </TextBasic>
            <Paragraph>
              <TextBasic size="sm" weight="sm">
                El valor mostrado no contempla
              </TextBasic>
              <TextBasic size="sm" weight="sm">
                grupos familiares incompletos
              </TextBasic>
            </Paragraph>
          </TextContainer>
          {showButton && (
            <ButtonSummaryContainer>
              <Button onClick={handleOnPressUpdate}>Actualizar</Button>
            </ButtonSummaryContainer>
          )}
        </>
      )}

      {pymeRequoteIsFetching && (
        <MainContainer>
          <TextBasic weight="mdS" size="md">
            Cotización en curso
          </TextBasic>
          <BodyContainer>
            <TextBasic color="chathamsBlue">Esto puede</TextBasic>
            <TextBasic color="chathamsBlue">demorar un poco</TextBasic>
          </BodyContainer>
          <SpinnerBasic />
        </MainContainer>
      )}

      {hasPymeRequoteError && !pymeRequoteIsFetching && (
        <MainContainer>
          <WarningMessage>
            <ErrorWarningIcon color="california" size="lg" />
          </WarningMessage>
          <TextBasic color="mariner" weight="mdS" size="md">
            Ocurrió un error
          </TextBasic>
          <BodyContainer>
            <TextBasic color="chathamsBlue">Por favor reintentá</TextBasic>
            <TextBasic color="chathamsBlue">más tarde</TextBasic>
          </BodyContainer>
          <ButtonSummaryContainer>
            <Button onClick={handleOnPressUpdate}>Reintentar</Button>
          </ButtonSummaryContainer>
        </MainContainer>
      )}
    </PlanSummaryContainer>
  )
}

export default PlanSummaryCard
