import React, { useEffect, useState } from 'react'
import GetPymeSummary from '../../../../modules/Pyme/application/useCase/getPymeSummary'
import { IPymeRepository } from '../../../../modules/Pyme/domain'
import { SummaryPymeApplication } from '../../../../modules/Pyme/application/useCase/getPymeSummary/Summary'
import { mappers, numbers } from '../../shared/helpers'
import UI from '../../UI'
import * as S from './styles'
import { PaymentType } from '../../../../modules/Pyme/application/useCase/savePaymentMethod/PaymentType'

const SummaryModal = ({
  pymeRepository,
  onCloseModal,
}: {
  pymeRepository: IPymeRepository
  onCloseModal: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [summaryData, setSummaryData] = useState<null | SummaryPymeApplication>(
    null,
  )
  const [error, setError] = useState('')
  const getPymeSummaryUseCase = new GetPymeSummary(pymeRepository)

  useEffect(() => {
    const getSumary = async () => {
      try {
        setError('')
        setIsLoading(true)
        const response = await getPymeSummaryUseCase.run()

        setSummaryData(response.data)
      } catch (err) {
        setError('Se produjo un error')
      } finally {
        setIsLoading(false)
      }
    }

    getSumary()
  }, [])

  const pymePayments = () => (
    <S.RowSplitted>
      <UI.Text weight="mdS">CBU</UI.Text>
      <UI.Text>{summaryData?.MedioPago.InfoExtra || ''}</UI.Text>
    </S.RowSplitted>
  )

  const familyGroupData = () => {
    const data = [
      {
        label: 'Relación de Dependencia',
        value: summaryData?.Pyme.Asignaciones[0].Empleados,
      },
      {
        label: 'Monotributistas',
        value: summaryData?.Pyme.Asignaciones[1].Empleados,
      },
      {
        label: 'Particulares',
        value: summaryData?.Pyme.Asignaciones[2].Empleados,
      },
    ]

    return data.map(({ label, value }: any) => (
      <S.RowSplitted key={label}>
        <UI.Text weight="mdS">{label}</UI.Text>
        <UI.Text>{value}</UI.Text>
      </S.RowSplitted>
    ))
  }

  const pymeAmountPaymentData = () => {
    let key = 0
    const plans: { label: number; value: string }[] | undefined = summaryData?.Pyme.Planes.map(({ Cantidad, Nombre }: any) => {
      const amountAndPlanName = {
        label: (key += 1),
        value: `${Cantidad} ${Nombre}`,
      }

      return amountAndPlanName
    })

    return (
      <>
        <S.RowSplitted>
          <UI.Text weight="mdS">Valor de cotización</UI.Text>
          <UI.Text>
            {`$ ${
              summaryData?.Pyme.ValorCotizacion
                ? `${numbers.numberWithSeparators({
                  number: summaryData.Pyme.ValorCotizacion,
                  withThousandSeparator: true,
                })}`
                : ''
            }`}
          </UI.Text>
        </S.RowSplitted>
        <S.RowSplitted>
          <UI.Text weight="mdS">Planes</UI.Text>
          <div>
            {plans?.map((plan) => (
              <UI.Text key={plan.label}>{plan.value}</UI.Text>
            ))}
          </div>
        </S.RowSplitted>
      </>
    )
  }

  const pymeInfoData = () => {
    const data = [
      { label: 'Razón Social', value: summaryData?.Pyme.RazonSocial },
      { label: 'CUIT', value: summaryData?.Pyme.CUIT },
      { label: 'Mail', value: summaryData?.Pyme.Mail },
      { label: 'Teléfono', value: summaryData?.Pyme.Telefono },
      { label: 'Provincia', value: summaryData?.Pyme.Provincia },
      { label: 'Localidad', value: summaryData?.Pyme.Localidad },
    ]

    return data.map(({ label, value }: any) => (
      <S.RowSplitted key={label}>
        <UI.Text weight="mdS">{label}</UI.Text>
        <UI.Text>{value}</UI.Text>
      </S.RowSplitted>
    ))
  }

  return (
    <S.ModalContainer>
      {error && <UI.Text>{error}</UI.Text>}
      {isLoading && <UI.Spinner />}
      {summaryData && (
        <S.ModalContent>
          <UI.Text weight="mdS" color="mariner" size="md">
            Detalle de la solicitud
          </UI.Text>
          <S.RowSplittedEqually>
            <S.Content>
              <UI.Text weight="mdS" size="md" color="chathamsBlue">
                Cantidad de grupos familiares
              </UI.Text>
              <hr />
              {familyGroupData()}
            </S.Content>
            <S.Content>
              <UI.Text weight="mdS" size="md" color="chathamsBlue">
                Monto a pagar
              </UI.Text>
              <hr />
              {pymeAmountPaymentData()}
            </S.Content>
          </S.RowSplittedEqually>
          <S.RowSplittedEqually>
            <S.Content>
              <UI.Text weight="mdS" size="md" color="chathamsBlue">
                Datos de facturación
              </UI.Text>
              <hr />
              {pymeInfoData()}
            </S.Content>
            <S.Content>
              <UI.Text weight="mdS" size="md" color="chathamsBlue">
                Método de pago
              </UI.Text>
              <hr />
              <S.PaymentTypeAlign>
                <UI.Text weight="mdS">Tipo</UI.Text>
                <UI.Text>
                  {mappers.paymentTypeToString(summaryData?.MedioPago.TipoPago)}
                </UI.Text>
              </S.PaymentTypeAlign>
              {summaryData?.MedioPago.TipoPago === PaymentType.debit ? (
                <S.RowSplittedEqually>{pymePayments()}</S.RowSplittedEqually>
              ) : null}
            </S.Content>
          </S.RowSplittedEqually>
          <S.ButtonContainer>
            <UI.Button onClick={onCloseModal} variant="outlined">
              Cerrar
            </UI.Button>
          </S.ButtonContainer>
        </S.ModalContent>
      )}
    </S.ModalContainer>
  )
}

export default SummaryModal
