import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import UI from '../../UI'
import { CardBasic } from '../../UI/Card'
import { CheckCircleIcon } from '../../UI/Icon'

export const SuccessCard = styled(CardBasic)`
  width: 550px;
  margin: 100px auto;
`

export const SuccessContent = styled.div`
  padding: 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > :first-child {
    background-color: #e7efde;
    border-radius: 24px;
    padding: 10px;
  }
  > :nth-child(2) {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  > :last-child {
    padding-top: 1rem;
  }
`

export const SuccessMessage: FC<{
  children: ReactNode
}> = ({ children }) => (
  <SuccessCard>
    <SuccessContent>{children}</SuccessContent>
  </SuccessCard>
)

const SuccessScreen = () => (
  <SuccessMessage>
    <CheckCircleIcon color="surfieGreen" />
    <UI.Text color="mariner" size="mdLg" weight="mdS">
      ¡Felicitaciones!
    </UI.Text>
    <UI.Text color="chathamsBlue">
      Completaste los datos de tu grupo familiar correctamente.
    </UI.Text>
    <UI.Text color="greyishBrownTwo" size="sm">
      En caso de necesitar información adicional o detectar algún inconveniente
      en los datos cargados, tu solicitud será devuelta para ser modificada.
    </UI.Text>
  </SuccessMessage>
)

export default SuccessScreen
