import React from 'react'
import Text from '../../UI/Text'
import { Grow,
  Title,
  AvatarContainer,
  OmintAvatar,
  Avatar } from './stylesAdvisor'

interface IHeader {
  foto: string
  nombre: string
}

const HeaderAdvisor: React.FC<IHeader> = ({ foto, nombre }) => (
  <Grow>
    <Title>Mi asesor</Title>
    <AvatarContainer foto={foto}>
      {foto ? <Avatar src={foto} /> : <OmintAvatar />}
    </AvatarContainer>
    <Text color="mariner" weight="mdS">
      {nombre}
    </Text>
  </Grow>
)

export default HeaderAdvisor
