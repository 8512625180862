import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import UI from '../../../../UI'
import * as S from '../Step2/styles'
import CompanyInfo from './CompanyInfo'
import saveOrEditCompanyDataController from './controller'
import { IHandleSetActiveIndex } from '../../../../hooks/useStepper'
import { CancelModalContent,
  ConfirmCancelModalContent,
  ContinueWithoutSaving } from './ModalContent'
import { IPymeRepository } from '../../../../../../modules/Pyme/domain'
import ProvinceRepository from '../../../../../../modules/Province/infraestructure/repositories/provinceRepository'

const CompanyApplication = ({
  handleSetActiveIndex,
  pymeRepository,
  provinceRepository,
}: {
  handleSetActiveIndex: () => IHandleSetActiveIndex
  pymeRepository: IPymeRepository
  provinceRepository: ProvinceRepository
}) => {
  const {
    errorMsgOnFetchProvincies,
    provincies,
    isFetching,
    handleOnSaveCompanyData,
    companyDataSaved,
    errorMsgOnPutCompanyData,
    companyDataIsFetching,
    newChangesSaved,
    pymeData,
    pymeDataIsFetching,
    errorMsgOnFetchPymeData,
    msgOnSavePyme,
    handleOnCloseSuccessSave,
    handleNewChanges,
  } = saveOrEditCompanyDataController({ pymeRepository, provinceRepository })

  const { handleDisplayModal, handleModalContent } = useContext(
    UI.Modal.ModalContext,
  )
  const history = useHistory()

  const handleConfirmCancel = () => {
    handleDisplayModal(false)
    history.goBack()
  }

  const handleOnPressConfirmCancel = () => {
    handleModalContent(
      <ConfirmCancelModalContent
        onPressCancel={() => handleDisplayModal(false)}
        onPressConfirmCancel={handleConfirmCancel}
      />,
    )
  }

  const handleOnPressCancel = () => {
    handleDisplayModal(true)
    handleModalContent(
      <CancelModalContent
        onPressGoBack={() => handleDisplayModal(false)}
        onPressGoOutWithoutSave={handleOnPressConfirmCancel}
      />,
    )
  }

  const handleContinue = () => {
    handleDisplayModal(true)
    handleModalContent(
      <ContinueWithoutSaving
        onPressContinue={() => {
          handleSetActiveIndex().next()
          handleDisplayModal(false)
        }}
        onPressConfirm={() => {
          handleDisplayModal(false)
        }}
      />,
    )
  }

  return (
    <UI.Cards.Card>
      <UI.LoadingWrapper isLoading={pymeDataIsFetching}>
        {pymeData ? (
          <CompanyInfo
            provincies={provincies}
            provinciesIsFetching={isFetching}
            companyDataSaved={companyDataSaved}
            newChangesSaved={newChangesSaved}
            errorMsgOnFetchProvincies={errorMsgOnFetchProvincies}
            handleOnSaveCompanyData={handleOnSaveCompanyData}
            handleSetActiveIndex={handleSetActiveIndex}
            errorMsgOnPutCompanyData={errorMsgOnPutCompanyData}
            companyDataIsFetching={companyDataIsFetching}
            onPressCancel={handleOnPressCancel}
            pymeData={pymeData}
            errorMsgOnFetchPymeData={errorMsgOnFetchPymeData}
            handleContinue={handleContinue}
            handleNewChanges={handleNewChanges}
          />
        ) : (
          'sin datos de la empresa'
        )}
      </UI.LoadingWrapper>
      {msgOnSavePyme.msg && (
        <S.MessageContainer>
          <UI.Message
            variant={msgOnSavePyme.error ? 'error' : 'success'}
            onClose={handleOnCloseSuccessSave}
          >
            {msgOnSavePyme.msg}
          </UI.Message>
        </S.MessageContainer>
      )}
    </UI.Cards.Card>
  )
}

export default CompanyApplication
