const HTTP_UNEXPECTED_ERROR_STATUS = 500
const HTTP_INVALID_CREDENTIALS_STATUS = 401
const HTTP_OK_STATUS = 200
const HTTP_SESSION_EXPIRED = 403
const HTTP_BAD_REQUEST = 400
const HTTP_OK_NO_CONTENT = 204
const HTTP_NON_AUTHORITATIVE_INFORMATION = 203

export default {
  HTTP_UNEXPECTED_ERROR_STATUS,
  HTTP_INVALID_CREDENTIALS_STATUS,
  HTTP_OK_STATUS,
  HTTP_SESSION_EXPIRED,
  HTTP_BAD_REQUEST,
  HTTP_OK_NO_CONTENT,
  HTTP_NON_AUTHORITATIVE_INFORMATION,
}
