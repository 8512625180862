import { IPymeRepository, TLastQuoteResponse } from '../../../domain'

export default class GetLastQuote {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async getLastQuote(cached?: boolean): Promise<TLastQuoteResponse> {
    return this.repository.getLastQuote(cached)
  }
}
