import { useState } from 'react'

export default () => {
  const [displayModal, setDisplayModal] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [modalIsFetching, setModalIsFetching] = useState(false)
  const [error, setError] = useState('')

  const handleDisplayModal = (display: boolean) => setDisplayModal(display)
  const handleModalContent = (content: any) => setModalContent(content)
  const handleOnModalIsFetching = (isFetching: boolean) => setModalIsFetching(isFetching)
  const handleOnModalError = (modalError: any) => setError(modalError)

  return {
    displayModal,
    handleDisplayModal,
    handleModalContent,
    modalContent,
    modalIsFetching,
    handleOnModalIsFetching,
    error,
    handleOnModalError,
  }
}
