import { IApi } from '../../../domain/httpClient'
import DevHttpClient from './development'

const { REACT_APP_BACKOFFICE_PYME_API, REACT_APP_VENTA_API } = process.env

const http = ({
  token,
  logoutUser,
}: {
  token: string
  logoutUser: () => void
}): {
  portalPymeApi: IApi
  ventaDigitalApi: IApi
  publicApi: IApi
} => {
  if (process.env.REACT_APP_ENV === 'development') {
    return {
      portalPymeApi: new DevHttpClient(
        token,
        REACT_APP_BACKOFFICE_PYME_API,
        logoutUser,
      ),
      ventaDigitalApi: new DevHttpClient(
        token,
        REACT_APP_VENTA_API,
        logoutUser,
      ),
      publicApi: new DevHttpClient(
        token,
        REACT_APP_BACKOFFICE_PYME_API,
        logoutUser,
      ),
    }
  }

  return {
    portalPymeApi: new DevHttpClient(
      token,
      REACT_APP_BACKOFFICE_PYME_API,
      logoutUser,
    ),
    ventaDigitalApi: new DevHttpClient(token, REACT_APP_VENTA_API, logoutUser),
    publicApi: new DevHttpClient(
      token,
      REACT_APP_BACKOFFICE_PYME_API,
      logoutUser,
    ),
  }
}

export default http
