import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import endpoints from '../../shared/constants/endpoints'
import GetDataPyme from '../../../../modules/Pyme/application/useCase/getDataPyme'
import GetLastQuote from '../../../../modules/Pyme/application/useCase/getLastQuote'
import UpdateSteps from '../../../../modules/Pyme/application/useCase/updateSteps'
import { ILastQuote,
  IPymeRepository,
  TDataPyme } from '../../../../modules/Pyme/domain'
import { Steps } from '../../../../modules/commons'

const homeController = ({
  pymeRepository,
}: {
  pymeRepository: IPymeRepository
}) => {
  const [dataPymeIsLoading, setDataPymeIsLoading] = useState(false)
  const [dataPyme, setDataPyme] = useState<TDataPyme | null>(null)
  const [errorPyme, setErrorPyme] = useState('')
  const [lastQuote, setLastQuote] = useState<ILastQuote | null>(null)
  const [quoteError, setQuoteError] = useState('')

  const getDataPymeUseCase = new GetDataPyme(pymeRepository)
  const getLastQuoteUseCase = new GetLastQuote(pymeRepository)
  const updateStep = new UpdateSteps(pymeRepository)
  const history = useHistory()

  const handleOnPressCompanyApplication = () => history.push(endpoints.registrationRequest)

  useEffect(() => {
    async function getData() {
      try {
        setDataPymeIsLoading(true)
        const response = await getDataPymeUseCase.getDataPyme(false)

        return setDataPyme(response.data)
      } catch (err) {
        setDataPyme(null)
        return setErrorPyme('Error en conseguir data pyme.')
      } finally {
        setDataPymeIsLoading(false)
      }
    }

    getData()
  }, [])

  useEffect(() => {
    async function getLastQuote() {
      try {
        const response = await getLastQuoteUseCase.getLastQuote()

        return setLastQuote(response.data)
      } catch (err) {
        setLastQuote(null)
        return setQuoteError('Error en conseguir la ultima cotización.')
      }
    }

    getLastQuote()
  }, [])

  useEffect(() => {
    updateStep.run(Steps.NoIniciado)
  }, [])

  return {
    dataPyme,
    errorPyme,
    lastQuote,
    quoteError,
    handleOnPressCompanyApplication,
    dataPymeIsLoading,
  }
}

export default homeController
