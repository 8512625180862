export const HOLDER_STEPS = {
  INCOMPLETE: 2,
  COMPLETE: 3,
  FORM_COMPLETE: 1, // ONLY FOR FRONTEND USE
  DJ_SEND: 4,
  DJ_COMPLETE: 5,
}

export default {
  HOLDER_STEPS,
}
