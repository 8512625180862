import { AdvisorSecurityCodeParam } from './AdvisorSecurityCodeParam'
import { IPymeRepository } from '../../../domain/entity/PymeRepository'
import { ValidationServerResponse } from './validateAdvisorsSecurityCodeServerResponse'

export default class ValidateAdvisorsSecurityCode {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async validateAdvisorsSecurityCode(
    param: AdvisorSecurityCodeParam,
  ): Promise<ValidationServerResponse> {
    return this.repository.validateAdvisorsSecurityCode(param)
  }
}
