import React, { FC, ReactNode } from 'react'
import Header from './Header'

export const CommonPageContainer: FC<{ children: ReactNode }> = ({
  children,
}) => (
  <>
    <Header>¡Te damos la bienvenida al Portal de Empresas!</Header>
    {children}
  </>
)

export default CommonPageContainer
