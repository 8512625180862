import React, { ReactElement, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import Stores from '../stores'

// Redirect on root if user is already authenticated
const RedirectRoot = ({ children }: { children: ReactElement }) => {
  const { isAuth, isFirstLogin } = useContext(Stores.LoginContext)

  if (!isAuth) {
    return children
  }

  return isFirstLogin ? <Redirect to="/welcome" /> : <Redirect to="/home" />
}

export default RedirectRoot
