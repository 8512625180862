import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ILastQuote, TDataPyme } from '../../../modules/Pyme/domain'
import Text from './Text'
import * as Icons from './Icon'

import OMINT_LOGO from '../shared/images/logo_omint_azul.svg'
import whiteLogo from '../shared/images/solid_white.png'
import stores from '../stores'
import Advisor from '../Pages/Home/Advisor'
import PlanSummaryCard from '../Pages/Home/PlanSummaryCard'
import RepositoriesProvider from '../components/RepositoriesProvider'

const NavBarContainer = styled.div`
  padding: 0 0.7rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 10px 0px 25px rgba(125, 125, 125, 0.05);
  justify-content: space-between;
  padding-bottom: 2rem;
`

const Logo = styled.img`
  width: 5rem;
`

const NavBarHeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 3.7rem;
  width: 90%;
`

const DataPymeContainer = styled.div`
  height: 3rem;
  display: flex;
  gap: 0.8rem;
  margin: 1.8rem 0.5rem;
`
const PymesLogo = styled.img`
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
`

const DataPymesName = styled.div`
  display: flex;
  flex-direction: column;
`

interface NavPyme {
  data: TDataPyme | null
  logo: any
}

const NavBarDataPyme: React.FC<NavPyme> = ({ data, logo }) => {
  const razonSocial = data?.RazonSocial ? data.RazonSocial : ''
  const profileLogo = logo || whiteLogo // provisorio hasta definición de logo default

  return (
    <DataPymeContainer>
      <PymesLogo src={profileLogo} alt="pymes name" />
      <DataPymesName>
        <Text color="chathamsBlue" weight="md">
          {razonSocial}
        </Text>
        <Text size="sm">Empresa</Text>
      </DataPymesName>
    </DataPymeContainer>
  )
}

const NavBarHeader = () => (
  <NavBarHeaderContainer>
    <Logo src={OMINT_LOGO} alt="Omint empresas" />
    <Text color="chathamsBlue">|</Text>
    <Text color="chathamsBlue">Empresas</Text>
  </NavBarHeaderContainer>
)

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`

const Option = styled.div<{ active: boolean }>`
  background-color: ${(props) => (props.active ? props.theme.colors.altoAlpha : props.theme.colors.white)};
  height: 3rem;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  gap: 0.5rem;
  cursor: pointer;

  :hover {
    border-style: solid;
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.flatBlue};
  }
`

const NavBarOptions = () => {
  const history = useHistory()

  const handleOnClickHomeButton = () => history.push('/home')

  return (
    <OptionsContainer>
      <Option active onClick={handleOnClickHomeButton}>
        <Icons.BusinessIcon size="sm" color="flatBlue" />
        <Text weight="mdS" color="flatBlue">
          Inicio
        </Text>
      </Option>
    </OptionsContainer>
  )
}

const NavBarHr = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.altoAlpha};
`
const LogoutContainer = styled.div`
  display: flex;
  cursor: pointer;
`
const LogoutButton = () => {
  const context = useContext(stores.LoginContext)
  const handleOnPressLogout = () => context.handleOnPressLogout()

  return (
    <LogoutContainer onClick={handleOnPressLogout}>
      <Icons.LogoutIcon size="sm" color="flatBlue" />
      <Text weight="mdS" color="flatBlue">
        Salir
      </Text>
    </LogoutContainer>
  )
}

interface INavBar {
  dataPyme: TDataPyme | null
  pymeLogo: any
  lastQuote: ILastQuote | null
  activeScreen: number
}

const NavBarContent = styled.div``
const NavBar: React.FC<INavBar> = ({
  dataPyme,
  pymeLogo,
  lastQuote,
  activeScreen,
}) => {
  const { pymeRepository } = RepositoriesProvider()

  const isPathHome = window.location.pathname !== '/home' && activeScreen !== 4
  const isStep1 = activeScreen === 1
  return (
    <NavBarContainer>
      <NavBarContent>
        <NavBarHeader />
        <NavBarDataPyme data={dataPyme} logo={pymeLogo} />
        <NavBarOptions />
        <NavBarHr />
        <Advisor lastQuote={lastQuote} />
        <PlanSummaryCard
          showCard={isPathHome}
          showButton={isStep1}
          lastQuote={lastQuote}
          pymeRepository={pymeRepository}
        />
      </NavBarContent>
      <LogoutButton />
    </NavBarContainer>
  )
}

export default NavBar
