import React from 'react'

import styled from 'styled-components'
import { CloseIcon } from './Icon'
import Text from './Text'
import Spinner from './Spinner'
import useModal from '../hooks/useModal'

type TModal = {
  displayElement: boolean
}

export const ModalComponent = styled.div<TModal>`
  display: ${(props) => (props.displayElement ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 56, 126, 0.75);
  z-index: 1;
`

export const ModalMain = styled.div`
  position: fixed;
  background: white;
  min-width: 300px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  padding: 15px;
`

export const CloseButtonContainer = styled.div<TModal>`
  display: ${(props) => (props.displayElement ? 'flex' : 'none')};
  justify-content: flex-end;
`

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface TModalContext {
  modalContent: string
  displayModal: boolean
  handleDisplayModal: (display: boolean) => void
  modalIsFetching: boolean
  error: string
  handleModalContent: (content: any) => void
  handleOnModalIsFetching: (isFetching: boolean) => void
  handleOnModalError: (modalError: any) => void
}

const ModalContext = (() => React.createContext({} as TModalContext))()
const { Provider } = ModalContext

const Modal = () => {
  const {
    modalContent,
    displayModal,
    handleDisplayModal,
    modalIsFetching,
    error,
  } = React.useContext(ModalContext)

  return (
    <ModalComponent displayElement={displayModal}>
      <ModalMain>
        <>
          <CloseButtonContainer
            displayElement
            onClick={() => handleDisplayModal(false)}
          >
            <CloseIcon size="sm" isCursor />
          </CloseButtonContainer>
          {modalIsFetching ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            modalContent
          )}

          {error && (
            <Text textAlign="center" color="scarlet">
              {error}
            </Text>
          )}
        </>
      </ModalMain>
    </ModalComponent>
  )
}

const ModalProvider = ({ children }: { children: any }) => {
  const {
    displayModal,
    handleDisplayModal,
    handleModalContent,
    modalContent,
    modalIsFetching,
    handleOnModalIsFetching,
    error,
    handleOnModalError,
  } = useModal()

  return (
    <Provider
      value={{
        displayModal,
        handleDisplayModal,
        handleModalContent,
        modalContent,
        modalIsFetching,
        handleOnModalIsFetching,
        error,
        handleOnModalError,
      }}
    >
      <Modal />
      {children}
    </Provider>
  )
}

export default Modal
export { ModalProvider, ModalContext }
