import { Steps } from '../../../commons'

export const registrationRequestStatus: {
  [key: number]: string
} = {
  [Steps.NoIniciado]: 'no iniciado',
  [Steps.DatosPymes]: 'en proceso',
  [Steps.DatosGrupoFamiliar]: 'en proceso',
  [Steps.Pago]: 'en proceso',
  [Steps.Completo]: 'completado',
}

export enum MemberType {
  desregulado = 1,
  monotributista = 2,
  individual = 3,
}
