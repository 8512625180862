import React from 'react'
import constants from '../../shared/constants'
import AlreadySavedScreen from './AlreadySavedScreen'

const {
  commons: {
    HOLDER_STEPS: { DJ_COMPLETE },
  },
} = constants

const EXCLUDE_LIST = [DJ_COMPLETE]

const DJSGuardScreen = ({
  step,
  children,
}: {
  step: number
  children: JSX.Element
}) => {
  const shouldContinue = EXCLUDE_LIST.every((v) => v !== step)

  return shouldContinue ? children : <AlreadySavedScreen />
}

export default DJSGuardScreen
