import styled from 'styled-components'

const SpinnerBasic = styled.div<{ width?: string; heigth?: string }>`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: ${(props) => (props.width ? props.width : '1.5')}rem;
  height: ${(props) => (props.heigth ? props.heigth : '1.5')}rem;
  border-radius: 50%;
  border-left-color: #09f;

  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

export default SpinnerBasic
