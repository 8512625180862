// tipos de afiliacion
export const memberTypes = {
  desrregulado: 1,
  monotributo: 2,
  individual: 3,
}

export const memberTypesLabel: {
  [key: string]: string
} = {
  Desregulados: 'Desregulados',
  Monotributistas: 'Monotributistas',
  Individuales: 'Individuales',
}

export type MemberTypes = typeof memberTypes
export type MemberTypesKeys = keyof MemberTypes
export type MemberTypesValues = typeof memberTypes[MemberTypesKeys]

export const memberTypesPlural: {
  [key: string]: number
} = {
  Desregulados: 1,
  Monotributistas: 2,
  Individuales: 3,
}

export default {
  memberTypes,
  memberTypesLabel,
}
