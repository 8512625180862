import React, { FC } from 'react'
import styled from 'styled-components'
import { IMaskInput } from 'react-imask'

import Basic from './FieldBasic'

type TMaskField = {
  name: string
  label: string
  value?: string
  onChange?: (e: string) => void
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void
  onBlur?: (e: any) => void
  mask?: any
  validationMsg?: string
  placeholder?: string
  disabled?: boolean
  radix?: string
  lazy?: boolean
  thousandsSeparator?: string
}

const TextFieldContainer = styled.div<{ disabled?: boolean }>`
  > input {
    width: 100%;
    height: 2rem;
    border: 1px solid ${({ theme }) => theme.colors.silverChalice};
    border-radius: 3px;
    color: ${({ theme }) => theme.colors.greyishBrownTwo};
    padding-left: 0.5rem;
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.concrete : theme.colors.alabaster)};

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.mariner}!important;
      outline-offset: 0px;
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.silverChalice};
      font-size: 0.8rem;
    }
  }
`

const MaskField: FC<TMaskField> = ({
  name,
  label,
  value = '',
  onChange = () => {},
  onBlur = () => {},
  validationMsg,
  mask = Number,
  placeholder = '',
  disabled = false,
  setFieldValue,
  radix,
  lazy,
  thousandsSeparator,
}) => {
  const handleOnChange = (e: any) => {
    if (setFieldValue) setFieldValue(name, e)

    if (onChange) onChange(e)
  }

  const handleOnBlur = (e: any) => {
    if (onBlur) onBlur(e)
  }

  return (
    <Basic name={name} label={label} validationMsg={validationMsg}>
      <TextFieldContainer disabled={disabled}>
        <IMaskInput
          mask={mask}
          radix={radix}
          value={value}
          onAccept={handleOnChange}
          placeholder={placeholder}
          lazy={lazy}
          thousandsSeparator={thousandsSeparator}
          aria-label={name}
          disabled={disabled}
          onBlur={handleOnBlur}
        />
      </TextFieldContainer>
    </Basic>
  )
}

MaskField.defaultProps = {
  value: '',
  onChange: () => {},
  onBlur: () => {},
  mask: Number,
  placeholder: '',
  disabled: false,
  validationMsg: '',
  radix: ',',
  lazy: false,
  setFieldValue: () => {},
  thousandsSeparator: '',
}

export default MaskField
export type { TMaskField }
