import * as idbkeyval from 'idb-keyval'
import { IPersistence } from './interfaces'

import TestingPersistence from './testing'

const persist: IPersistence = (() => {
  try {
    if (process.env.NODE_ENV === 'test') return TestingPersistence

    return idbkeyval as IPersistence
  } catch (err) {
    // eslint-disable-next-line
    console.log('PERSISTENCE ERROR: ', err)

    return TestingPersistence
  }
})()

export default persist
