import React, { FC, Fragment } from 'react'
import styled from 'styled-components'
import Text from './Text'

import { DotFillIcon, DotIcon } from './Icon'

type Props = {
  activeItem: boolean
  theme: any
}

const StepperContainer = styled.ol`
  display: flex;
  flex-wrap: wrap;
  width: 70vw;

  --circle-size: 3rem;
  --spacing: 0.5rem;
`

const Item = styled.li`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:not(:last-child) {
    &:after {
      content: '';
      position: relative;
      top: calc(var(--circle-size) / 1.2);
      width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
      left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
      height: 3px;
      background-color: ${(props: Props) => (props.activeItem
    ? props.theme.colors.chathamsBlue
    : props.theme.colors.silver)};
      order: -1;
    }
  }
`

interface StepperItem {
  title: string
  onClick?: () => void
}

const Stepper: FC<{
  titleItems: string[]
  active: number
}> = ({ titleItems, active }) => (
  <StepperContainer>
    {titleItems
      && titleItems.map((title: string, index: number) => (
        <Item key={title} activeItem={index + 1 <= active}>
          <Text
            weight="md"
            color={`${index <= active ? 'chathamsBlue' : 'silver'}`}
            horizontalAlign="center"
          >
            {title}
          </Text>
          {index <= active ? (
            <DotFillIcon size="md" color="chathamsBlue" />
          ) : (
            <DotIcon size="md" color="silver" />
          )}
        </Item>
      ))}
  </StepperContainer>
)

const VerticalStepperContainer = styled.div``
const Row = styled.div`
  display: flex;
  height: 50px;
`
const Hr = styled.div`
  width: 2px;
  height: 2rem;
  position: relative;
  margin-top: -20px;
  transform: rotate(180deg);
  background-color: ${(props) => props.theme.colors.silver};
  margin-left: 12px; // padding half size of DotIcon
`
const Process = styled.div<{ completed?: boolean }>`
  &:hover {
    cursor: ${(props) => (props.completed ? 'cursor' : 'pointer')};
  }
`

const Label = styled.div`
  font-size: 10px;
`

const validateOnClick = (
  completed: boolean,
  index: number,
  active: number,
  action: StepperItem,
) => {
  if (completed) return undefined

  if (index <= active) return action.onClick

  return undefined
}

const handleLabelStatus = (active: number, index: number): string => {
  if (active === index) {
    return 'en proceso'
  }
  return active > index ? 'Completo' : ''
}

const VerticalStepper: FC<{
  titleItems: StepperItem[]
  active: number
  completed?: boolean
}> = ({ titleItems, active, completed = false }) => (
  <VerticalStepperContainer>
    {titleItems
      && titleItems.map((StepperItem, index) => (
        <Fragment key={StepperItem.title}>
          <Process
            completed={completed}
            onClick={validateOnClick(completed, index, active, StepperItem)}
          >
            <Row>
              {index < active ? (
                <DotFillIcon size="md" color="chathamsBlue" />
              ) : (
                <DotIcon
                  size="md"
                  color={`${index <= active ? 'chathamsBlue' : 'silver'}`}
                />
              )}
              <div>
                <Text color={`${index <= active ? 'chathamsBlue' : 'silver'}`}>
                  {StepperItem.title}
                </Text>
                <Label>
                  <Text color="silver">{handleLabelStatus(active, index)}</Text>
                </Label>
              </div>
            </Row>
          </Process>
          {index !== titleItems.length - 1 && (
            <Row>
              <Hr />
            </Row>
          )}
        </Fragment>
      ))}
  </VerticalStepperContainer>
)

VerticalStepper.defaultProps = {
  completed: false,
}

export default {
  Stepper,
  VerticalStepper,
}
