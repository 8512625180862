import React from 'react'
import UI from '../../../../UI'

const HeaderStep2 = () => (
  <>
    <UI.Text color="mariner" size="mdLg" weight="md">
      Completa los datos de tu empresa
    </UI.Text>
    <UI.Text color="tundora" size="md">
      Carga los datos de cada grupo familiar del listado y sus integrantes según
      el tipo de contratación.
    </UI.Text>
    <UI.Text>
      Recordá cargar un mail valido para el titular ya que se le enviara una
      solicitud para completar la DDJJ a su correo electronico.
    </UI.Text>
  </>
)

export default HeaderStep2
