import { IFamilyGroupRepository } from '../../domain/entities'

export default class GetIfAllDjsAreComplete {
  readonly repository: IFamilyGroupRepository

  constructor(repository: IFamilyGroupRepository) {
    this.repository = repository
  }

  async run(): Promise<boolean> {
    const response = await this.repository.getIfAllDjsAreComplete()

    return response.data.PuedeContinuar
  }
}
