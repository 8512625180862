import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import Basic from './FieldBasic'

type TTextField = {
  name: string
  label: string
  value?: string
  onChange?: (event: { target: HTMLInputElement }) => void
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void
  onBlur?: (e: any) => void
  validationMsg?: string
  password?: boolean
  placeholder?: string
  disabled?: boolean
  maxCharacters?: number
  helperText?: string
}

const TextFieldContainer = styled.div<{
  disabled?: boolean
}>`
  > input {
    width: 100%;
    height: 2rem;
    border: 1px solid ${({ theme }) => theme.colors.silverChalice};
    border-radius: 3px;
    color: ${({ theme }) => theme.colors.greyishBrownTwo};
    padding-left: 0.5rem;
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.concrete : theme.colors.alabaster)};

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.mariner}!important;
      outline-offset: 0px;
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.silverChalice};
      font-size: 0.8rem;
    }
  }
`

const TextField: FC<TTextField> = ({
  name,
  label,
  setFieldValue,
  value: providedValue,
  onChange = () => {},
  validationMsg,
  password = false,
  onBlur = () => {},
  placeholder = '',
  disabled = false,
  maxCharacters,
  helperText,
}) => {
  const inputType = password ? 'password' : 'text'

  const [value, setValue] = useState<number | string>(providedValue || '')

  const handleOnChange = (event: { target: HTMLInputElement }) => {
    const inputValue = event.target.value

    if (maxCharacters) {
      if (inputValue.length <= maxCharacters) {
        return setValue(inputValue)
      }
      return setValue(inputValue.substring(0, maxCharacters))
    }

    if (onChange) onChange(event)
    return setValue(inputValue)
  }

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e)
  }

  useEffect(() => {
    if (setFieldValue) setFieldValue(name, value)
  }, [value])

  return (
    <Basic
      name={name}
      label={label}
      validationMsg={validationMsg}
      helperText={helperText}
    >
      <TextFieldContainer disabled={disabled}>
        <input
          type={inputType}
          aria-label={name}
          id={name}
          name={name}
          value={providedValue}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          disabled={disabled}
        />
      </TextFieldContainer>
    </Basic>
  )
}

TextField.defaultProps = {
  value: '',
  onChange: () => {},
  password: false,
  onBlur: () => {},
  setFieldValue: () => {},
  placeholder: '',
  disabled: false,
  validationMsg: '',
  maxCharacters: 0,
  helperText: '',
}

export default TextField
export type { TTextField }
