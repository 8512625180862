import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as S from './styles'
import UI from '../../../../UI'
import RequestDetails from './RequestDetail'
import ConfirmCancelModalFinishRequest from './ConfirmCancelModalFinishRequest'
import { IHandleSetActiveIndex } from '../../../../hooks/useStepper'
import { IPymeRepository } from '../../../../../../modules/Pyme/domain'
import detailController from '../../../../../../modules/Pyme/infrastructure/controllers/detailsController'

const Detail = ({
  handleSetActiveIndex,
  pymeRepository,
}: {
  handleSetActiveIndex: () => IHandleSetActiveIndex
  pymeRepository: IPymeRepository
}) => {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [termsInput, setTermsInput] = useState(false)

  const handleModalTerms = () => {
    setTermsInput(!termsInput)
  }
  const { dataPymeSummary, pymeSummaryIsLoading, finishRequest } = detailController({ pymeRepository })

  return pymeSummaryIsLoading ? (
    <UI.Spinner />
  ) : (
    <S.DetailsContainer>
      <UI.Text color="mariner" size="lg" weight="md">
        Detalle de la solicitud
      </UI.Text>
      <S.RequestDetailsContainer>
        {dataPymeSummary && (
          <>
            <RequestDetails dataPymeSummary={dataPymeSummary} />
            <S.Tacbox>
              <label htmlFor="checkbox">
                <input
                  id="checkbox"
                  type="checkbox"
                  checked={termsInput}
                  onChange={handleModalTerms}
                />
                <a
                  href="https://www.omint.com.ar/Website2/Terminos%20y%20condiciones_venta%20pyme.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Acepto términos y condiciones
                </a>
              </label>
            </S.Tacbox>
            <S.ButtonsContainer>
              <UI.Button
                variant="outlined"
                onClick={() => {
                  handleSetActiveIndex().prev()
                }}
              >
                Anterior
              </UI.Button>
              <UI.Button
                disabled={!termsInput}
                onClick={() => {
                  setShowModal(true)
                }}
              >
                Finalizar Solicitud
              </UI.Button>
            </S.ButtonsContainer>
          </>
        )}
      </S.RequestDetailsContainer>
      {showModal && (
        <ConfirmCancelModalFinishRequest
          onPressCancel={() => setShowModal(false)}
          onPressConfirmCancel={() => {
            finishRequest()
            setShowModal(false)
            history.push('/home')
            window.location.reload()
          }}
        />
      )}
    </S.DetailsContainer>
  )
}

export default Detail
