import * as yup from 'yup'
import MedioDePago from '../../../../../../modules/Pyme/application/useCase/getPaymentMethodData/PaymentMethodData'
import { PaymentFormValues } from '../../../../../../modules/Pyme/application/useCase/savePaymentMethod/PaymentFormValues'
import { PaymentType } from '../../../../../../modules/Pyme/application/useCase/savePaymentMethod/PaymentType'
import constants from '../../../../shared/constants'

const {
  formRelated: { validationMsg },
} = constants

export enum PaymentActions {
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  SET_BUTTON_DISABLED = 'SET_BUTTON_DISABLED',
  SET_CONTINUE_BTN_STATE = 'SET_CONTINUE_BTN_STATE',
  SET_FORM_IS_FETCHING = 'SET_FORM_IS_FETCHING',
  SET_CBU = 'SET_CBU',
  SET_ERROR = 'SET_ERROR',
}

export interface PaymentAction {
  type: PaymentActions
  payload: any
}

export interface PaymentState {
  isContinueBtnDisabled: boolean
  isFormFetching: boolean
  error: string
}

export const paymentFormInitValues = (
  initialValues: MedioDePago | null,
): PaymentFormValues => ({
  paymentMethod: initialValues?.MedioPago.TipoPago || PaymentType.none,
  cbu: initialValues?.MedioPago.InfoExtra || '',
  nameAndLastName: initialValues?.DatoFacturacion.NombreCompleto || '',
  grossIncome: initialValues?.DatoFacturacion.IIBB || '',
  street: initialValues?.DatoFacturacion.Calle || '',
  streetNumber: initialValues?.DatoFacturacion.Numero || '',
  floorDpto: initialValues?.DatoFacturacion.Resto,
  postalCode: initialValues?.DatoFacturacion.CodigoPostal || '',
})

export const paymentFormValidationSchema = yup.object().shape({
  paymentMethod: yup.number().test({
    name: 'requiredCbu',
    exclusive: false,
    params: {},
    message: 'Debes seleccionar un método de pago',
    test(value: number | undefined) {
      if (value === PaymentType.none) return false

      return true
    },
  }),
  cbu: yup.string().test({
    name: 'requiredCbu',
    exclusive: false,
    params: {},
    message: 'Debes ingresar un CBU',
    test(value: string | undefined) {
      if (this.parent.paymentMethod === PaymentType.debit && !value) {
        return false
      }
      if (
        this.parent.paymentMethod === PaymentType.debit
        && value?.length !== 22
      ) {
        return false
      }

      return true
    },
  }),
  nameAndLastName: yup
    .string()
    .min(
      3,
      validationMsg.customAtLeastFieldNameAndCharacterLength(
        'Nombre y Apellido',
        3,
      ),
    )
    .max(
      100,
      validationMsg.customAtMaxFieldNameAndCharacterLength(
        'Nombre y Apellido',
        100,
      ),
    )
    .required(validationMsg.fieldRequired),
  grossIncome: yup.string().test({
    name: 'requiredCbu',
    exclusive: false,
    params: {},
    message: 'Campo requerido y válido',
    test(value: string | undefined) {
      if (!value) return false

      return true
    },
  }),
  street: yup
    .string()
    .min(5, validationMsg.customAtLeastFieldNameAndCharacterLength('calle', 5))
    .max(50, validationMsg.customAtMaxFieldNameAndCharacterLength('calle', 50))
    .required(validationMsg.fieldRequired),
  streetNumber: yup
    .string()
    .max(50, validationMsg.customAtMaxFieldNameAndCharacterLength('número', 50))
    .required(validationMsg.fieldRequired),
  floorDpto: yup
    .string()
    .max(
      50,
      validationMsg.customAtMaxFieldNameAndCharacterLength('piso/depto', 50),
    ),
  postalCode: yup
    .string()
    .max(
      50,
      validationMsg.customAtMaxFieldNameAndCharacterLength('código postal', 50),
    )
    .required(validationMsg.fieldRequired),
})
