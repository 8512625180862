import styled from 'styled-components'
import backgroundImg from '../../shared/images/login_background.jpg'

const ContainerPage = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center center;
`

const LeftContent = styled.div`
  flex-basis: 20rem;
  flex-grow: 1;

  // hide element when the viewport is less than 640px
  max-width: max(0px, calc((100% - 800px) * 999));
  overflow: hidden;
`

const RightContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1.5;
  width: 50%;
  background-color: rgba(255, 255, 255, 0.95);

  > form {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
      height: 60vh;
      margin-top: 4rem;
    }
  }
`

const LogoOmint = styled.img`
  width: 8rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default {
  ContainerPage,
  LeftContent,
  RightContent,
  LogoOmint,
  Container,
}
