import styled from 'styled-components'
import MainLogo from '../../shared/images/logo_omint_blanco.svg'

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Grow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const AdvisorContainer = styled.div`
  width: 215px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 2.5rem;
`

type TPlanSummaryCard = {
  displayElement: boolean
}

export const PlanSummaryContainer = styled.div<TPlanSummaryCard>`
  display: ${(props) => (props.displayElement ? 'flex' : 'none')};
  width: 215px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 2.5rem;
`

export const TextContainer = styled.div`
  margin: 0.6rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 3px;
  justify-content: flex-start;
`

export const ButtonSummaryContainer = styled.div`
  display: flex;
  width: 180px;
  margin-top: 1rem;
  margin-bottom: 2rem;
`

export const ContactText = styled.div`
  color: ${({ theme }) => theme.colors.chathamsBlue};
  font-weight: 600;
  font-size: 14px;
`

export const CustomButton = styled.div`
  width: 100%;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.chathamsBlue};
  font-weight: bold;
  font-size: 18px;
  height: 32px;
  display: flex;
  align-items: center;
`

export const AvatarContainer = styled.div<{ foto?: string }>`
  background-color: ${(props) => (props.foto !== undefined ? 'transparent' : props.theme.color.silver)};
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Avatar = styled.img<{ src: string }>`
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center center;
  width: 104px;
  height: 110px;
`

export const OmintAvatar = styled.div`
  background-image: url(${MainLogo});
  background-size: cover;
  background-position: center center;
  width: 150px;
  height: 40px;
`

export const ContactMedias = styled.div`
  width: 100%;
`

export const ContactMediasRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 4px 0px;
`

export const ClickeableIcon = styled.div<{ onClick: () => void }>``
