import React, { FC } from 'react'
import { IPymeRepository } from '../../../../modules/Pyme/domain'
import hooks from '../../hooks'

import UI from '../../UI'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'

import S from './styles'
import RedirectToLogin from './Steps/RedirectToLogin'

const { useStepper } = hooks

const data = [
  'Cargá tu código',
  'Cambiá tu contraseña',
  'Confirmá tus datos',
  'Personalizá tu perfil',
]

const screens = [
  (props: {
    handleSetActiveIndex: () => {}
    pymeRepository: IPymeRepository
  }) => <Step1 {...props} />,
  (props: {
    handleSetActiveIndex: () => {}
    pymeRepository: IPymeRepository
  }) => <Step2 {...props} />,
  (props: {
    handleSetActiveIndex: () => {}
    pymeRepository: IPymeRepository
  }) => <Step3 {...props} />,
  (props: {
    handleSetActiveIndex: () => {}
    pymeRepository: IPymeRepository
  }) => <Step4 {...props} />,
  (props: {}) => <RedirectToLogin {...props} />,
]

type TFirsLoginPage = {
  activeScreen?: number
  pymeRepository: IPymeRepository
}

const FirstLoginPage: FC<TFirsLoginPage> = ({
  activeScreen = 0,
  pymeRepository,
}) => {
  const { itemsTitle, handleSetActiveIndex, activeIndex } = useStepper({
    data,
    activeScreen,
  })

  return (
    <UI.PageContainer>
      <S.PageContainer>
        <UI.Steppers.Stepper titleItems={itemsTitle} active={activeIndex} />
        <S.CardContainer>
          <UI.Cards.Card>
            {screens[activeIndex]({
              handleSetActiveIndex,
              pymeRepository,
            })}
          </UI.Cards.Card>
        </S.CardContainer>
      </S.PageContainer>
    </UI.PageContainer>
  )
}

FirstLoginPage.defaultProps = {
  activeScreen: 0,
}

export default FirstLoginPage
