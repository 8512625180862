import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import UI from '../../../UI'
import * as S from '../styles'
import changePasswordController from '../../../../../modules/Pyme/infrastructure/controllers/changePasswordController'
import { IPymeRepository } from '../../../../../modules/Pyme/domain'

const initialValues = {
  Password: '',
  ConfirmarPassword: '',
}

const validationForm = yup.object().shape({
  Password: yup
    .string()
    .min(6, 'La password debe tener mas de 6 caracteres')
    .required('Campo requerido'),
  ConfirmarPassword: yup
    .string()
    .min(6, 'La password debe tener mas de 6 caracteres')
    .oneOf([yup.ref('Password'), null], 'Ambas claves deben ser iguales')
    .required('Campo requerido'),
})

// Screen where user change password
const Step2 = ({
  handleSetActiveIndex,
  pymeRepository,
}: {
  handleSetActiveIndex: any
  pymeRepository: IPymeRepository
}) => {
  const { errorMsg, isLoading, handleOnPressSubmit } = changePasswordController(
    {
      handleSetActiveIndex,
      pymeRepository,
    },
  )

  return (
    <>
      <UI.Text color="mariner" weight="lg" size="xl">
        Modificá tu contraseña
      </UI.Text>
      <S.FormContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnPressSubmit}
          validationSchema={validationForm}
          validateOnMount
        >
          {({
            values,
            handleSubmit,
            errors,
            handleChange,
            isValid,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <UI.TextField
                value={values.Password}
                label="Elegí tu nueva contraseña"
                name="Password"
                password
                validationMsg={errors.Password}
                onChange={handleChange}
                placeholder="Ingresar"
              />
              <UI.TextField
                value={values.ConfirmarPassword}
                label="Confirmá tu nueva contraseña"
                name="ConfirmarPassword"
                password
                validationMsg={errors.ConfirmarPassword}
                onChange={handleChange}
                placeholder="Ingresar"
              />
              <UI.Text color="scarlet">{errorMsg}</UI.Text>
              <UI.Button
                type="submit"
                disabled={!isValid || isSubmitting}
                isLoading={isLoading}
              >
                Continuar
              </UI.Button>
            </form>
          )}
        </Formik>
      </S.FormContainer>
    </>
  )
}

export default Step2
