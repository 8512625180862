import { IApi, TServerResponse } from '../../../../app/domain/httpClient'
import IQuestionRepository from '../../../Questions/domain/QuestionRepository'
import Answer from '../../domain/entities/Answer'
import IDjsRepository from '../../domain/IDjsRepository'
import { TAddMemberDjsParams, TAnswers } from '../../domain/valueObjects'

const getQuestionResponse = (answers: Answer | undefined) => (questionId: number) => answers?.preguntas[questionId] || false

export default class DjsRepository implements IDjsRepository {
  private portalHttpClient: IApi

  private answers: TAnswers | undefined = undefined

  private questionRepository: IQuestionRepository

  constructor(portalHttpClient: IApi, questionRepository: IQuestionRepository) {
    this.portalHttpClient = portalHttpClient
    this.questionRepository = questionRepository
  }

  addMemberDjs(props: TAddMemberDjsParams) {
    const answer = Answer.create(props)

    this.answers = Object.assign(this.answers ? this.answers : {}, {
      [props.dni]: { ...answer },
    })
  }

  getAllMembersDjs(): TAnswers | undefined {
    return this.answers
  }

  getMemberDjsByDni(dni: number): Answer | undefined {
    return this.answers ? this.answers[dni] : undefined
  }

  async saveAllMembersDjs(guid: string): Promise<TServerResponse<any>> {
    const answers = this.getAllMembersDjs()
    let memberDjs: { [key: number]: Answer } = {}

    if (!answers) return Promise.resolve({ status: 200, data: {} })

    const mapQuestionsByMemberRequests = answers
      && Object.keys(answers).map((dni: string) => {
        const djs = this.getMemberDjsByDni(parseInt(dni, 10))
        memberDjs = { ...memberDjs, [dni]: djs }

        return (
          djs
          && this.questionRepository.getQuestionsByMemberTypeId(djs.memberTypeId)
        )
      })

    const questionsByMember = mapQuestionsByMemberRequests
      && (await Promise.all(mapQuestionsByMemberRequests))

    const mapDataToDjsByMember = Object.values(memberDjs).map((dj) => ({
      Altura: parseFloat(
        dj.altura
          .toString()
          .replace(',', '')
          .replaceAll('_', '0')
          .replace('mts', ''),
      ),
      Dni: dj.dni,
      Peso: parseFloat(dj.peso.toString().replaceAll('_', '').replace('k', '')),
      IMC: 1,
      Nombre: dj.nombre,
      Apellido: dj.apellido,
      respuestas: [],
      IMCValor: 1,
      TipoSocio: dj.memberTypeId,
    }))

    const addAnswersAndOptions = mapDataToDjsByMember.map((djs, index) => ({
      ...djs,
      respuestas: questionsByMember[index]?.map((q) => {
        const questionResponseByTheUser = q
          ? getQuestionResponse(memberDjs[djs.Dni])(q.Id)
          : false

        const isResultBoolean = typeof questionResponseByTheUser === 'boolean'

        const result = isResultBoolean ? questionResponseByTheUser : true

        return {
          idPregunta: q.Id,
          resultado: result,
          opciones: isResultBoolean
            ? []
            : Object.keys(questionResponseByTheUser)
              .map((p) => (memberDjs[djs.Dni]?.preguntas[q.Id][p]
                ? {
                  Id: p,
                  idPregunta: q.Id,
                  Opcion: '',
                  OpcionTexto: result,
                  EsOtro:
                          q.Opciones.find(
                            (op: any) => op.ID === parseInt(p, 10),
                          )?.EsOtro || false,
                }
                : null))
              .filter((a) => a !== null),
        }
      }),
    }))

    return this.portalHttpClient.post(
      `/Guardar?guid=${guid}`,
      addAnswersAndOptions,
    )
  }
}
