import { IPersistence } from './interfaces'

type TKeyVal = any[]

// pretend to replicate idb-keyvalue for testing
const TestingPersistence: IPersistence = (() => {
  let data: {
    [key: string | number]: any
  } = {}

  return {
    get: async (key: string | number) => data[key],
    set: async (key: string | number, value: any) => {
      data[key] = value
    },
    getMany: async (keys: string[] | number[]) => keys.map((key: string | number) => data[key]),
    setMany: async (keyvalPairs: TKeyVal[]) => {
      keyvalPairs.forEach((el) => {
        const [k, v] = el

        data[k] = v
      })
    },
    clear: () => {
      data = {}
    },
  }
})()

export default TestingPersistence
