import styled from 'styled-components'
import { TabList, Tab } from 'react-tabs'

export const TitlesContainer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`

export const FieldTitle = styled.div`
  margin-bottom: 1rem;
`

export const InputsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 1.2rem;
`

export const PhoneInputs = styled.div`
  display: flex;
  gap: 1rem;
`

export const AreaCodeInput = styled.div`
  display: flex;
  width: 3rem;
`

export const InputsContainerVertical = styled(InputsContainer)`
  flex-direction: column;
  gap: 0.1rem;
`

// basic constant for later usage in the saveButton
export const SaveButton = styled.div`
  position: absolute;
  right: 0rem;
  top: 1rem;
`

export const SaveButtonIcon = styled.div`
  margin-top: -1.5px;
  padding-right: 3px;
`

export const CompanyApplicationForm = styled.form`
  position: relative;
  width: 100%;
`

export const ScreensContainer = styled.div`
  margin: 1rem 0;
  position: relative;
`

export const StyledTabList = styled(TabList)`
  display: inline-flex;
  gap: 3rem;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding: 0;
  .react-tabs__tab--selected {
    color: ${({ theme }) => theme.colors.chathamsBlue};
    border: none;
    border-bottom: 4px solid ${({ theme }) => theme.colors.chathamsBlue};
  }
`

export const StyledTab = styled(Tab)`
  width: 15rem;
  text-decoration: none;
  text-align: center;
  padding-bottom: 0.5rem;
  &:hover {
    cursor: pointer;
  }
  list-style-type: none;
`

export const GrayLine = styled.div`
  width: 51rem;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  margin-top: -1rem;
`

export const CompletedHoldersContainer = styled.div<{
  completed: number
  totalOfHolders: number
  memberType: 'Desregulados' | 'Monotributistas' | 'Individuales'
}>`
  width: 51.25rem;
  font-weight: ${({ theme }) => theme.fontWeights.mdS};
  font-size: 0.75rem;
  color: ${({ completed, totalOfHolders, theme }) => (completed === totalOfHolders
    ? theme.colors.surfieGreen
    : theme.colors.tundora)};
  padding: 1.125rem 3.75rem;
  display: flex;
  justify-content: ${({ memberType }) => {
    const justifyContent = {
      Desregulados: 'flex-start',
      Monotributistas: 'center',
      Individuales: 'flex-end',
    }

    return justifyContent[memberType]
  }};
`

export default {
  TitlesContainer,
  FieldTitle,
  InputsContainer,
  SaveButton,
  StyledTabList,
  GrayLine,
  StyledTab,
  CompanyApplicationForm,
  ScreensContainer,
  CompletedHoldersContainer,
  InputsContainerVertical,
  PhoneInputs,
  AreaCodeInput,
  SaveButtonIcon,
}
