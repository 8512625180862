import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  gap: 4rem;
`

export const FormContainer = styled.div`
  > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`

export const CardContainer = styled.div`
  width: 464px;
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`

export const RedirectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  height: 200px;
  gap: 1rem;
`

export default {
  PageContainer,
  FormContainer,
  CardContainer,
  SpinnerContainer,
  RedirectContainer,
}
