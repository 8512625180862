import { IPymeRepository } from '../../../domain'

export default class GetPymeRequote {
  private repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async run(idPyme: number): Promise<any> {
    return this.repository.getRequote(idPyme)
  }
}
