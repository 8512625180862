import React from 'react'
import { Formik } from 'formik'
import S from '../../styles'
import UI from '../../../../UI'
import { TDropDown } from '../../../../UI/DropDown'
import { ILocation,
  Province } from '../../../../../../modules/Province/domain/Province'
import { companyCovarageDatesOptions,
  Step1FormInitialValues } from './constants'
import FormValidationSchema from './validationSchema'
import { SaveCompanyFormData } from '../../../../../../modules/Pyme/domain/entity/SaveCompanyFormData'
import { IHandleSetActiveIndex } from '../../../../hooks/useStepper'
import { TDataPyme } from '../../../../../../modules/Pyme/domain'

const CuitMask = '00-00000000-0'

const filter = (provincies: Province[], provId: number) => provincies
  ?.find((province: Province) => province.id === provId)
  ?.localidades.map((l: ILocation) => ({
    label: l.nombre,
    value: l.id,
  })) || []

const CompanyInfo = ({
  provincies,
  provinciesIsFetching,
  companyDataSaved,
  newChangesSaved,
  errorMsgOnFetchProvincies,
  handleOnSaveCompanyData,
  handleSetActiveIndex,
  errorMsgOnPutCompanyData,
  companyDataIsFetching,
  onPressCancel,
  pymeData,
  errorMsgOnFetchPymeData,
  handleContinue,
  handleNewChanges,
}: {
  provincies: Province[]
  provinciesIsFetching: boolean
  companyDataSaved: boolean
  newChangesSaved: boolean
  errorMsgOnFetchProvincies: string
  handleOnSaveCompanyData: (formValues: SaveCompanyFormData) => void
  handleSetActiveIndex: () => IHandleSetActiveIndex
  errorMsgOnPutCompanyData: string
  companyDataIsFetching: boolean
  onPressCancel: () => void
  pymeData: TDataPyme
  errorMsgOnFetchPymeData: string
  handleContinue: () => void
  handleNewChanges: () => void
}) => (
  <Formik
    initialValues={Step1FormInitialValues({ pymeData })}
    validationSchema={() => FormValidationSchema(provincies)}
    onSubmit={handleOnSaveCompanyData}
    enableReinitialize
    validateOnMount
  >
    {({
      values,
      handleChange,
      handleSubmit,
      errors,
      setFieldValue,
      isValid,
      isSubmitting,
      handleBlur,
      touched,
    }) => (
      <S.CompanyApplicationForm onSubmit={handleSubmit}>
        <S.TitlesContainer>
          <UI.Text color="mariner" size="lg" weight="md">
            Completá los datos de tu empresa
          </UI.Text>
        </S.TitlesContainer>
        <UI.Text color="chathamsBlue" weight="md">
          Datos de la empresa
        </UI.Text>
        <S.InputsContainer>
          <UI.TextField
            name="razonSocial"
            label="Razón social"
            onChange={handleChange}
            value={values.razonSocial}
            validationMsg={errors.razonSocial}
            onBlur={(e: any) => {
              handleBlur(e)
              handleNewChanges()
            }}
          />
          <UI.MaskInput
            name="cuit"
            label="CUIT"
            setFieldValue={setFieldValue}
            value={values.cuit}
            validationMsg={errors.cuit}
            mask={CuitMask}
            onBlur={(e: any) => {
              handleBlur(e)
              handleNewChanges()
            }}
          />
          <UI.TextField
            name="mail"
            label="Mail"
            onChange={handleChange}
            value={values.mail}
            validationMsg={errors.mail}
            onBlur={(e: any) => {
              handleBlur(e)
              handleNewChanges()
            }}
          />
          <S.PhoneInputs>
            <S.AreaCodeInput>
              <UI.FieldNumber
                name="codigoArea"
                label="Cod.Área"
                value={values.codigoArea}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                withArrows={false}
                maxDigits={4}
                validationMsg={errors.codigoArea}
                onBlur={(e: any) => {
                  handleBlur(e)
                  handleNewChanges()
                }}
                helperText="(sin el 0)"
              />
            </S.AreaCodeInput>
            <UI.FieldNumber
              name="telefono"
              label="Teléfono"
              onChange={handleChange}
              value={values.telefono}
              setFieldValue={setFieldValue}
              withArrows={false}
              maxDigits={12}
              validationMsg={errors.telefono}
              onBlur={(e: any) => {
                handleBlur(e)
                handleNewChanges()
              }}
              helperText="(sin el 15)"
            />
          </S.PhoneInputs>
        </S.InputsContainer>
        <S.InputsContainer>
          <UI.DropDown
            value={values.provincia}
            placeholder="Selecciona"
            id="provincia"
            name="provincia"
            label="Provincia"
            onChange={(option: TDropDown) => {
              setFieldValue('provincia', option.value)
            }}
            options={
              provincies
                ? provincies.map((p: Province) => ({
                  label: p.nombre,
                  value: p.id,
                }))
                : [{ label: '', value: '' }]
            }
            isLoading={provinciesIsFetching}
            isDisabled={provinciesIsFetching}
            validationMsg={errors.provincia || errorMsgOnFetchProvincies}
            onBlur={(e: any) => {
              handleBlur(e)
              handleNewChanges()
            }}
          />
          {provincies.find((p) => p.id === values.provincia)?.localidades
            .length ? (
              <UI.DropDown
                value={values.localidad}
                placeholder="Selecciona"
                id="localidad"
                name="localidad"
                label="Localidad"
                onChange={(option: TDropDown) => {
                  setFieldValue('localidad', option.value)
                }}
                options={filter(provincies, values.provincia)}
                validationMsg={errors.localidad}
              />
            ) : null}
        </S.InputsContainer>
        <S.TitlesContainer>
          <S.FieldTitle>
            <UI.Text color="chathamsBlue" weight="md" size="lg">
              Cobertura
            </UI.Text>
          </S.FieldTitle>
          <UI.DropDown
            value={values.fechaVigencia}
            placeholder="Selecciona"
            id="fechaVigencia"
            name="fechaVigencia"
            label="Fecha de inicio de cobertura"
            onChange={(option: TDropDown) => {
              setFieldValue('fechaVigencia', option.value)
            }}
            options={companyCovarageDatesOptions}
            validationMsg={errors.fechaVigencia}
            onBlur={(e: any) => {
              handleBlur(e)
              handleNewChanges()
            }}
          />
        </S.TitlesContainer>
        <UI.Text color="chathamsBlue" weight="md" size="lg">
          Colaboradores
        </UI.Text>
        <S.InputsContainerVertical>
          <UI.Text color="flatBlue">
            Cantidad de colaboradores en relación de dependencia
          </UI.Text>
          <UI.FieldNumber
            value={values.enRelacionDeDependencia.Empleados}
            name="Empleados"
            label="Colaboradores"
            withArrows={false}
            disabled
            tiny
          />
          <UI.FieldNumber
            value={values.enRelacionDeDependencia.Parejas}
            name="enRelacionDeDependencia"
            label="Parejas"
            withArrows={false}
            disabled
            tiny
          />
          <UI.FieldNumber
            value={values.enRelacionDeDependencia.Hijos}
            name="enRelacionDeDependencia"
            label="Hijos"
            withArrows={false}
            disabled
            tiny
          />
        </S.InputsContainerVertical>
        <S.InputsContainerVertical>
          <UI.Text color="flatBlue">
            Cantidad de colaboradores monotributistas
          </UI.Text>
          <UI.FieldNumber
            value={values.monotributistas.Empleados}
            name="monotributistas"
            label="Colaboradores"
            withArrows={false}
            disabled
            tiny
          />
          <UI.FieldNumber
            value={values.monotributistas.Parejas}
            name="monotributistas"
            label="Parejas"
            withArrows={false}
            disabled
            tiny
          />
          <UI.FieldNumber
            value={values.monotributistas.Hijos}
            name="monotributistas"
            label="Hijos"
            withArrows={false}
            disabled
            tiny
          />
        </S.InputsContainerVertical>
        <S.InputsContainerVertical>
          <UI.Text color="flatBlue">
            Cantidad de colaboradores directos (Sin obra social)
          </UI.Text>
          <UI.FieldNumber
            value={values.particulares.Empleados}
            name="particulares"
            label="Colaboradores"
            withArrows={false}
            disabled
            tiny
          />
          <UI.FieldNumber
            value={values.particulares.Parejas}
            name="particulares"
            label="Parejas"
            withArrows={false}
            disabled
            tiny
          />
          <UI.FieldNumber
            value={values.particulares.Hijos}
            name="particulares"
            label="Hijos"
            withArrows={false}
            disabled
            tiny
          />
        </S.InputsContainerVertical>
        <UI.Text color="chathamsBlue" weight="md" size="lg">
          Cápitas
        </UI.Text>
        <S.InputsContainer>
          <UI.FieldNumber
            value={values.colaboradores}
            name="colaboradores"
            label="Cantidad total (Máximo 25 capitas)"
            validationMsg={errors.colaboradores}
            disabled
            withArrows={false}
            tiny
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <UI.Button type="button" variant="outlined" onClick={onPressCancel}>
            Cancelar
          </UI.Button>
          <UI.Button
            type="submit"
            disabled={!isValid || isSubmitting || companyDataIsFetching}
            variant="outlined"
          >
            <S.SaveButtonIcon>
              <UI.Icons.Save2Icon size="xxs" color={(!isValid || isSubmitting || companyDataIsFetching) ? 'tundora' : 'mariner'} />
            </S.SaveButtonIcon>
            Guardar
          </UI.Button>
          <UI.Button
            type="button"
            disabled={!companyDataSaved || !isValid}
            onClick={() => {
              const hasNewChanges = Object.keys(touched).length !== 0
              return hasNewChanges && !newChangesSaved
                ? handleContinue()
                : handleSetActiveIndex().next()
            }}
          >
            Continuar
          </UI.Button>
        </S.InputsContainer>
        {errorMsgOnPutCompanyData && (
          <UI.Text color="carmine">{errorMsgOnPutCompanyData}</UI.Text>
        )}
        {errorMsgOnFetchPymeData && (
          <UI.Text color="carmine">{errorMsgOnFetchPymeData}</UI.Text>
        )}
      </S.CompanyApplicationForm>
    )}
  </Formik>
)

export default CompanyInfo
