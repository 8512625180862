import { TServerResponse } from '../../../../../app/domain/httpClient'
import { IPymeRepository } from '../../../domain'
import { TDeleteHolderAndFamilyGroupParam } from './DeleteHolderAndFamilyGroupParam'

export default class DeleteHolderAndFamilyGroup {
  repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async run(
    params: TDeleteHolderAndFamilyGroupParam,
  ): Promise<TServerResponse<string>> {
    const buildParams: TDeleteHolderAndFamilyGroupParam = {
      guid: params.guid || '',
    }

    buildParams.tipoAfiliacion = params.guid ? 0 : params.tipoAfiliacion

    return this.repository.deleteHolderAndFamilyGroup(buildParams)
  }
}
