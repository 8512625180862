import React, { useState } from 'react'
import PostHolderDjs from '../../../../../../../modules/FamilyGroup/application/useCase/postHolderDjs'
import { IFamilyGroupRepository } from '../../../../../../../modules/FamilyGroup/domain/entities'
import constants from '../../../../../shared/constants'
import UI from '../../../../../UI'
import holdersLocal from '../HolderLocalModule'

import * as S from '../styles'

const SendRequestToHolder = ({
  familyGroupRepository,
  getHolders,
  memberType,
}: {
  familyGroupRepository: IFamilyGroupRepository
  getHolders: () => void
  memberType: string
}) => {
  const [holderDjsErrorMsg, setholderDjsErrorMsg] = useState('')
  const [holderDjsIsFetching, setHolderDjsIsFetching] = useState(false)

  const disabledBtn = holdersLocal.getHolder()?.PasoDesdeFuente
    === constants.commons.HOLDER_STEPS.COMPLETE
  const holderDjsNotified = holdersLocal.getHolder()?.PasoDesdeFuente
    === constants.commons.HOLDER_STEPS.DJ_SEND

  const postDjsUseCase = new PostHolderDjs(familyGroupRepository)

  const handleTitularRequest = async () => {
    try {
      setholderDjsErrorMsg('')
      setHolderDjsIsFetching(true)
      const guid = holdersLocal.getHolder()?.Guid

      if (!guid) return setholderDjsErrorMsg('Primero debe guardar el titular')
      if (guid) {
        const response = await postDjsUseCase.run(guid, memberType)
        if (response.error) {
          setholderDjsErrorMsg(response.msg)
        }

        getHolders()

        return response
      }

      return ''
    } catch (error) {
      return setholderDjsErrorMsg('Se produjo un error al enviar la solicitud')
    } finally {
      setHolderDjsIsFetching(false)
    }
  }

  return (
    <>
      <S.DjButtonsContainer>
        <UI.Button
          type="button"
          onClick={handleTitularRequest}
          isLoading={holderDjsIsFetching}
          disabled={!disabledBtn}
        >
          Enviar solicitud al titular
        </UI.Button>
        {holderDjsNotified && (
          <S.ConfirmationMessage>
            <UI.Icons.CheckCircleIcon size="xs" color="surfieGreen" />
            <UI.Text size="xs">
              Se ha enviado la solicitud al titular del grupo
            </UI.Text>
          </S.ConfirmationMessage>
        )}
      </S.DjButtonsContainer>
      <UI.Text color="carmine">{holderDjsErrorMsg}</UI.Text>
    </>
  )
}

export default SendRequestToHolder
