import { IApi, TServerResponse } from '../../../../app/domain/httpClient'
import services from '../../../../app/infraestructure/services'
import httpStatusCodes from '../../../../app/infraestructure/shared/constants/httpStatusCodes'
import { DataPymeServerResponse, TLastQuoteResponse } from '../../domain'
import { TDeleteHolderAndFamilyGroupParam } from '../../application/useCase/deleteHolderAndFamilyGroup/DeleteHolderAndFamilyGroupParam'
import MedioDePago from '../../application/useCase/getPaymentMethodData/PaymentMethodData'
import { IPymeRepository } from '../../domain/entity/PymeRepository'
import { SaveCompanyDataDTO } from '../../application/useCase/putDataPyme/SaveCompanyDataDTO'
import { SummaryResponseServer } from '../../application/useCase/getPymeSummary/Summary'
import { ValidationServerResponse } from '../../application/useCase/validateAdvisorsSecurityCode/validateAdvisorsSecurityCodeServerResponse'
import { ICoveragePlansParam } from '../../application/useCase/getDataCoveragePlans/CoveragePlansParam'
import { SavePaymentMethodParams } from '../../application/useCase/savePaymentMethod/SavePaymentMethodParams'
import { UpdatePasswordParam } from '../../application/useCase/updatePassword/UpdatePasswordParam'
import { Steps } from '../../../commons'
import { AdvisorSecurityCodeParam } from '../../application/useCase/validateAdvisorsSecurityCode/AdvisorSecurityCodeParam'

export default class PymeRepository implements IPymeRepository {
  private http: IApi

  private dataPyme: DataPymeServerResponse | null = null

  private lastQuote: TLastQuoteResponse | null = null

  private dataPymeIsFetching: boolean = false

  private lastQuoteIsFetching: boolean = false

  private pymeLogo: any = null

  constructor(http: IApi) {
    this.http = http
  }

  async validateAdvisorsSecurityCode(
    param: AdvisorSecurityCodeParam,
  ): Promise<ValidationServerResponse> {
    const response = await this.http.post<ValidationServerResponse>(
      '/pymes/confirmarcodigo',
      param,
    )

    return response
  }

  async updatePassword(param: UpdatePasswordParam): Promise<any> {
    const response = await this.http.post<any>('CambioPassword', param)

    return response
  }

  async getDataPyme(cached = true): Promise<DataPymeServerResponse> {
    if (this.dataPymeIsFetching) {
      // eslint-disable-next-line
      await new Promise<void>(resolve => setTimeout(() => resolve(), 2000))
    }

    this.dataPymeIsFetching = true

    if (!this.dataPyme || !cached) {
      this.dataPyme = await this.http.get<DataPymeServerResponse>('/PymeData')
    }

    this.dataPymeIsFetching = false

    return this.dataPyme
  }

  async getLastQuote(cached = true): Promise<TLastQuoteResponse> {
    if (this.lastQuoteIsFetching) {
      // eslint-disable-next-line
      await new Promise<void>(resolve => setTimeout(() => resolve(), 1100))
    }

    this.lastQuoteIsFetching = true

    if (!this.lastQuote || !cached) {
      this.lastQuote = await this.http.get<TLastQuoteResponse>('/pyme')
    }

    this.lastQuoteIsFetching = false

    return this.lastQuote
  }

  async saveCompanyLogo(param: FormData): Promise<any> {
    const response = await this.http.post<any>('/pymes/guardarLogo', param)

    return response
  }

  async saveCompanyData(params: SaveCompanyDataDTO): Promise<any> {
    const response = await this.http.put<any>('/pyme', params)

    this.getDataPyme(false)

    return response
  }

  async deleteHolderAndFamilyGroup(
    params: TDeleteHolderAndFamilyGroupParam,
  ): Promise<TServerResponse<string>> {
    const queryString = `?guid=${params.guid}${
      params.guid ? '' : `&tipoAfiliacion=${params.tipoAfiliacion}`
    }`

    const response = await this.http.post<Promise<TServerResponse<string>>>(
      `/Pymes/EliminarGrupoFamiliar${queryString}`,
      {},
    )

    if (
      response.status === httpStatusCodes.HTTP_UNEXPECTED_ERROR_STATUS
      && !response.data
    ) {
      response.data = 'Se produjo un error inesperado'
    }

    if (
      response.status === httpStatusCodes.HTTP_BAD_REQUEST
      && !response.data
    ) {
      response.data = 'Se produjo un error al borrar'
    }

    if (response.status === httpStatusCodes.HTTP_OK_STATUS && !response.data) {
      response.data = 'Se borró exitosamente'
    }

    return response
  }

  async getCoveragePlans(params: ICoveragePlansParam): Promise<any> {
    const response = await this.http.get<any>('/pymes/planes', params)

    return response
  }

  async getPymeLogo(params: number): Promise<any> {
    if (!this.pymeLogo) {
      const response = await this.http.get<any>(
        '/Pymes/Logo',
        { Id: params },
        'arraybuffer',
      )
      const prefix = `data:${response.headers['content-type']};base64,`
      const image = Buffer.from(response.data, 'binary').toString('base64')

      this.pymeLogo = prefix + image
    }

    return this.pymeLogo
  }

  async savePaymentMethod(params: SavePaymentMethodParams): Promise<any> {
    const response = await this.http.post('/Pymes/MediosDePago', params)

    return response
  }

  async updateSteps(step: Steps): Promise<void> {
    const response = await this.http.post<void>(
      `/Pymes/ActualizarPaso?idPaso=${step}`,
      {},
    )

    return response
  }

  async getPymeSummary(): Promise<SummaryResponseServer> {
    const response = await this.http.get<SummaryResponseServer>('/pyme/Resumen')

    return response
  }

  async getPaymentMethodData(
    IdPyme: number,
  ): Promise<TServerResponse<MedioDePago>> {
    const response = await this.http.get<TServerResponse<MedioDePago>>(
      '/pymes/MedioDePago',
      { IdPyme },
    )

    return response
  }

  async getRequote(idPyme: number): Promise<any> {
    const response = await this.http.get<any>('/Pymes/Cotizar', { idPyme })
    return response
  }

  async saveRequote(params: any): Promise<any> {
    const response = await this.http.post<any>(
      '/Pymes/GuardarCotizacion',
      params,
    )
    return response
  }
}

export const pymeRepo = new PymeRepository(
  services.http({ token: '', logoutUser: () => {} }).portalPymeApi,
)
