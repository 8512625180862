import httpStatusCodes from './httpStatusCodes'
import mimeTypes from './mimeTypes'
import endpoints from './endpoints'
import userRelated from './userRelated'
import formRelated from './formRelated'
import commons from './commons'

export default {
  httpStatusCodes,
  mimeTypes,
  endpoints,
  userRelated,
  formRelated,
  commons,
}
