import React from 'react'
import styled from 'styled-components'

import { TColorsM } from '../shared/styles/colors'
import Text from './Text'
import Spinner from './Spinner'

type TButton = {
  children: string | any
  type?: 'button' | 'submit'
  disabled?: boolean
  textColor?: keyof TColorsM
  buttonColor?: keyof TColorsM
  isLoading?: boolean
  shouldAdapt?: boolean
  onClick?: () => void
  variant?: 'normal' | 'outlined'
}

const ButtonComponent = styled.button.attrs((p: any) => ({
  disabled: p.disabled,
}))<{
  buttonColor: string
  isLoading: boolean
  disabled: boolean
  shouldAdapt: boolean
  variant: 'normal' | 'outlined'
  backgroundColor: string
}>`
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px ${({ variant, disabled }) => ((variant === 'normal' || disabled) ? 'none' : 'solid')}
    ${({ theme, variant, buttonColor }) => (variant === 'normal'
    ? theme.colors.silverChalice
    : theme.colors[buttonColor])};
  border-radius: 3px;
  background-color: ${({ theme, backgroundColor, disabled }) => (disabled ? theme.colors.silver : theme.colors[backgroundColor])};

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    width: ${(props) => (props.shouldAdapt ? '100%' : '14rem')};
  }

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.chathamsBlue};
  }
`

const Button = ({
  children,
  type = 'button',
  disabled = false,
  textColor = 'white',
  isLoading = false,
  buttonColor = 'mariner',
  shouldAdapt = false,
  onClick,
  variant = 'normal',
}: TButton) => {
  let txtColor: keyof TColorsM = textColor
  let backgroundColor: keyof TColorsM = buttonColor

  if (isLoading) {
    txtColor = 'emperor'
    backgroundColor = 'concrete'
  } else if (variant !== 'normal') {
    txtColor = buttonColor
    backgroundColor = 'transparent'
  } if (disabled) {
    txtColor = 'tundora'
  }

  return (
    <ButtonComponent
      type={type}
      disabled={disabled || isLoading}
      buttonColor={buttonColor}
      isLoading={isLoading}
      shouldAdapt={shouldAdapt}
      onClick={onClick}
      variant={variant}
      backgroundColor={backgroundColor}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Text size="xs" weight="md" color={txtColor}>
          {children}
        </Text>
      )}
    </ButtonComponent>
  )
}

Button.defaultProps = {
  type: 'button',
  disabled: false,
  textColor: 'white',
  isLoading: false,
  buttonColor: 'mariner',
  shouldAdapt: false,
  onClick: () => {},
  variant: 'normal',
}

export default Button
export type { TButton }
