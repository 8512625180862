// https://help.accusoft.com/PrizmViewer/v10.1/Config/MIME_Types.html
export const imagesMimeTypes = {
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  jpe: 'image/jpeg',
  png: 'image/png',
}

export default {
  imagesMimeTypes,
}
