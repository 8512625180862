import React, { FC } from 'react'
import styled from 'styled-components'
import { THoldersList } from '../../../../../../modules/FamilyGroup/domain/DTOs'
import constants from '../../../../shared/constants'
import UI from '../../../../UI'
import { StepsMapper } from './constants'
import holdersLocal from './HolderLocalModule'

const {
  commons: { HOLDER_STEPS },
} = constants

const Status = styled.div<{
  isComplete: boolean | number
}>`
  color: ${({ theme, isComplete }) => (isComplete === HOLDER_STEPS.COMPLETE
    || isComplete === HOLDER_STEPS.DJ_SEND
    || isComplete === HOLDER_STEPS.DJ_COMPLETE
    ? theme.colors.surfieGreen
    : theme.colors.chathamsBlue)};
`

const ListOfHoldersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`
const HolderContainer = styled.div<{
  isSelected: boolean
}>`
  padding: 0.688rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${({ isSelected }) => isSelected
    && `
    background: rgba(212, 212, 212, 0.3);
    box-shadow: inset 8px 0px 0px #2F79C2;
    border-radius: 0px 6px 6px 0px;
  `};
`

const Name = styled.div<{
  isSelected: boolean
}>`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.chathamsBlue};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ isSelected }) => isSelected
    && `
    font-weight: 600;
  `};
`

const DeleteIconContainer = styled.div`
  cursor: pointer;
`

const HolderData = styled.div`
  width: 80%;
`

const AddHolder = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.mariner};
  cursor: pointer;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-decoration-line: underline;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`

type TProps = {
  onClickSelectHandler: (id: string) => void
  onClickDeleteHandler: (id: string) => void
  onClickAddHolderHandler: () => void
  isFetching: boolean
}

const validStates = (step: number) => [HOLDER_STEPS.DJ_COMPLETE, HOLDER_STEPS.DJ_SEND, HOLDER_STEPS.COMPLETE].some(
  (s) => s === step,
)

const ListOfHolders: FC<TProps> = ({
  onClickSelectHandler,
  onClickDeleteHandler,
  onClickAddHolderHandler,
  isFetching,
}) => (
  <ListOfHoldersContainer>
    {isFetching ? (
      <SpinnerContainer>
        <UI.Spinner />
      </SpinnerContainer>
    ) : (
      <>
        <div>
          {(holdersLocal.getHoldersList() || []).map(
            ({ Paso, Nombre, id, PasoDesdeFuente }: THoldersList) => (
              <HolderContainer
                key={id}
                isSelected={holdersLocal.getHolderSelectedId() === id}
                onClick={() => {
                  onClickSelectHandler(id)
                }}
              >
                <HolderData>
                  <Name isSelected={holdersLocal.getHolderSelectedId() === id}>
                    {Nombre}
                  </Name>
                  {!validStates(PasoDesdeFuente) && (
                    <Status isComplete={PasoDesdeFuente}>
                      {StepsMapper[Paso]}
                    </Status>
                  )}
                  {validStates(PasoDesdeFuente) && (
                    <Status isComplete={PasoDesdeFuente}>
                      {StepsMapper[PasoDesdeFuente]}
                    </Status>
                  )}
                </HolderData>
                {holdersLocal.getHolderSelectedId() === id && (
                  <DeleteIconContainer
                    onClick={() => {
                      onClickDeleteHandler(id)
                    }}
                  >
                    <UI.Icons.DeleteIcon />
                  </DeleteIconContainer>
                )}
              </HolderContainer>
            ),
          )}
        </div>
        <AddHolder onClick={onClickAddHolderHandler}>
          <UI.Icons.PlusIcon color="mariner" />
          <p>Añadir titular</p>
        </AddHolder>
      </>
    )}
  </ListOfHoldersContainer>
)

export default ListOfHolders
