import { v4 } from 'uuid'

import { THoldersList,
  THoldersListByMemberType,
  THoldersListByMemberTypeKeys } from '../../../../../../modules/FamilyGroup/domain/DTOs'
import constants from '../../../../shared/constants'
import { TFamilyGroupFormValues } from './constants'

const {
  commons: { HOLDER_STEPS },
} = constants

const memberTypeByDefault = 'Desregulados'

const holdersLocal = (() => {
  let data: THoldersListByMemberType | null
  let holderIdSelected = ''
  let memberType: THoldersListByMemberTypeKeys = memberTypeByDefault

  return {
    getHolders: (): THoldersListByMemberType | null => data,
    setHolders: (holders: THoldersListByMemberType) => {
      data = holders
    },
    updateHolder: (
      mType: THoldersListByMemberTypeKeys,
      hIdSelected: string,
      fData: TFamilyGroupFormValues,
      status: number,
      step: number,
    ) => {
      if (data) {
        data = {
          ...data,
          [mType]: data[mType].map((holder: THoldersList) => {
            if (holder.id === hIdSelected) {
              return Object.assign(
                holder,
                { Estado: status },
                { formData: fData },
                { Nombre: `${fData.name || 'Titular'} ${fData.lastName}` },
                { Paso: step },
              )
            }

            return holder
          }),
        }
      }
    },
    getHoldersList: (): THoldersList[] | null => (data ? data[memberType] : null),
    createNewHolderByMemberType: (mType: THoldersListByMemberTypeKeys) => {
      if (data) {
        const holdersMemberType: any[] = []
        data[mType].forEach((k: any) => {
          holdersMemberType.push(k)
        })
        holdersMemberType.push({
          Guid: null,
          Nombre: 'Titular',
          Estado: 1,
          id: v4(),
          formData: null,
          Paso: HOLDER_STEPS.INCOMPLETE,
        })
        const mappedHolders = {
          ...data,
          [mType]: holdersMemberType,
        }

        data = mappedHolders
      }
    },
    deleteHolderById: (id: string, mType: THoldersListByMemberTypeKeys) => {
      if (data) {
        const mappedHolders = {
          ...data,
          [mType]: data[mType].filter(
            (holder: THoldersList) => holder.id !== id,
          ),
        }

        data = mappedHolders
      }
    },
    getHolder: (): THoldersList | null => {
      if (data) {
        return (
          data[memberType].find(
            (holder: THoldersList) => holder.id === holderIdSelected,
          ) || null
        )
      }

      return null
    },
    setHolderSelectedId: (guid: string) => {
      holderIdSelected = guid
    },
    getHolderSelectedId: (): string => holderIdSelected,
    setMemberType: (mType: THoldersListByMemberTypeKeys) => {
      memberType = mType
    },
    getMemberType: (): THoldersListByMemberTypeKeys => memberType,
  }
})()

export default holdersLocal
