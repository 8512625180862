import React from 'react'
import styled from 'styled-components'
import Text from './Text'

const CheckBoxWrapper = styled.div`
  position: relative;
  height: 24px;
`
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 24px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    transition: 0.2s;
  }
`

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 24px;
  &:checked + ${CheckBoxLabel} {
    background: ${(props) => props.theme.colors.mariner};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`

const TextContainer = styled.div<{ isChecked: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: ${({ isChecked }) => (isChecked ? '7px' : '23px')};
  display: flex;
  align-items: center;
`

const ToggleSwitch = ({
  id,
  value,
  onChange,
}: {
  id?: string
  value: boolean
  onChange: (v: boolean) => void
}) => {
  const handleOnClick = () => onChange(!value)

  return (
    <CheckBoxWrapper>
      <CheckBox
        id={id}
        type="checkbox"
        checked={value}
        onChange={handleOnClick}
      />
      <CheckBoxLabel htmlFor={id}>
        <TextContainer isChecked={value}>
          <Text size="xs" weight="mdS" color="white">
            {value ? 'SI' : 'NO'}
          </Text>
        </TextContainer>
      </CheckBoxLabel>
    </CheckBoxWrapper>
  )
}

ToggleSwitch.defaultProps = { id: 'checkbox' }

export default ToggleSwitch
