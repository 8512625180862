import styled from 'styled-components'

export const UpdatePasswordPageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  padding: 40px 80px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid rgba(1, 0, 0, 0.11);

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
`
