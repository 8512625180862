import { TServerResponse } from '../../../../app/domain/httpClient'
import IDjsRepository from '../../domain/IDjsRepository'

export default class SaveAllMembersDjs {
  readonly repository: IDjsRepository

  constructor(repository: IDjsRepository) {
    this.repository = repository
  }

  async run(guid: string): Promise<TServerResponse<any>> {
    return this.repository.saveAllMembersDjs(guid)
  }
}
