import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { IPymeRepository } from '../../../../modules/Pyme/domain'
import controller from './controller'
import { numbers } from '../../shared/helpers'
import UI from '../../UI'
import CardHome from './CardHome'
import { CardNoLine } from './CardNoLine'
import S from './styles'

import { registrationRequestStatus } from '../../../../modules/Pyme/domain/ValueObjects'
import SummaryModal from './SummaryModal'
import { Steps } from '../../../../modules/commons'
import useStepper from '../../hooks/useStepper'

const mapStepsToButonText: {
  [key: number]: string
} = {
  [Steps.NoIniciado]: 'Comenzar',
  [Steps.DatosPymes]: 'Completar',
  [Steps.DatosGrupoFamiliar]: 'Cargar',
  [Steps.Pago]: 'Agregar',
  [Steps.Completo]: 'Ver detalle',
}

const HomeScreen = ({
  pymeRepository,
}: {
  pymeRepository: IPymeRepository
}) => {
  const { handleDisplayModal, handleModalContent } = useContext(
    UI.Modal.ModalContext,
  )
  const {
    errorPyme,
    dataPyme,
    quoteError,
    lastQuote,
    handleOnPressCompanyApplication,
    dataPymeIsLoading,
  } = controller({
    pymeRepository,
  })

  const history = useHistory()
  const lastStepFromHolderProcess = dataPyme?.UltimoPaso || 0
  const stepIndex = lastStepFromHolderProcess
    ? lastStepFromHolderProcess - 1
    : 0
  const pymeSteps = [Steps.DatosPymes, Steps.DatosGrupoFamiliar, Steps.Pago]
  const pymeApplicationSteps = pymeSteps.map((step) => step - 1)

  const handleOnPressProcess = () => {
    if (lastStepFromHolderProcess !== Steps.Completo) {
      return handleOnPressCompanyApplication()
    }

    handleDisplayModal(true)
    return handleModalContent(
      <SummaryModal
        pymeRepository={pymeRepository}
        onCloseModal={() => handleDisplayModal(false)}
      />,
    )
  }

  const handleNextStep = (index: number) => {
    history.push('/solicitudDeAlta', { step: index })
  }
  const location = useLocation<{ step: number }>()
  const step = location.state?.step
  const { activeIndex } = useStepper({
    data: [],
    activeScreen: step,
  })

  useEffect(() => {
    handleModalContent(null)
  }, [])

  return (
    <UI.MainLayer
      headerTitle="Inicio"
      pymeRepository={pymeRepository}
      activeIndex={activeIndex}
    >
      <UI.Text color="chathamsBlue" weight="md" size="lg">
        ¡Te damos la bienvenida al Portal de empresas!
      </UI.Text>
      <UI.Text color="tundora" size="mdLg">
        Podrás visualizar los datos de tu empresa, gestionar nuevas
        cotizaciones, solicitar el alta de tus coberturas ¡Y mucho más!
      </UI.Text>
      <S.CardsContainer>
        <S.Column>
          <UI.Cards.CardSimple title="Datos de la empresa">
            <UI.Text color="carmine">{errorPyme}</UI.Text>
            <CardHome data={dataPyme} />
          </UI.Cards.CardSimple>
        </S.Column>
        <S.Column>
          <CardNoLine title="Cotización">
            <>
              <UI.Text color="carmine">{quoteError}</UI.Text>
              <UI.Text size="sm">
                Realizá una nueva cotización y conocé el valor exacto para tu
                empresa.
              </UI.Text>
              <UI.Text size="xxl" color="chathamsBlue" weight="lg">
                {lastQuote?.Total
                  ? `$${numbers.numberWithSeparators({
                    number: lastQuote.Total,
                    withThousandSeparator: true,
                  })}`
                  : '$0'}
              </UI.Text>
              <UI.Text size="md" weight="mdS">
                Valores aproximados
              </UI.Text>
              <UI.Text size="sm">
                {lastQuote?.FechaUltimaCotizacion
                  ? `Última actualización: ${lastQuote?.FechaUltimaCotizacion}`
                  : 'Última actualización: '}
              </UI.Text>
            </>
          </CardNoLine>
          <UI.Cards.CardInfo
            data={
              dataPymeIsLoading ? (
                <UI.Spinner />
              ) : (
                <>
                  <S.SpacingTitle>
                    <UI.Text weight="mdS" color="mariner">
                      Solicitud de alta
                    </UI.Text>
                    <S.ChipStatus>
                      <UI.Text size="sm">
                        {registrationRequestStatus[lastStepFromHolderProcess]}
                      </UI.Text>
                    </S.ChipStatus>
                  </S.SpacingTitle>
                  <S.Spacing>
                    <UI.Text size="sm">
                      Visualizá el estado de tu alta. Al finalizar todos los
                      pasos,
                    </UI.Text>
                    <UI.Text size="sm">
                      revisaremos tu solicitud y un asesor se comunicará con
                      vos.
                    </UI.Text>
                  </S.Spacing>
                  <S.Spacing>
                    <UI.Steppers.VerticalStepper
                      titleItems={[
                        {
                          title: 'Datos de la empresa',
                          onClick: () => handleNextStep(pymeApplicationSteps[0]),
                        },
                        {
                          title: 'Grupos familiares',
                          onClick: () => handleNextStep(pymeApplicationSteps[1]),
                        },
                        {
                          title: 'Método de pago',
                          onClick: () => handleNextStep(pymeApplicationSteps[2]),
                        },
                      ]}
                      active={stepIndex}
                      completed={lastStepFromHolderProcess === Steps.Completo}
                    />
                  </S.Spacing>
                </>
              )
            }
            actions={(
              <UI.Button shouldAdapt onClick={handleOnPressProcess}>
                {mapStepsToButonText[lastStepFromHolderProcess]}
              </UI.Button>
            )}
          />
        </S.Column>
      </S.CardsContainer>
    </UI.MainLayer>
  )
}

export default HomeScreen
