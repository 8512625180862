import React, { Fragment } from 'react'
import { Formik, FieldArray } from 'formik'
import { v4 } from 'uuid'
import { isEqual } from 'lodash'
import UI from '../../../../../UI'
import constants from '../../../../../shared/constants'
import * as S from '../styles'
import * as C from '../constants'
import familyGroupFormValidationSchema from './familyGroupFormValidationSchema'
import { DropDownValues } from '../../../../../UI/DropDown'
import { ILocation,
  Province } from '../../../../../../../modules/Province/domain/Province'
import PlansCoverage from './PlansCoverage'
import { IPymeRepository } from '../../../../../../../modules/Pyme/domain'
import { getYearsFromDate } from '../../../../../shared/helpers/date'
import ProvinciesDropDown from './ProvinciesDropDown'
import ProvinceRepository from '../../../../../../../modules/Province/infraestructure/repositories/provinceRepository'
import SendRequestToHolder from './SendRequestToHolder'
import { IFamilyGroupRepository } from '../../../../../../../modules/FamilyGroup/domain/entities'
import holdersLocal from '../HolderLocalModule'
import { HOLDER_STEPS } from '../../../../../shared/constants/commons'
import { memberTypesLabel } from '../../../../../../../modules/commons/member'

const CuilMask = '00-00000000-0'

const {
  formRelated: { placeholders },
  userRelated,
} = constants

const handleErrors = (errors: any, key: string, index: number): string => {
  if (!errors.childs) return ''
  if (!errors.childs[index]) return ''
  if (typeof errors.childs[index] !== 'object') return ''
  if (key in errors.childs[index]) return errors.childs[index][key]

  return ''
}

const SubmitListener = ({ props, onSubmitHolderForm }: any) => {
  const [lastValues, updateState] = React.useState(props.values)

  React.useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, props.values)
    const valuesEqualInitialValues = props.values === props.initialValues

    if (!valuesEqualLastValues) {
      updateState(props.values)
    }

    if (!valuesEqualLastValues && !valuesEqualInitialValues) {
      onSubmitHolderForm(props.values)
      props.submitForm()
    }
  }, [lastValues, props.values, props.initialValues, props.onChange, props])

  return null
}

const status = () => holdersLocal.getHolder()?.PasoDesdeFuente || 0

interface IFamilyGroupForm {
  onSubmitHolderForm: (values: C.TFamilyGroupFormValues) => void
  provincies: Province[]
  onSubmitOnHoldersForm: (formData: C.TFamilyGroupFormValues) => void
  initValues: C.TFamilyGroupFormValues | null
  pymeRepository: IPymeRepository
  provinceRepository: ProvinceRepository
  familyGroupRepository: IFamilyGroupRepository
  getHolders: () => void
  memberType: string
}

type IFProps = {
  values: C.TFamilyGroupFormValues
  errors: any
  handleSubmit: any
  handleChange: any
  setFieldValue: any
}

const FamilyGroupForm = ({
  onSubmitHolderForm,
  provincies,
  onSubmitOnHoldersForm,
  initValues,
  pymeRepository,
  provinceRepository,
  familyGroupRepository,
  getHolders,
  memberType,
}: IFamilyGroupForm) => (
  <Formik
    initialValues={C.familyGroupFormInitialValues(initValues)}
    validationSchema={() => familyGroupFormValidationSchema(provincies, memberType)}
    onSubmit={onSubmitHolderForm}
    enableReinitialize
    validateOnMount
  >
    {(props: IFProps) => {
      const { values, errors, handleSubmit, handleChange, setFieldValue } = props
      return (
        <form onSubmit={handleSubmit}>
          <SubmitListener
            props={props}
            onSubmitHolderForm={onSubmitOnHoldersForm}
          />
          <UI.Text weight="md" size="md">
            Titular
          </UI.Text>
          <S.InputsContainerGrid>
            <UI.TextField
              name="name"
              value={values.name}
              label="Nombre"
              onChange={handleChange}
              placeholder={placeholders.complete}
              validationMsg={errors.name}
              disabled={status() >= HOLDER_STEPS.DJ_SEND}
            />
            <UI.TextField
              name="lastName"
              value={values.lastName}
              label="Apellido"
              onChange={handleChange}
              placeholder={placeholders.complete}
              validationMsg={errors.lastName}
              disabled={status() >= HOLDER_STEPS.DJ_SEND}
            />
            <UI.DropDown
              value={values.sex}
              name="sex"
              label="Sexo"
              id="sex"
              onChange={(option: DropDownValues) => {
                setFieldValue('sex', option ? option.value : null)
              }}
              options={userRelated.sexOptions}
              placeholder={placeholders.select}
              validationMsg={errors.sex}
              isDisabled={status() >= HOLDER_STEPS.DJ_SEND}
            />
            <UI.FieldNumber
              onlyPositive
              withArrows={false}
              name="dni"
              value={values.dni}
              setFieldValue={setFieldValue}
              onChange={handleChange}
              label="N° Documento"
              placeholder={placeholders.complete}
              validationMsg={errors.dni}
              disabled={Boolean(holdersLocal.getHolder()?.Guid) || false}
              withoutDot
              maxDigits={8}
            />
            <UI.MaskInput
              name="cuil"
              value={values.cuil.toString()}
              mask={CuilMask}
              label="CUIL"
              onChange={(cuil: string) => {
                setFieldValue('cuil', cuil)
              }}
              validationMsg={errors.cuil}
              disabled={status() >= HOLDER_STEPS.DJ_SEND}
            />
            <UI.Date
              showMonthDropdown
              showYearDropdown
              label="Fecha de nacimiento"
              name="birthDay"
              date={values.birthDay}
              onChange={handleChange}
              onChangeDate={(v: any) => {
                setFieldValue('birthDay', v)
                setFieldValue('age', getYearsFromDate(v))
              }}
              validationMsg={errors.birthDay}
              disabled={status() >= HOLDER_STEPS.DJ_SEND}
            />
            <UI.TextField
              name="email"
              value={values.email}
              label="Mail"
              onChange={handleChange}
              placeholder={placeholders.email}
              validationMsg={errors.email}
              disabled={status() >= HOLDER_STEPS.DJ_SEND}
            />
            <UI.TextField
              name="repeatEmail"
              value={values.repeatEmail}
              label="Mail"
              onChange={handleChange}
              placeholder={placeholders.repeatEmail}
              validationMsg={errors.repeatEmail}
              disabled={status() >= HOLDER_STEPS.DJ_SEND}
            />
            <UI.FieldNumber
              disabled
              withArrows={false}
              name="age"
              value={values.age}
              label="Edad"
              setFieldValue={setFieldValue}
              onChange={handleChange}
              placeholder={placeholders.complete}
              validationMsg={errors.age}
            />
            <S.PhoneInputs>
              <S.AreaCodeInput>
                <UI.TextField
                  name="areaCode"
                  label="Cod.Área"
                  value={values.areaCode}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  maxCharacters={4}
                  validationMsg={errors.areaCode}
                  disabled={status() >= HOLDER_STEPS.DJ_SEND}
                  placeholder="sin el 0"
                />
              </S.AreaCodeInput>
              <UI.TextField
                name="phone"
                label="Teléfono"
                value={values.phone}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                maxCharacters={12}
                validationMsg={errors.phone}
                disabled={status() >= HOLDER_STEPS.DJ_SEND}
              />
            </S.PhoneInputs>
            {memberType !== memberTypesLabel.Individuales && (
              <UI.FieldNumber
                onlyPositive
                withArrows={false}
                name="contributions"
                value={values.contributions}
                label="Aportes"
                setFieldValue={setFieldValue}
                onChange={handleChange}
                placeholder={placeholders.contributions}
                validationMsg={errors.contributions}
                disabled={status() >= HOLDER_STEPS.DJ_SEND}
              />
            )}
          </S.InputsContainerGrid>
          <hr />
          <UI.Text>Domicilio</UI.Text>
          <S.InputsContainerGrid>
            <UI.TextField
              name="street"
              value={values.street}
              label="Calle"
              onChange={handleChange}
              placeholder={placeholders.complete}
              validationMsg={errors.street}
            />
            <UI.TextField
              name="streetNumber"
              value={values.streetNumber}
              label="Altura"
              onChange={handleChange}
              placeholder={placeholders.complete}
              validationMsg={errors.streetNumber}
            />
            <UI.TextField
              name="floor"
              value={values.floor}
              label="Piso y/o dpto. (Opcional)"
              onChange={handleChange}
              placeholder={placeholders.complete}
              validationMsg={errors.floor}
            />
            <ProvinciesDropDown
              value={values.province}
              provinceRepository={provinceRepository}
              error={errors.province}
              setFieldValue={setFieldValue}
            />
            {provincies.find((p: Province) => p.id === values.province)
              ?.localidades.length ? (
                <UI.DropDown
                  name="location"
                  label="Localidad"
                  id="location"
                  value={values.location}
                  onChange={(option: DropDownValues) => {
                    setFieldValue('location', option.value)
                  }}
                  options={
                  provincies
                    ?.find(
                      (province: Province) => province.id === values.province,
                    )
                    ?.localidades.map((l: ILocation) => ({
                      label: l.nombre,
                      value: l.id,
                    })) || []
                }
                  validationMsg={errors.location}
                />
              ) : null}
            <UI.TextField
              name="postalCode"
              value={values.postalCode}
              label="Código postal"
              onChange={handleChange}
              placeholder={placeholders.complete}
              validationMsg={errors.postalCode}
            />
          </S.InputsContainerGrid>
          <hr />
          <UI.Text weight="md" size="md">
            Pareja
          </UI.Text>
          <S.InlineInputs>
            <UI.Text weight="md" size="sm">
              ¿El plan incluye a su pareja?
            </UI.Text>
            {status() < HOLDER_STEPS.DJ_SEND && (
              <UI.ToggleSwitch
                value={values.includeCouple}
                onChange={(v: boolean) => setFieldValue('includeCouple', v)}
              />
            )}
          </S.InlineInputs>
          {values.includeCouple && (
            <>
              <S.InputsContainerGrid>
                <UI.TextField
                  name="coupleName"
                  value={values.coupleName}
                  label="Nombre"
                  onChange={handleChange}
                  placeholder={placeholders.complete}
                  validationMsg={errors.coupleName}
                  disabled={status() >= HOLDER_STEPS.DJ_SEND}
                />
                <UI.TextField
                  name="coupleLastName"
                  value={values.coupleLastName}
                  label="Apellido"
                  onChange={handleChange}
                  placeholder={placeholders.complete}
                  validationMsg={errors.coupleLastName}
                  disabled={status() >= HOLDER_STEPS.DJ_SEND}
                />
                <UI.DropDown
                  name="coupleSex"
                  label="Sexo"
                  id="coupleSex"
                  value={values.coupleSex}
                  onChange={(option: DropDownValues) => {
                    setFieldValue('coupleSex', option ? option.value : null)
                  }}
                  options={userRelated.sexOptions}
                  placeholder={placeholders.select}
                  validationMsg={errors.coupleSex}
                  isDisabled={status() >= HOLDER_STEPS.DJ_SEND}
                />
              </S.InputsContainerGrid>
              <S.InputsContainerGrid>
                <UI.FieldNumber
                  onlyPositive
                  withArrows={false}
                  name="coupleDni"
                  value={values.coupleDni}
                  label="Nº Documento"
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  placeholder={placeholders.complete}
                  validationMsg={errors.coupleDni}
                  disabled={status() >= HOLDER_STEPS.DJ_SEND}
                  withoutDot
                  maxDigits={8}
                />
                <UI.MaskInput
                  name="coupleCuil"
                  value={values.coupleCuil.toString()}
                  mask={CuilMask}
                  label="CUIL"
                  onChange={(cuil: string) => {
                    setFieldValue('coupleCuil', cuil)
                  }}
                  validationMsg={errors.coupleCuil}
                  disabled={status() >= HOLDER_STEPS.DJ_SEND}
                />
              </S.InputsContainerGrid>
              <S.InputsContainerGrid>
                <UI.Date
                  showMonthDropdown
                  showYearDropdown
                  label="Fecha de nacimiento"
                  name="coupleBirthday"
                  date={values.coupleBirthday}
                  onChange={handleChange}
                  onChangeDate={(v: any) => {
                    setFieldValue('coupleBirthday', v)
                    setFieldValue('coupleAge', getYearsFromDate(v))
                  }}
                  validationMsg={errors.coupleBirthday}
                  disabled={status() >= HOLDER_STEPS.DJ_SEND}
                />
                <S.PhoneInputs>
                  <S.AreaCodeInput>
                    <UI.TextField
                      name="coupleAreaCode"
                      value={values.coupleAreaCode}
                      label="Cod.Área"
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      maxCharacters={4}
                      validationMsg={errors.coupleAreaCode}
                      disabled={status() >= HOLDER_STEPS.DJ_SEND}
                      placeholder="sin el 0"
                    />
                  </S.AreaCodeInput>
                  <UI.TextField
                    name="couplePhone"
                    value={values.couplePhone}
                    label="Teléfono"
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    placeholder={placeholders.complete}
                    validationMsg={errors.couplePhone}
                    maxCharacters={12}
                    disabled={status() >= HOLDER_STEPS.DJ_SEND}
                  />
                </S.PhoneInputs>
                <UI.FieldNumber
                  disabled
                  withArrows={false}
                  name="coupleAge"
                  value={values.coupleAge}
                  label="Edad"
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  placeholder={placeholders.complete}
                  validationMsg={errors.coupleAge}
                />
              </S.InputsContainerGrid>
            </>
          )}
          <hr />
          <UI.Text weight="md" size="md">
            Hijos/as
          </UI.Text>
          <S.ElementSpacing>
            <UI.Text weight="md" size="xs">
              Cantidad de hijos/as
            </UI.Text>
          </S.ElementSpacing>
          <FieldArray
            name="childs"
            render={(arrayHelpers) => (
              <>
                {status() < HOLDER_STEPS.DJ_SEND && (
                  <UI.Button onClick={() => arrayHelpers.push({ id: v4() })}>
                    Agregar hijo
                  </UI.Button>
                )}
                {values.childs.map((child: any, index: number) => (
                  <Fragment key={child.dni || index}>
                    <S.ChildrenButton>
                      Hijo/a
                      {' '}
                      {index + 1}
                      <hr />
                    </S.ChildrenButton>
                    <S.InputsContainerGrid>
                      <UI.TextField
                        name={`childName${index}`}
                        value={child.name}
                        label="Nombre"
                        onChange={(e: any) => {
                          handleChange(e)

                          arrayHelpers.replace(index, {
                            ...child,
                            name: e.target.value,
                          })
                        }}
                        placeholder={placeholders.complete}
                        validationMsg={handleErrors(errors, 'name', index)}
                        disabled={status() >= HOLDER_STEPS.DJ_SEND}
                      />
                      <UI.TextField
                        name={`childLastName${index}`}
                        value={child.lastName}
                        label="Apellido"
                        onChange={(e: any) => {
                          handleChange(e)

                          arrayHelpers.replace(index, {
                            ...child,
                            lastName: e.target.value,
                          })
                        }}
                        placeholder={placeholders.complete}
                        validationMsg={handleErrors(errors, 'lastName', index)}
                        disabled={status() >= HOLDER_STEPS.DJ_SEND}
                      />
                      <UI.DropDown
                        name={`childSex${index}`}
                        label="Sexo"
                        value={child.sex}
                        id={`childSex${index}`}
                        onChange={(option: DropDownValues) => {
                          arrayHelpers.replace(index, {
                            ...child,
                            sex: option.value,
                          })
                        }}
                        options={userRelated.sexOptions}
                        placeholder={placeholders.select}
                        validationMsg={handleErrors(errors, 'sex', index)}
                        isDisabled={status() >= HOLDER_STEPS.DJ_SEND}
                      />
                    </S.InputsContainerGrid>
                    <S.InputsContainerGrid>
                      <UI.FieldNumber
                        onlyPositive
                        withArrows={false}
                        name="childDni"
                        value={child.childDni || NaN}
                        setFieldValue={(
                          fieldName: string,
                          value: string | number,
                        ) => {
                          arrayHelpers.replace(index, {
                            ...child,
                            [fieldName]: value,
                          })
                        }}
                        label="Nº Documento"
                        placeholder={placeholders.complete}
                        validationMsg={handleErrors(errors, 'childDni', index)}
                        disabled={status() >= HOLDER_STEPS.DJ_SEND}
                        withoutDot
                        maxDigits={8}
                      />

                      <UI.Date
                        showMonthDropdown
                        showYearDropdown
                        label="Fecha de nacimiento"
                        date={child.birthDay}
                        name={`childBirthday${index}`}
                        onChange={handleChange}
                        onChangeDate={(v: any) => {
                          arrayHelpers.replace(index, {
                            ...child,
                            birthDay: v,
                            age: getYearsFromDate(v),
                          })
                        }}
                        validationMsg={handleErrors(errors, 'birthDay', index)}
                        disabled={status() >= HOLDER_STEPS.DJ_SEND}
                      />
                      <UI.FieldNumber
                        disabled
                        onlyPositive
                        name={`childAge${index}`}
                        value={child.age}
                        label="Edad"
                        setFieldValue={(
                          fieldName: string,
                          value: string | number,
                        ) => {
                          arrayHelpers.replace(index, { ...child, age: value })
                        }}
                        onChange={handleChange}
                        placeholder={placeholders.complete}
                        validationMsg={handleErrors(errors, 'age', index)}
                      />
                    </S.InputsContainerGrid>
                    <S.InlineInputs>
                      {status() < HOLDER_STEPS.DJ_SEND && (
                        <UI.Button
                          variant="outlined"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Eliminar
                        </UI.Button>
                      )}
                    </S.InlineInputs>
                  </Fragment>
                ))}
              </>
            )}
          />
          <S.DeclaracionContainer>
            <UI.Text color="chathamsBlue" weight="mdS">
              Declaraciones juradas del grupo
            </UI.Text>
          </S.DeclaracionContainer>
          <UI.Text color="tundora" size="sm">
            Completá todos los campos para poder enviar la solicitud.
          </UI.Text>
          <UI.Text color="tundora" size="sm">
            Al enviarla, le llegara un mail al titular del grupo para que
            complete la DDJJ de cada integrante del grupo.
          </UI.Text>
          <S.DeclaracionContainer>
            <SendRequestToHolder
              familyGroupRepository={familyGroupRepository}
              getHolders={getHolders}
              memberType={memberType}
            />
          </S.DeclaracionContainer>
          <hr />
          <UI.Text color="chathamsBlue" weight="md">
            Cobertura del grupo familiar
          </UI.Text>
          <PlansCoverage
            setFieldValue={setFieldValue}
            error={errors.coveragePlans}
            pymeRepository={pymeRepository}
            age={values.age}
            provId={values.province}
            value={values.coveragePlans}
          />
        </form>
      )
    }}
  </Formik>
)

export default FamilyGroupForm
