import { TServerResponse } from '../../../../app/domain/httpClient'
import { LoginRepository,
  LoginParams,
  LoginResponse,
  RecoverPasswordParams,
  UpdatePasswordParams } from '../../domain'

type AuthServerResponse = TServerResponse<LoginResponse>

export default class AutenthicateUser {
  private repository: LoginRepository

  constructor(repository: LoginRepository) {
    this.repository = repository
  }

  async authenticate(credentials: LoginParams): Promise<AuthServerResponse> {
    return this.repository.loginUser(credentials)
  }

  async updatePassword(params: UpdatePasswordParams): Promise<any> {
    return this.repository.updatePassword(params)
  }

  async recoverPassword(credentials: RecoverPasswordParams): Promise<any> {
    return this.repository.recoverPassword(credentials)
  }
}
