import React, { ReactNode } from 'react'
import styled from 'styled-components'

import Text from './Text'

type TFieldBasic = {
  children: ReactNode
  name: string
  label: string
  validationMsg?: string
  helperText?: string
}

const FieldContainer = styled.div`
  width: 100%;
  height: 4rem;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 12rem;
  }

  > label {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

const FieldBasic = ({
  children,
  name,
  label,
  validationMsg = '',
  helperText = '',
}: TFieldBasic) => (
  <FieldContainer>
    <label htmlFor={name}>
      <Text size="xs" weight="sm" color="flatBlue">
        {label}
      </Text>
      {children}
    </label>
    <Text size="xxs" weight="sm" color="greyishBrown">
      {helperText}
    </Text>
    <Text size="xxs" weight="sm" color="carmine">
      {validationMsg}
    </Text>
  </FieldContainer>
)

FieldBasic.defaultProps = { validationMsg: '', helperText: '' }

export default FieldBasic
export type { FieldBasic }
