import { useState } from 'react'
import GetDataPyme from '../../application/useCase/getDataPyme'
import SaveCompanyLogo from '../../application/useCase/saveCompanyLogo'
import { IPymeRepository } from '../../domain'

export type Step4Form = {
  guardarLogo: any
}

const customizeAccountController = ({
  pymeRepository,
  handleSetActiveIndex,
}: {
  handleSetActiveIndex: any
  pymeRepository: IPymeRepository
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const saveCompanysLogoUseCase = new SaveCompanyLogo(pymeRepository)
  const getDataPymeUseCase = new GetDataPyme(pymeRepository)

  const handleOnSubmit = async (value: Step4Form) => {
    try {
      setIsLoading(true)
      const dataPyme = await getDataPymeUseCase.getDataPyme()
      const formData = new FormData()

      formData.append(
        value.guardarLogo.name,
        value.guardarLogo,
        value.guardarLogo.name,
      )
      formData.append('idPyme', dataPyme.data.Id.toString())

      await saveCompanysLogoUseCase.saveCompanyLogo(formData)

      setIsLoading(false)

      return handleSetActiveIndex().next()
    } catch (err) {
      setIsLoading(false)

      return setErrorMsg('Error inesperado')
    }
  }

  const handleOnUploadLater = () => {
    handleSetActiveIndex().next()
  }

  return {
    isLoading,
    errorMsg,
    handleOnSubmit,
    handleOnUploadLater,
  }
}

export default customizeAccountController
