import React, { useState,
  useRef,
  useMemo,
  createContext,
  useEffect } from 'react'
import { LoginResponse } from '../../../modules/Login/domain'
import services from '../services'

export type TLogin = {
  children: React.ReactNode
  isAuth?: boolean
  firstLogin?: boolean
}

export type TLoginContext = {
  isAuth: boolean
  handleOnSuccessLogin: (loginResponse: LoginResponse) => void
  isFirstLogin: boolean
  isResetPassword: boolean
  handleOnPressLogout: () => void
  token: string
}

export const LoginContext = createContext({} as TLoginContext)

export const Login = ({
  children,
  isAuth = false,
  firstLogin = false,
}: TLogin) => {
  const { getMany, setMany } = services.persistence
  const [state, setState] = useState({
    isUserAuth: false,
    isFirstLogin: false,
    isResetPassword: false,
    token: '',
  })

  const handleOnSuccessLogin = useRef((loginResponse: LoginResponse) => {
    const { Token, PrimerIngreso, ResetPassword } = loginResponse

    setState({
      isUserAuth: !!Token,
      isFirstLogin: PrimerIngreso,
      token: Token,
      isResetPassword: ResetPassword,
    })
  })

  const handleOnPressLogout = useRef(() => {
    setState({
      isUserAuth: false,
      isFirstLogin: false,
      token: '',
      isResetPassword: false,
    })
  })

  const authProviderValue = useMemo<{
    isAuth: boolean
    handleOnSuccessLogin:(
    loginResponse: LoginResponse) => void
    isFirstLogin: boolean
    handleOnPressLogout: () => void
    token: string
    isResetPassword: boolean
  }>(
      () => ({
        isAuth: state.isUserAuth,
        handleOnSuccessLogin: (loginResponse) => handleOnSuccessLogin.current(loginResponse),
        isFirstLogin: state.isFirstLogin,
        handleOnPressLogout: () => handleOnPressLogout.current(),
        token: state.token,
        isResetPassword: state.isResetPassword,
      }),
      [state, handleOnSuccessLogin, handleOnPressLogout],
      )

  // init effects
  useEffect(() => {
    async function getAll() {
      try {
        const [auth, tok, firstLog, isResetPassword] = await getMany([
          'isUserAuth',
          'token',
          'isFirstLogin',
          'isResetPassword',
        ])

        setState({
          isUserAuth: auth || isAuth,
          isFirstLogin: firstLog || firstLogin,
          token: tok,
          isResetPassword,
        })
      } catch (err) {
        throw new Error(`Error en el getAll: ${err}`)
      }
    }

    getAll()
  }, [])

  // update effects
  useEffect(() => {
    async function updateUserAuth() {
      try {
        await setMany([
          ['isUserAuth', state.isUserAuth],
          ['isFirstLogin', state.isFirstLogin],
          ['token', state.token],
          ['isResetPassword', state.isResetPassword],
        ])
      } catch (error) {
        throw new Error(`Error update userAuth: ${error}`)
      }
    }

    updateUserAuth()
  }, [state])

  return (
    <LoginContext.Provider value={authProviderValue}>
      {children}
    </LoginContext.Provider>
  )
}

Login.defaultProps = {
  isAuth: false,
  firstLogin: false,
  isResetPassword: false,
}

export default {
  Login,
}
