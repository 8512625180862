import React, { FC } from 'react'
import styled from 'styled-components'

import Text from './Text'
import OMINT_LOGO from '../shared/images/logo_omint_azul.svg'
import OMINT_LOGO_WHITE from '../shared/images/logo_omint_blanco.svg'
import theme from '../shared/styles/theme'
import { TColors } from '../shared/styles/colors'

const SHeaderDefaultProps = {
  color: theme.colors.white,
}

const SHeader = styled.header<{ color: TColors }>`
  height: 4rem;
  display: flex;
  padding: 0px 3rem;
  gap: 1rem;

  background: ${(props) => props.theme.colors[props.color] || SHeaderDefaultProps.color};
  box-shadow: 10px 0px 25px rgba(125, 125, 125, 0.25);
`

const Logo = styled.img`
  width: 7rem;
`

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-right: 7.5rem;
`

const TextBlueContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;

  gap: 1rem;
  margin-right: 7.5rem;
`

type THeader = {
  children: string
}

const Header: FC<THeader> = ({ children }) => (
  <SHeader color="white">
    <Logo alt="omint" src={OMINT_LOGO} />
    <TextContainer>
      <Text
        textAlign="center"
        verticalAlign="center"
        weight="md"
        size="lg"
        color="chathamsBlue"
      >
        {children}
      </Text>
    </TextContainer>
  </SHeader>
)

const HeaderBlue: FC<THeader> = ({ children }) => (
  <SHeader color="chathamsBlue">
    <Logo alt="omint" src={OMINT_LOGO_WHITE} />
    <TextBlueContainer>
      <Text
        textAlign="left"
        verticalAlign="center"
        weight="md"
        size="lg"
        color="white"
      >
        |
      </Text>
      <Text
        textAlign="left"
        verticalAlign="center"
        weight="mdS"
        size="lg"
        color="white"
      >
        {children}
      </Text>
    </TextBlueContainer>
  </SHeader>
)

export default Header
export { HeaderBlue }
