import styled from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3.7rem;
  padding: 0 3rem;
  gap: 0.2rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.chathamsBlue};
`

const PymesLogo = styled.img`
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
`

const Row = styled.div`
  display: flex;
  width: 560px;
  justify-content: space-between;
`

const OptionsRow = styled.div`
  display: flex;
  width: 450px;
  justify-content: space-between;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0 0 2rem;
  gap: 10px;
`

const ButtonsContainer = styled(Row)`
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 0;
`

export default {
  QuestionsContainer,
  HeaderContainer,
  PymesLogo,
  PageContainer,
  Row,
  OptionsRow,
  OptionsContainer,
  ButtonsContainer,
}
