import { IPymeRepository } from '../../domain'

export default class GetPymeLogo {
  repository: IPymeRepository

  constructor(repository: IPymeRepository) {
    this.repository = repository
  }

  async run(pymeId: number) {
    return this.repository.getPymeLogo(pymeId)
  }
}
