import React from 'react'
import { TDataPyme } from '../../../../modules/Pyme/domain'
import UI from '../../UI'
import S from './styles'

interface Prop {
  data: TDataPyme | null
}

const CardHome = ({ data }: Prop) => (
  <S.InfoContainer>
    <div>
      <UI.Text weight="mdS" color="chathamsBlue">
        Razón social
      </UI.Text>
      <UI.Text weight="mdS" color="chathamsBlue">
        CUIT
      </UI.Text>
      <UI.Text weight="mdS" color="chathamsBlue">
        Mail
      </UI.Text>
      <UI.Text weight="mdS" color="chathamsBlue">
        Teléfono
      </UI.Text>
      <UI.Text weight="mdS" color="chathamsBlue">
        Provincia
      </UI.Text>
      <UI.Text weight="mdS" color="chathamsBlue">
        Localidad
      </UI.Text>
    </div>

    <div>
      <UI.Text>{data?.RazonSocial ? data.RazonSocial : ''}</UI.Text>
      <UI.Text>{data?.CUIT ? data.CUIT : ''}</UI.Text>
      <UI.Text>{data?.Mail ? data.Mail : ''}</UI.Text>
      <UI.Text>{data?.Telefono ? data.Telefono : ''}</UI.Text>
      <UI.Text>{data?.Provincia ? data.Provincia : ''}</UI.Text>
      <UI.Text>{data?.Localidad ? data.Localidad : ''}</UI.Text>
    </div>
  </S.InfoContainer>
)

export default CardHome
