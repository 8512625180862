import styled from 'styled-components'

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`

export const RequestDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const Tacbox = styled.div`
  input {
    margin-right: 16px;
  }

  a {
    color: #2f79c2;
  }

  label {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #2f79c2;
    text-decoration: underline;
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

export default {
  DetailsContainer,
  ButtonsContainer,
  RequestDetailsContainer,
  Tacbox,
  SpinnerContainer,
}
